import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { createQueryKey as createUseGetNextPossibleTransitionsQueryKey } from './useGetNextPossibleTransitions';
import { createQueryKey as createUseGetTransactionQueryKey } from './useGetTransaction';
import { post } from 'util/httpsClient';

export const useAcceptOrder = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (transactionId: string) =>
      post({ path: `/orders/${transactionId}/accept`, body: {} }),
    onSuccess: (_, transactionId) => {
      toast.success('Order successfully accepted');
      queryClient.invalidateQueries(createUseGetTransactionQueryKey(transactionId));
      queryClient.invalidateQueries(createUseGetNextPossibleTransitionsQueryKey(transactionId));
    },
    meta: { name: 'acceptOrder' },
  });
};
