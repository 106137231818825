import { useQuery } from '@tanstack/react-query';

import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { get } from 'util/httpsClient';

export const useGetUserSocials = () => {
  const { currentUser } = useCurrentUser();

  return useQuery({
    queryKey: [...useGetUserSocialsQueryKey, currentUser.id?.uuid],
    queryFn: async () => {
      const userId = currentUser.id?.uuid;

      return get({ path: `/users/socials?${userId ? 'userId=' + userId : ''}` }).then(res => ({
        ...res,
        followingIds: res.following.map((f: any) => f.id),
      }));
    },
  });
};

export const useGetUserSocialsQueryKey = ['userSocials'];
