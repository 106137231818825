import classNames from 'classnames';
import React from 'react';

import css from './CheckoutCheckbox.module.css';
import { Checkbox } from 'components/FieldCheckboxV2/FieldCheckbox';

export const CheckoutCheckbox: React.FC<{
  label: string;
  checked: boolean;
  onCheckedChange: (checked: boolean) => void;
  className?: string;
  labelClassName?: string;
}> = ({ label, checked, onCheckedChange, className, labelClassName }) => {
  return (
    <Checkbox
      className={classNames(css.checkoutCheckbox, className)}
      labelClassName={classNames(css.checkoutCheckbox__label, labelClassName)}
      label={label}
      checked={checked}
      onCheckedChange={onCheckedChange}
    />
  );
};
