import { useInfiniteQuery } from '@tanstack/react-query';

import { getAllTransitionsForEveryProcess } from '../../../transactions/transaction';
import { denormalisedResponseEntities } from '../../../util/data';
import { useSdk } from '../../../util/sdkContext';
import { useCurrentUser } from '../../selectors/useCurrentUser';

export enum TransactionFilter {
  ALL = 'all',
  SALES = 'sales',
  ORDERS = 'orders',
}

export const useGetTransactions = ({
  filter: transactionFilter = TransactionFilter.ALL,
  listingId,
}: {
  filter?: TransactionFilter;
  listingId?: string;
} = {}) => {
  const { currentUser } = useCurrentUser();
  const sdk = useSdk();

  return useInfiniteQuery({
    enabled: Boolean(currentUser.id?.uuid),
    queryKey: ['transactions', { transactionFilter, listingId }],
    getNextPageParam: lastPage => {
      // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
      const { totalPages, page } = lastPage.meta;
      if (page < totalPages) {
        return page + 1;
      }
      return undefined;
    },
    queryFn: ({ pageParam = 1 }) => {
      const apiQueryParams = {
        listingId,
        only:
          transactionFilter === TransactionFilter.ORDERS
            ? 'order'
            : transactionFilter === TransactionFilter.SALES
            ? 'sale'
            : undefined,
        lastTransitions: getAllTransitionsForEveryProcess(),
        include: [
          'listing',
          'provider',
          'provider.profileImage',
          'customer',
          'customer.profileImage',
          'listing.images',
        ],
        'fields.transaction': [
          'processName',
          'lastTransition',
          'lastTransitionedAt',
          'transitions',
          'payinTotal',
          'payoutTotal',
          'lineItems',
          'protectedData',
        ],
        'fields.listing': [
          'title',
          'availabilityPlan',
          'publicData.listingType',
          'publicData.brandName',
          'publicData.brandId',
        ],
        'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
        page: pageParam,
        perPage: 100,
      };

      return sdk.transactions.query(apiQueryParams).then(res => {
        return {
          meta: res.data.meta,
          data: denormalisedResponseEntities(res).map(transaction => {
            const userRole =
              transaction.customer.id.uuid === currentUser.id.uuid
                ? TransactionUserRole.CUSTOMER
                : TransactionUserRole.PROVIDER;
            const otherUser =
              transaction?.[
                userRole === TransactionUserRole.CUSTOMER
                  ? TransactionUserRole.PROVIDER
                  : TransactionUserRole.CUSTOMER
              ];

            return {
              ...transaction,
              userRole,
              otherUser,
              isCurrentUserProvider: userRole === TransactionUserRole.PROVIDER,
            };
          }),
        };
      });
    },
  });
};

export const TransactionUserRole = {
  CUSTOMER: 'customer',
  PROVIDER: 'provider',
};
