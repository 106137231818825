import * as RadixCheckbox from '@radix-ui/react-checkbox';
import * as Label from '@radix-ui/react-label';
import classNames from 'classnames';
import React from 'react';

import css from './FieldCheckbox.module.css';
import { IconCheckboxTick } from 'assets/icons';

type CheckboxProps = {
  label?: React.ReactNode;
  labelClassName?: string;
} & RadixCheckbox.CheckboxProps;

export const Checkbox: React.FC<CheckboxProps> = props => {
  const { label, labelClassName, className, ...rest } = props;

  const checkbox = (
    <RadixCheckbox.Root className={classNames(css.checkboxRoot, className)} {...rest}>
      <RadixCheckbox.Indicator className={css.checkboxIndicator}>
        <IconCheckboxTick />
      </RadixCheckbox.Indicator>
    </RadixCheckbox.Root>
  );

  if (label) {
    return (
      <Label.Root className={classNames(css.checkboxLabel, labelClassName)}>
        {checkbox}
        {label}
      </Label.Root>
    );
  }

  return checkbox;
};

const FieldCheckbox = props => {
  // TODO: Wrap in Field component

  return <Checkbox {...props} />;
};

export default FieldCheckbox;
