import { useParams } from 'react-router-dom';

import { CheckboxInputs } from '../CheckoutCheckboxInputs/CheckoutCheckboxInputs';
import { DiscountCodeInput } from '../CheckoutDiscountCodeInput/CheckoutDiscountCodeInput';
import css from './CheckoutSummary.module.css';
import { Modal, NamedLink, PrimaryButton } from 'components';
import IconSpinner from 'components/IconSpinner/IconSpinner';
import OrderBreakdown from 'components/OrderBreakdown/OrderBreakdown';
import OrderSummary from 'components/OrderSummary/OrderSummary';
import { useGetListing } from 'hooks/api/listings/useGetListing';
import { createListingSlug } from 'util/createListingSlug';
import { ensureListing } from 'util/data';

const CheckoutSummary = ({
  orderData,
  setOrderData,
  children,
  onApply,
  onRemove,
  isLoadingLineItems,
  lineItemsResponse,
  discountCodeState,
  setDiscountCodeState,
  isGuest,
}) => {
  const { id: listingId } = useParams<{ id: string }>();
  const { data: listingData } = useGetListing(listingId);
  const listing = ensureListing(listingData);

  const missingStripeAccount = listing?.attributes?.metadata?.sellerMissingStripeAccount;

  const lineItems = lineItemsResponse?.lineItems;

  const total = lineItemsResponse?.payinTotal;

  return (
    <div className={css.root}>
      <Modal open={missingStripeAccount} showCloseButton={false} title={'Whoops'}>
        <div>
          Seller's profile isn't complete, and you can't order this item at the moment. We'll notify
          them immediately, so please try again later.
        </div>
        <Modal.Footer>
          <NamedLink
            name="ListingPage"
            params={{ id: listingId, slug: createListingSlug(listing) }}
          >
            <PrimaryButton>Go back to listing</PrimaryButton>
          </NamedLink>
        </Modal.Footer>
      </Modal>
      {listing.id && <OrderSummary currentListing={listing} />}
      <DiscountCodeInput
        onApply={onApply}
        onRemove={onRemove}
        discountCodeState={discountCodeState}
        setDiscountCodeState={setDiscountCodeState}
      />
      {discountCodeState.error && <p style={{ color: 'red' }}>{discountCodeState.error}</p>}

      {!isGuest && <CheckboxInputs orderData={orderData} setOrderData={setOrderData} />}
      {isLoadingLineItems ? (
        <div className={css.loadingSpinner}>
          <IconSpinner />
        </div>
      ) : (
        lineItems && lineItems.length > 0 && <OrderBreakdown lineItems={lineItems} total={total} />
      )}
      <div className={css.chargeDisclaimer}>
        You will only be charged if your request is accepted within seven days by the seller of the
        item.
      </div>
      {children}
    </div>
  );
};

export default CheckoutSummary;
