import classNames from 'classnames';
import PropTypes from 'prop-types';

import { FormattedMessage } from '../../util/reactIntl';
import { TabNavHorizontal } from '../TabNavHorizontal/TabNavHorizontal';
import { LinkTabNavVertical } from '../TabNavVertical/TabNavVertical';
import css from './UserNav.module.css';

const UserNav = props => {
  const { className, rootClassName, currentPage } = props;
  const classes = classNames(rootClassName || css.root, css.verticalNav, className);

  const tabs = [
    {
      text: <FormattedMessage id="UserNav.editProfile" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: 'ProfileSettingsPage',
      },
    },
    {
      text: <FormattedMessage id="UserNav.addresses" />,
      selected: currentPage === 'ProfileAddressesPage',
      disabled: false,
      linkProps: {
        name: 'ProfileAddressesPage',
      },
    },
    {
      text: <FormattedMessage id="BankAccountPage.navTitle" />,
      selected: currentPage === 'BankAccountPage',
      linkProps: {
        name: 'BankAccountPage',
      },
    },
  ];

  return (
    <div className={css.wrapper}>
      <p className={css.wrapperLabel}>
        <FormattedMessage id="UserNav.settings" />
      </p>
      <LinkTabNavVertical className={classes} tabRootClassName={css.tab} tabs={tabs} skin="light" />
      <TabNavHorizontal className={css.horizontalNav} tabRootClassName={css.tab} tabs={tabs} />
    </div>
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
