import { connect } from 'react-redux';

import Topbar from '../../components/Topbar/Topbar';

const mapStateToProps = state => {
  // Topbar needs isAuthenticated
  const { isAuthenticated, authScopes } = state.auth;
  // Topbar needs user info.
  const { currentUser } = state.user;
  return {
    currentUser,
    isAuthenticated,
    authScopes,
  };
};

const TopbarContainer = connect(mapStateToProps)(Topbar);

export default TopbarContainer;
