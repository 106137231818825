import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { fetchCurrentUser } from '../../../ducks/user.duck';
import { useSdk } from 'util/sdkContext';

export const useVerifyEmail = () => {
  const dispatch = useDispatch();
  const sdk = useSdk();

  return useMutation({
    mutationFn: async (verificationToken: string) => {
      // Note that the thunk does not reject when the verification fails, it
      // just dispatches the login error action.
      return sdk.currentUser.verifyEmail({ verificationToken });
    },
    onSuccess: async () => {
      await dispatch(fetchCurrentUser());
    },
    meta: {
      name: 'verifyEmail',
    },
  });
};
