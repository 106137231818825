import React from 'react';

const TermsAndConditions = () => {
  return (
    <>
      <p>
        {' '}
        <em>The current T&amp;C came into effect on 14.03.2024</em>{' '}
      </p>{' '}
      <p>
        {' '}
        The present User Agreement (the Agreement) governs the relationship between{' '}
        <strong>NOLD</strong>, on the one hand, and the individuals and entities (the Sellers and
        Buyers, jointly referred to as the Users) on the{' '}
        <strong>
          {' '}
          <em>thenold.com</em>{' '}
        </strong>{' '}
        website (the Website), on the other.{' '}
      </p>{' '}
      <p>
        {' '}
        Please read the published Agreement in its entirety before using the information and
        services offered on the Website.{' '}
      </p>{' '}
      <p>
        {' '}
        This document contains information about the activities of NOLD and the general conditions
        for using the services provided by NOLD to the Users.{' '}
      </p>{' '}
      <p>
        {' '}
        Confirmation of the Agreement is a necessary and mandatory condition for creating an Account
        and using the Website.{' '}
      </p>{' '}
      <p>
        {' '}
        By accepting this Agreement, Users agree to have their personal data processed on the basis
        of the contract concluded between them and NOLD.{' '}
      </p>{' '}
      <p>
        {' '}
        Users personal data is processed according to NOLD’s Privacy Policy, which can be found here
        (link).{' '}
      </p>{' '}
      <p>&nbsp;</p>{' '}
      <p>
        {' '}
        <strong>NOLD Ltd. </strong>is a company registered under the law of the United Kingdom of
        Great Britain and Northern Ireland, with an address:{' '}
        <strong>124-128 City Road, London, England, EC1V 2NX</strong>, email address:
        hello@thenold.com, telephone number: +44 20 7019 9006{' '}
      </p>{' '}
      <p>
        {' '}
        NOLD is an online marketplace. Our platform allows our users to connect and trade items
        between them.{' '}
      </p>{' '}
      <p>
        {' '}
        NOLD owns <u>none</u> of the items displayed or sold on the Website. Therefore, NOLD is not
        a party to the legal relationship between Buyers and Sellers.{' '}
      </p>{' '}
      <p>
        {' '}
        NOLD does not participate in any of the money transactions that are part of the payment
        procedure for the goods or the delivery of the goods, sold on the Website.{' '}
      </p>{' '}
      <p>&nbsp;</p>{' '}
      <ol>
        {' '}
        <li>
          {' '}
          <strong> </strong> <strong>Definitions</strong>{' '}
        </li>{' '}
      </ol>{' '}
      <p>
        {' '}
        For the purposes of this Agreement, the following concepts should have the following
        meaning:{' '}
      </p>{' '}
      <p>
        <strong>Website</strong> – thenold.com and all its subpages and all BRH (brand resale hubs)
        driven by NOLD LLC. See the full list here.
      </p>
      <p>
        <strong>Seller Services </strong>– services, provided by NOLD via the Website, which
        services allow Sellers to conclude contracts for the sale of goods with Buyers
      </p>
      <p>
        <strong>Seller</strong> – any individual or a company which uses the Seller Services
        provided by NOLD
      </p>
      <p>
        <strong>Buyer </strong>– any individual who uses the Website to buy goods, offered by
        Sellers
      </p>
      <p>
        <strong>Agreement</strong> – this Seller Agreement, and any related documents, which
        include, but are not limited to: cookies policy, privacy policy, registration and delivery
        policies, voluntary dispute resolution, contract cancellation and exchange forms, list of
        forbidden goods, and any other legally binding information found on the Website
      </p>
      <p>
        <strong>Personal data</strong> – information that relates to an identified or identifiable
        living individual, e.g. name, date of birth, location data
      </p>
      <p>
        <strong>Good(s)</strong> – movable material property of the fashion industry, with the
        exceptions provided for in the applicable law; the term includes second-hand items (sold by
        Sellers) and items in unused condition (samples, factory seconds and items from old
        collections – sold by Partners)
      </p>
      <p>
        <strong>Sales contract</strong> – a contract under which a Seller transfers or undertakes to
        transfer the ownership of goods to the Buyer and the Buyer pays or undertakes to pay the
        price for them
      </p>
      <p>
        <strong>Partner –</strong> a brand which shares its product databases with NOLD
      </p>
      <p>
        <strong>Brand Resale Hub</strong> – a module on the marketplace which only displays items of
        a particular brand
      </p>
      <p>
        <strong>Partner database </strong>– a database, containing visual elements (images,
        graphics), text, tables etc. related to a product of a Partner brand&nbsp;
      </p>
      <p>
        <strong>Sale price – </strong>price for an individual item determined by the Seller
      </p>
      <p>
        <strong>Store credit</strong> – credit amount, which is offered by a Partner and is subject
        to the Partner’s terms of use.&nbsp;
      </p>
      <p>
        <strong>Fee</strong> – the amount of money which is due for the service of the platform.
        You’ll find details of our Selling Fees in this table.
      </p>
      <p>
        <strong>Collection</strong> – a group of all of the listings a Seller
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Services provided</strong>
        </li>
      </ol>
      <p>
        NOLD and its Website serve as a marketplace, which allows Buyers and Sellers to enter into
        contracts for the sale of goods.
      </p>
      <p>
        The goods offered by Sellers through the Website are intended for individuals and/or legal
        entities.
      </p>
      <p>NOLD may test and optimise content on the Website, including all Seller listings.</p>
      <p>NOLD offers marketing tools and services to qualified Sellers.</p>
      <p>NOLD may modify or cease providing services to its users at its discretion.</p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Account</strong>
        </li>
      </ol>
      <p>
        In order to use the Website and the Seller Services provided by NOLD, Users must register an
        Account. Creating an Account is currently free of charge.
      </p>
      <p>
        By registering an Account, Users confirm that they accept this Agreement and that they will
        act in accordance with its provisions.
      </p>
      <p>
        NOLD Accounts are currently available to individuals and legal entities in the European
        Union and the United Kingdom of Great Britain and Northern Ireland.
      </p>
      <p>The only person allowed to use the Account is the person whose name this account is in.</p>
      <p>Each individual or legal entity is allowed to register a single Account.</p>
      <p>
        The Account registration is a multi-step procedure which includes User verification and User
        approval.
      </p>
      <p>In order to create an Account, users must provide the following information:</p>
      <ul>
        <li>name</li>
        <li>account name</li>
        <li>email address</li>
        <li>date of birth</li>
        <li>delivery address</li>
        <li>bank account details</li>
      </ul>
      <p>NOLD may request additional information to be provided at its discretion.</p>
      <p>
        NOLD also allows Account registration via Facebook or Google. By using this option, NOLD
        requests and receives the information described above directly from Google or Facebook.
      </p>
      <p>NOLD shall have the right to refuse to open an Account at its discretion.</p>
      <p>
        NOLD bears no responsibility for losses arising out of or related to the use or access, or
        lack thereof, of an Account.
      </p>
      <p>
        By registering an account on NOLD, Users gain access to the main marketplace, as well as all
        Brand Resale Hubs. The current list of active Brand Resale Hubs can be found here: (link).
      </p>
      <p>With the addition of new Brand Resale Hubs, Users automatically gain access to those.</p>
      <p>
        Creating and maintaining Accounts necessitates the processing of personal data. This is done
        in accordance with NOLD Privacy Policy, available here (link).
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Listing</strong>
        </li>
      </ol>
      <p>Sellers use the Website interface to create a listing for the goods they offer.</p>
      <ol>
        <li>
          Automatic listing – a type of listing which utilises the information in a Partner
          database. In order to create an automatic listing, the Seller scans a QR code or an ID
          label available on their item. The Seller then selects their item from the provided list
          with suggestions. The selection automatically fills in the relevant information available
          in the Partner database. The information includes original product photographs,
          description, material and composition, colour, size (if applicable) and recommended retail
          price (RRP). The Seller has to upload current photographs of the item, photos of proof of
          origin/purchase or authenticity (if available), fill in information about the current
          condition of the item and possible defects, any comments they might have, and their
          desired Sale price.
        </li>
      </ol>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <ol>
        <li>
          Searchable listing – a type of listing which also utilises the information in a Partner
          database, but is used for items which lack a QR code or an ID label. In order to create
          this type of listing, the Seller searches through the Partner database using filters and
          keywords. The Seller again has to select their item. The selection automatically fills in
          the relevant information available in the Partner database. The information includes
          original product photographs, description, material and composition, colour, size (if
          applicable) and recommended retail price (RRP). The Seller has to provide current
          photographs, photos of proof of origin/purchase or authenticity (if available), the item’s
          current condition and information about possible defects, any comments they might have,
          and their desired Sale price.
        </li>
      </ol>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
      <ol>
        <li>
          Manual listing – a type of listing which does not utilise a Partner database (the item
          lacks a QR code or an ID label, is not available in a Partner database) or NOLD does not
          partner with this brand. The Seller provides all the required information, including
          photographs, proof of origin/purchase or authenticity (if available), size, an approximate
          or exact RRP, item condition, material and composition, details, comments, and Sale price,
          by themselves. The Seller is guided through this process by prompts on the listing page.
        </li>
      </ol>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
      <p>All listings are subject to final approval by NOLD.</p>
      <p>
        Any changes made by a Seller to an already existing listing are subject to approval by NOLD.
      </p>
      <p>
        Sellers are solely responsible for all of their Seller listings, including those created
        with the help of a Partner database and approved by NOLD.
      </p>
      <p>
        Sellers must ensure that the content in their listings is complete, accurate, true and
        up-to-date.
      </p>
      <p>
        Sellers must ensure that they own the necessary rights to use the content (outside of the
        Partner database content) in their listings.
      </p>
      <p>
        Sellers must ensure that their listings include all legal notices and disclosures (such as
        health-related warnings, use restrictions, fees and taxes, etc.) if required by the
        applicable law.
      </p>
      <p>More specifically, prices on the Website must include VAT, where applicable.</p>
      <p>
        NOLD shall have the non-exclusive, royalty-free, irrevocable, transferable right to use the
        content of all listings on the Website in its commercial activities.
      </p>
      <p>&nbsp;</p>
      <p>
        The following list contains the goods that are currently forbidden for sale on the Website.
      </p>
      <p>
        The list is non-exclusive – items that are prohibited or restricted by the applicable law
        are forbidden on the Website in general.
      </p>
      <p>NOLD has the right to modify the list of forbidden goods at its own discretion.</p>
      <p>&nbsp;</p>
      <p>The current list of forbidden goods is:</p>
      <ul>
        <li>Counterfeit items</li>
        <li>Items infringing on third-party rights (e.g. model items)</li>
        <li>Items which are not intended for resale (e.g. uniforms)</li>
        <li>Items by brands which do not authorize resale</li>
        <li>Items in a condition that violates the commonly accepted standards of hygiene</li>
      </ul>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Ratings and Reviews</strong>
        </li>
      </ol>
      <p>
        Sellers and Buyers are subject to the rating system and the review system of the Website.
      </p>
      <p>
        Ratings are numerical scores on a scale from 1 to 5, which represent users’ satisfaction
        with a specific trade and the communication with the other party.&nbsp; Each User individual
        rating is formed as an average from the sum of all received ratings.&nbsp;
      </p>
      <p>
        Reviews are written opinions by users, evaluating their interaction with the other party and
        the trade itself.
      </p>
      <p>
        Ratings and reviews are an inseparable part of each user Profile and are publically
        available.
      </p>
      <p>
        Reviews and ratings are only available for completed orders and are available in the chat or
        in the profile section of the user.&nbsp;
      </p>
      <p>Ratings and reviews are under the moderation of NOLD.</p>
      <p>Reviews containing one of the following shall be immediately deleted:</p>
      <ul>
        <li>Misinformation, deception and fraud</li>
        <li>Hate speech</li>
        <li>Personal data or privacy violation in general</li>
        <li>Bullying and/or harassment</li>
        <li>
          Statements of intent to commit violence, including self-harm and violence against animals
        </li>
        <li>Intellectual property violations</li>
      </ul>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Order</strong>
        </li>
      </ol>
      <p>Ordering goods by the Buyer is a multi-step procedure:</p>
      <p>&nbsp;</p>
      <ol>
        <li>
          The Buyer selects an item by clicking the “Request” button on the product page or in the
          chat system interface.
        </li>
        <li>
          The Buyer selects a shipping method and accepts the terms of service of NOLD and of the
          chosen courier company.
        </li>
        <li>The Buyer orders the payment.</li>
        <li>Within a period of seven days, the Seller decides to Cancel or Confirm the Order:</li>
      </ol>
      <ol>
        <li>
          If the order is Cancelled, the Buyer receives a message informing him of the cancellation;
          the money is never transferred from their bank account to Stripe and the product is
          available on the Website again;
        </li>
        <li>
          If the order is Confirmed, the money is transferred to Stripe, and the Seller uses the
          Chat system to generate a shipping label
        </li>
      </ol>
      <ol>
        <li>The Seller has 3 days to fulfil the order.</li>
      </ol>
      <p>&nbsp;</p>
      <p>
        The list of courier companies currently available and the respective shipping fees is
        available here: (link)
      </p>
      <p>
        Buyer accounts are currently available to individuals and legal entities in the European
        Union and the United Kingdom of Great Britain and Northern Ireland.
      </p>
      <p>
        The contract for the sale of goods is considered concluded from the moment the order is
        confirmed by the Seller.
      </p>
      <p>
        Although NOLD owns none of the items displayed and sold on the Website, and NOLD is not a
        party to the contract for the sale of goods between the users, NOLD has the right to
        terminate already confirmed orders, made in violation of the present Agreement.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> Product verification – will be available soon</strong>
        </li>
      </ol>
      <p>
        Product verification is a paid service offered by NOLD for selected goods in partnership
        with Legit Grails.
      </p>
      <p>
        The service could be purchased at check-out with the order of the product that the Buyer
        wishes to verify.&nbsp;&nbsp;&nbsp;
      </p>
      <p>
        If the verification procedure returns a negative result, NOLD reimburses the verification
        fee and the sale price for the item to the Buyer. The item that is subject to the
        verification is then deleted from the platform.
      </p>
      <p>By using the Website, Users agree with the terms of service of Legit Grails.</p>
      <p>
        If a seller lists more than two items, proven to be counterfeit by Legit Grails verification
        procedure, NOLD holds the right to temporarily suspend or delete the Seller Account.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Chat System</strong>
        </li>
      </ol>
      <p>
        In order to facilitate the process of sale, NOLD offers a chat system which connects Sellers
        with Buyers.
      </p>
      <p>
        The chat system is available separately for each individual listing and Buyers who initiate
        the conversation or Request to buy.
      </p>
      <p>
        Sellers agree to only use the chat system for the purpose of executing the individual order.
      </p>
      <p>
        Discussing individual orders outside of the chat system deprives Sellers from their rights
        under the present Agreement, including receiving and leaving reviews and ratings, customer
        support etc.
      </p>
      <p>Within the two-day period under 4., the product is unavailable to other Buyers.</p>
      <p>NOLD has the right but not the obligation to moderate the chat messages between Users.</p>
      <p>NOLD has the right to delete chat messages at its discretion.</p>
      <p>Chat messages containing one of the following shall be immediately deleted:</p>
      <ul>
        <li>Misinformation, deception and fraud</li>
        <li>Hate speech</li>
        <li>Personal data or privacy violation in general</li>
        <li>Bullying and/or harassment</li>
        <li>
          Statements of intent to commit violence, including self-harm and violence against animals
        </li>
        <li>Intellectual property violations</li>
      </ul>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Fulfilment</strong>
        </li>
      </ol>
      <p>
        Goods sold on the Website are delivered by a logistics company chosen by the Buyer at
        checkout from a list provided by NOLD.
      </p>
      <p>The current list of logistics companies used by NOLD includes:</p>
      <ul>
        <li>Evri</li>
      </ul>
      <p>&nbsp;</p>
      <p>
        The list of logistics companies used by NOLD falls entirely within the discretion of NOLD
        and is subject to change.
      </p>
      <p>
        After an item is ordered by the Buyer and the Seller confirms the order, the Seller uses the
        interface of the Chat system to generate a shipping label.
      </p>
      <p>
        Sellers have 3 (three) business days (counted from the moment when the shipping label is
        generated) to transfer possession of the goods to the courier for the purpose of the
        delivery.
      </p>
      <p>All delivery fees are at the expense of and prepaid by the Buyer.</p>
      <p>
        If the address provided by the Buyer is incorrect and/or cannot be located at their address
        by the delivery company, all return fees are at the expense of the Buyer and deducted from
        the refund the Buyer receives.
      </p>
      <p>
        For international deliveries, the Buyer is solely responsible for all applicable fees and
        duties, and customs-related obligations and documents.
      </p>
      <p>
        By creating an Account and using the Seller Services, Sellers agree with the terms of use of
        the courier.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Payment</strong>
        </li>
      </ol>
      <p>
        Payment for the goods sold on the Website is facilitated by NOLD with the help of a
        third-party payment processing provider, which acts as an escrow.
      </p>
      <p>Currently, the only third-party service NOLD uses for this purpose is Stripe.</p>
      <p>By using the Website, Sellers agree with the terms of service of Stripe.</p>
      <p>
        NOLD urges Sellers to get acquainted with the terms of service of Stripe, which are
        available on their website:{' '}
        <span>
          <a href="https://stripe.com/">https://stripe.com/</a>
        </span>
      </p>
      <p>
        By using the Website, Sellers agree that the information, relating to them, including
        personal data, to be provided to Stripe. This information is limited to the information
        required by Stripe for using their payment processing services. This information is also
        subject to the terms of service of Stripe.
      </p>
      <p>
        Money sent as a purchase price for goods sold by the Seller is held in an escrow account.
      </p>
      <p>Escrow funds are not used or spent by NOLD.</p>
      <p>Escrow funds are held by NOLD until the order is confirmed and completed.</p>
      <p>
        Payments in currency, which is different from the display currency of the item, are subject
        to the currency conversion rules, fees and taxes of the credit card operator.
      </p>
      <p>
        When the order is complete, the Seller chooses their preferred form of payout, and
        immediately receives it.&nbsp;
      </p>
      <p>
        The currently available forms of payout are cash payout and store credit (for NOLD
        Partners).
      </p>
      <p>An order is considered complete, when the following conditions have been met:</p>
      <ul>
        <li>The goods have been delivered by the courier.</li>
        <li>The dispute period has expired.</li>
      </ul>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Dispute</strong>
        </li>
      </ol>
      <p>
        Dispute is a period of time in which a Buyer has the right to raise a concern regarding an
        order they have made.
      </p>
      <p>
        The option to initiate a dispute is available for any order with a confirmed payment by the
        Buyer.
      </p>
      <p>
        The current dispute period for all goods on the Website is 3 (three) days (counted from the
        moment the goods have been delivered by the courier/marked as delivered).
      </p>
      <p>After a dispute has been raised, the Buyer has to:</p>
      <ol>
        <li>
          Contact NOLD at the following email address: email address, describing the issue in
          detail;
        </li>
        <li>Contact the Seller in the Chat of the order in question.</li>
      </ol>
      <p>Sellers agree to make their best effort to resolve the dispute amicably.</p>
      <p>If a Buyer has initiated a dispute, the dispute period is extended until:</p>
      <ul>
        <li>the Buyer decides to mark the dispute as resolved; or</li>
        <li>NOLD issues a decision on the dispute, if the dispute has been referred to NOLD.</li>
      </ul>
      <p>Users agree to accept the decision made by NOLD as final and binding.</p>
      <p>Decisions rendered by NOLD fall entirely within its discretion.</p>
      <p>
        NOLD is not a party to the legal relationship between the parties and has no legal
        obligation to participate in dispute resolution.
      </p>
      <p>Grounds for dispute under the present terms are:</p>
      <ul>
        <li>receiving a counterfeit item; or</li>
        <li>
          receiving an item in a condition substantially different than the condition presented in
          the listing.
        </li>
      </ul>
      <p>Shipping costs for returning a counterfeit item are at the expense of NOLD.</p>
      <p>
        Shipping costs for returning an item in a condition substantially different than the
        condition presented in the listing are at the expense of the Buyer.
      </p>
      <p>
        If the parties have agreed, or NOLD has decided, for the item to be returned, the payment
        amount is returned to the Buyer.
      </p>
      <p>
        The buyer has 3 days after that to send the item back to the Seller and provide NOLD and or
        the seller with a tracking Number.
      </p>
      <p>
        If the Seller doesn't receive the item back, he has the right to raise a dispute with NOLD
        regarding the Buyer. The dispute follows the same procedure and is subject to the rules
        above.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Discounts</strong>
        </li>
      </ol>
      <p>
        Discounts for the goods offered on the Website can be provided by NOLD or by Sellers, who
        participate in the “Discount ladder” service.
      </p>
      <p>
        NOLD may provide discounts for the goods offered on the Website. Those discounts do not
        affect the price determined, and therefore received, by the Seller. Those discounts only
        affect the price paid by the Buyer.
      </p>
      <p>Discount ladder</p>
      <p>
        Sellers may opt-in to the “Discount ladder” service offered by NOLD. The Discount ladder is
        a promotional service which allows Sellers to automatically adjust the price of the goods
        they offer, by gradually reducing it at predefined time intervals. The service works in the
        following manner:
      </p>
      <ol>
        <li>
          The Seller opts in the Discount ladder service by clicking the button associated
          therewith;
        </li>
        <li>The Seller selects the minimum amount of the price they are willing to accept;</li>
        <li>
          The NOLD algorithm determines the appropriate price reduction amounts and time intervals
          and automatically applies them.
        </li>
      </ol>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Payout and Sales fees</strong>
        </li>
      </ol>
      <p>Each completed sale on the Website is subject to a fee.</p>
      <p>
        The current amount of the sales fee is determined by the form of payout the Seller chooses
        to receive.
      </p>
      <p>The current forms of payout are the following:</p>
      <ol>
        <li>Payment in currency - cash;</li>
        <li>Payment in store credit.</li>
      </ol>
      <p>Payment in store credit is only available for products of Partner brands or NOLD.</p>
      <p>The current amounts of sale fees are as follows:</p>
      <ol>
        <li>
          For payments in currency – 15% of the sale price + 0.00% Stripe transaction fees described
          in this table;
        </li>
        <li>For payment in store credit – 0% of the sale price.</li>
      </ol>
      <p>
        The Stripe transaction fee amount provided above is for convenience purposes only. Stripe
        transaction fees are subject to change. Although we try to keep our Agreement up-to-date,
        Sellers are responsible for the entire amount of the Stripe transaction fees associated with
        their sales.
      </p>
      <p>
        By choosing to receive payment in store credit, Sellers accept the terms of use of the
        Partners' first-hand website, as well as all the obligations related to using the store
        credit.
      </p>
      <p>
        NOLD has the right to change the available forms of payment and the applicable fees at its
        own discretion.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Cash Payout</strong>
        </li>
      </ol>
      <p>Cash payout is paid to the Sellers' bank account.</p>
      <p>Sellers provide details about their preferred bank account in their profile page.</p>
      <p>
        Currently, NOLD can only offer cash payout with no minimum to bank accounts in banks,
        located in The United Kingdom of Great Britain and Northern Ireland, and member states of
        the European Union.
      </p>
      <p>
        Cash payout to bank accounts in banks, located outside of the countries above, must be for
        the amount of at least 300 Euro and are subject to an additional fee of 2%.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Store credit</strong>
        </li>
      </ol>
      <p>
        Payment in store credit is available for sales of items carrying the brand of selected
        Partners only. The store credit is available for selection after a sale is finalised (i.e.
        the dispute period has expired).
      </p>
      <p>
        Once selected as a payment method, a store credit cannot be substituted with a cash payout.
      </p>
      <p>
        The store credit is in the amount of up to 110% of the Sale price and is not subject to any
        fees by NOLD.
      </p>
      <p>
        The store credit might be different for the various brands available on the marketplace
        and/or the Brand Resale Hubs.
      </p>
      <p>
        The store credit can only be used on the website of the Partner, which owns the brand of the
        item sold, or on the NOLD marketplace.
      </p>
      <p>The store credit has a term of validity of 1 (one) year.</p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Marketing Services – available soon</strong>
        </li>
      </ol>
      <p>The current marketing services offered by NOLD are:</p>
      <ol>
        <li>
          Collection promotion on the Website - via the Collection Promotion section on the profile
          page of the Website;
        </li>
        <li>Instagram promotions.</li>
      </ol>
      <p>
        Collection promotion is conducted via display in the Collection Promotion section, which is
        available on the homepage of the Website.
      </p>
      <p>
        Instagram promotions are Instagram posts and/or stories, available on the NOLD Instagram
        account.
      </p>
      <p>
        The fee for marketing services is determined for each individual service and is payable by
        card only.
      </p>
      <p>
        Additionally, NOLD has the right to promote any and all content available on the Website,
        including all images and content created and/or uploaded by Users, and the User profile
        themselves.
      </p>
      <p>
        NOLD has the right to promote the aforementioned content on any website or platform via any
        type of advertisement at its own discretion.
      </p>
      <p>
        Lastly, and unrelated to any marketing services offered by NOLD, Sellers have the right to
        independently use the listings created by them and the Partner database data contained
        therein for marketing purposes.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Indemnification</strong>
        </li>
      </ol>
      <p>
        Users are solely responsible for all state and municipal fees, duties and taxes, and their
        calculation, collection and reporting, related to listings created by them and sales
        executed on the Website.
      </p>
      <p>
        More specifically, Buyers are solely responsible for all obligations related to customs and
        customs handling of the goods.
      </p>
      <p>
        Sellers are solely responsible for goods conformity, contract withdrawal rights, guarantees,
        return and refund rights, and any and all related consumer rights or consumer protection
        obligations, or any other claims Buyers have in relation to sales executed on the Website.
      </p>
      <p>
        Users are solely responsible for all liabilities resulting from incompliance with the
        obligations described above or any legal obligations Sellers have in relation to the sale of
        goods on the Website.
      </p>
      <p>
        Users agree to indemnify NOLD from any and all claims arising out of or in relation to sales
        of goods executed on the Website, including but not limited to all obligations set out in
        this Agreement, the terms of use of Partners and third parties, and the applicable law.
      </p>
      <p>
        Specifically, Users agree to indemnify NOLD from any and all claims arising out of or in
        relation to state-level obligations, including but not limited to fees, duties and taxes,
        their calculation, collection and reporting, consumer protection, personal data protection,
        any and all related penalties.
      </p>
      <p>
        Goods that have not been received by the Buyers because of a failure to perform any of the
        obligations described above are still considered to be delivered and are therefore
        ineligible for reimbursement under no circumstances.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Intellectual Property</strong>
        </li>
      </ol>
      <p>
        The intellectual property rights on all materials and resources located on NOLD's Website
        (including the available databases) are subject to protection under the applicable law,
        belong to NOLD, or its Partners, or to the respective designated person who assigned the
        right of use to NOLD, and cannot be used in violation of current legislation.
      </p>
      <p>
        In the case of copying or reproduction of information beyond what is permissible, as well as
        in case of any other violation of intellectual property rights on NOLD's resources, NOLD has
        the right to claim compensation for the direct and indirect damages suffered in full.
      </p>
      <p>
        Except in cases where it is expressly agreed, the Seller may not reproduce, change, delete,
        publish, distribute, or publicise in any other way the information resources published on
        the Website.
      </p>
      <p>
        NOLD undertakes to take due care to provide the Seller with an opportunity for normal access
        to the Services provided.
      </p>
      <p>
        NOLD has the right, but not the obligation, at its discretion to delete information
        resources and materials published on its site.
      </p>
      <p>
        Sellers agree that all intellectual property that belongs to the Seller and is hosted on the
        Website, and does not constitute personal data, can be used for marketing purposes by NOLD.
      </p>
      <p>
        Sellers agree that all intellectual property that has been used for marketing purposes in
        the manner described above, can remain in use for marketing purposes, even after the Seller
        account has been terminated, regardless of the reason for the termination.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Termination</strong>
        </li>
      </ol>
      <p>
        Unless otherwise specified in the Agreement, both Parties have the right at their discretion
        to unilaterally terminate the Agreement, with an immediate effect upon notice to the other
        Party.
      </p>
      <p>
        In case NOLD finds that the services provided are used by the Seller in violation of the
        Agreement, NOLD has the right to unilaterally and with no prior notice:
      </p>
      <p>- limit or suspend some of the functionalities of the Seller’s account, and/or</p>
      <p>- limit or suspend some or all of the Seller’s benefits, or</p>
      <p>- terminate the Seller’s account completely</p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Survival provision</strong>
        </li>
      </ol>
      <p>
        The provisions of the Agreement that by their nature are intended to survive, shall survive
        and remain in effect between the Parties after the termination of the Agreement itself.
      </p>
      <p>
        In the event that any of the provisions of the Agreement is found to be invalid, this will
        not invalidate the entire Agreement. The invalid clauses will be superseded by the mandatory
        rules of law or established practice.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Amendment of the Agreement</strong>
        </li>
      </ol>
      <p>
        NOLD undertakes to notify Sellers of any changes in the present Agreement immediately by
        posting them on the Website.
      </p>
      <p>
        NOLD undertakes to additionally notify Sellers of any changes in the Fees, Payments, Account
        or other sections of the Agreement that NOLD deems important via email.
      </p>
      <p>By creating an Account, the Seller accepts the current Agreement with NOLD.</p>
      <p>
        Unless otherwise specified, the Agreement that is applicable to each order is the Agreement
        that was published on the Website at the time the Seller received an order.
      </p>
      <p>&nbsp;</p>
      <ol>
        <li>
          <strong> </strong>
          <strong>Applicable law</strong>
        </li>
      </ol>
      <p>
        The provisions of the current legislation of the United Kingdom of Great Britain and
        Northern Ireland&nbsp;shall apply to all matters not settled by this Agreement.
      </p>
      <p>
        <span>&nbsp;</span>
      </p>
    </>
  );
};

export default TermsAndConditions;
