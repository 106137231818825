import { FieldTextInput } from '../../components';
import { format } from './fieldDateFormatter';

const FieldFullDateInput = props => {
  const inputProps = {
    type: 'text',
    format: format,
    ...props,
  };

  return <FieldTextInput {...inputProps} />;
};

export default FieldFullDateInput;
