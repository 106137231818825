import React from 'react';

import { LayoutSingleColumn, MobileBottomNavigation, Page } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { useWalletTabs } from './ProfilePage.hooks';
import ProfileTabNav from './ProfileTabNav/ProfileTabNav';
import StoreCredits from './StoreCredits/StoreCredits';
import { ProfileWallet } from './Wallet/Wallet';
import css from './WalletPage.module.css';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';

export const WalletTab = {
  PAYOUTS: 'payouts',
  STORE_CREDITS: 'store-credits',
};

const WalletPage: React.FC = props => {
  const config = useConfiguration();
  const scrollingDisabled = useIsScrollingDisabled();
  const schemaTitle = 'Wallet | ' + config.marketplaceName;

  const { currentWalletTab } = useWalletTabs();

  return (
    <Page
      scrollingDisabled={scrollingDisabled}
      title={schemaTitle}
      schema={{
        '@context': 'https://schema.org',
        '@type': 'ProfilePage',
        name: schemaTitle,
      }}
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer currentPage="ProfilePage" />}
        footer={<MobileBottomNavigation />}
      >
        <div className={css.profileContainer}>
          <ProfileTabNav />

          {currentWalletTab === WalletTab.STORE_CREDITS ? (
            <StoreCredits className={css.maxWidthContainer} />
          ) : (
            <ProfileWallet className={css.maxWidthContainer} />
          )}
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default WalletPage;
