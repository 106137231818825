import { useConfiguration } from '../context/configurationContext';
import { getCountryCodes } from '../translations/countryCodes';

export function useCountryCodes(
  showAddressesFrom = 'all',
  userGeolocationCountryCode: string | null
) {
  const config = useConfiguration();

  return getCountryCodes(config.localization.locale, showAddressesFrom, userGeolocationCountryCode);
}
