import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

// This is needed because of the custom config
const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      'font-size': [
        'text-0000',
        'text-000',
        'text-00',
        'text-0-in-px',
        'text-0',
        'text-1',
        'text-2',
        'text-3',
        'text-4',
        'text-5',
        'text-6',
        'text-7',
      ],
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
