import React from 'react';

import css from './AddressesEmptyState.module.css';
import { PrimaryButton } from 'components';

type P = {
  onCreateAddressClick: () => void;
};

const AddressesEmptyState: React.FC<P> = ({ onCreateAddressClick }) => {
  return (
    <div className={css.root}>
      <div className={css.primaryText}>No Addresses</div>
      <div className={css.secondaryText}>
        Looks like your shipping address is missing. Set it up to ensure smooth delivery of your
        purchases!
      </div>
      <PrimaryButton onClick={onCreateAddressClick}>Add Address</PrimaryButton>
    </div>
  );
};

export default AddressesEmptyState;
