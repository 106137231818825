import { Form as FinalForm } from 'react-final-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { PrimaryButton } from '..';
import { useUpdateCurrentUser } from '../../hooks/api/users/useUpdateCurrentUser';
import * as validators from '../../util/validators';
import FieldFullDateInput from '../FieldFullDateInput/FieldFullDateInput';
import { formatDate } from 'util/dates';

export function DateOfBirthForm({ onDateOfBirthUpdated }) {
  const intl = useIntl();
  const { mutateAsync: updateCurrentUser, isLoading } = useUpdateCurrentUser();

  return (
    <div>
      <FinalForm
        onSubmit={async values => {
          await updateCurrentUser({
            privateData: { dateOfBirth: formatDate(values.dateOfBirth) },
          });
          onDateOfBirthUpdated();
        }}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit} style={{ display: 'grid', gap: 'var(--n-size-2)' }}>
            <FieldFullDateInput
              placeholder="DD/MM/YYYY"
              label={intl.formatMessage({ id: 'SignupForm.dobLabel' })}
              id={'dateOfBirth'}
              name="dateOfBirth"
              type="text"
              validate={validators.composeValidators(
                validators.required(intl.formatMessage({ id: 'SignupForm.dobRequiredMessage' })),
                validators.isValidDate(intl.formatMessage({ id: 'SignupForm.dobInvalidMessage' }))
              )}
            />
            <div>
              <PrimaryButton type="submit" inProgress={isLoading}>
                <FormattedMessage id="DateOfBirthModal.dobSubmitButton" />
              </PrimaryButton>
            </div>
          </form>
        )}
      />
    </div>
  );
}
