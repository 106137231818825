import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { QUERY_KEY, updateAddress } from './utils';
import { handleQueryError } from 'app';
import ToastContent from 'components/ToasterContent/ToastContent';

export const useUpdateAddress = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: address => updateAddress(address),
    onMutate: async address => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([QUERY_KEY]);

      // Snapshot the previous value
      const previousAddresses = queryClient.getQueryData([QUERY_KEY]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        [QUERY_KEY],
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        previousAddresses.map(a => (a.id === address.id ? address : a))
      );

      // Return a context object with the snapshotted value
      return { previousAddresses };
    },
    onError: (error: any, __, context) => {
      // Roll back to the previous value
      if (context?.previousAddresses) {
        queryClient.setQueryData([QUERY_KEY], context.previousAddresses);
      }
      if (error.data?.errors[0]?.type === 'ShippoError') {
        toast.error(
          t => <ToastContent messages={error.data.errors[0].validationMessages} toastId={t.id} />,
          {
            duration: 10000,
          }
        );
      } else {
        handleQueryError('Failed to create address. Please check your input and try again');
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
    },
    meta: { name: 'updateAddress' },
  });
};
