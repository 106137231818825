import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { fetchCurrentUser } from '../../../ducks/user.duck';
import { authInfo } from 'ducks/auth.duck';
import {
  LOGIN_GUEST_ACCOUNT_ERROR_MESSAGE,
  LOGIN_INVALID_CREDENTIALS_ERROR_MESSAGE,
} from 'util/errors';
import { useSdk } from 'util/sdkContext';

export const useLogin = () => {
  const sdk = useSdk();
  const dispatch = useDispatch();

  return useMutation({
    mutationFn: async ({ email, password }: { email: string; password: string }) => {
      try {
        const response = await sdk.login({ username: email, password });

        if (response.status !== 200) {
          throw new Error(LOGIN_INVALID_CREDENTIALS_ERROR_MESSAGE);
        }

        const currentUser = await sdk.currentUser.show({});
        const { privateData } = currentUser.data.data.attributes.profile;

        if (privateData?.guestAccount) {
          await sdk.logout();
          throw new Error(LOGIN_GUEST_ACCOUNT_ERROR_MESSAGE);
        }

        const tokenData = {
          privateData: {
            accessToken: response?.data?.access_token,
            refreshToken: response?.data?.refresh_token,
          },
        };
        await sdk.currentUser.updateProfile(tokenData);
      } catch (error) {
        throw error;
      }
    },
    onSuccess: async () => {
      await dispatch(authInfo());
      await dispatch(fetchCurrentUser());
    },
    meta: { name: 'login' },
  });
};
