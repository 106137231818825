import { useGetPayouts } from 'hooks/api/payouts';
import { TransactionFilter, useGetTransactions } from 'hooks/api/transactions';

export const useGetPayoutTransactions = () => {
  const { data: transactionsData, isLoading: loadingTransactions } = useGetTransactions({
    filter: TransactionFilter.SALES,
  });
  const transactions = transactionsData?.pages?.map((page: any) => page.data)?.flat() || [];
  const { data: payoutsData = {}, isLoading: loadingPayouts } = useGetPayouts();
  const { earned = 0, toClaim = 0 } = payoutsData || {};

  const payouts: any[] = payoutsData?.payouts || [];
  const transactionsWithPayouts = transactions
    .map(t => {
      const payout = payouts.find(p => p.transactionId === t.id.uuid);
      return { ...t, payout };
    })
    .filter(t => t.payout);

  const isLoading = loadingTransactions || loadingPayouts;

  return { transactionsWithPayouts, isLoading, earned, toClaim };
};
