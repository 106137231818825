import * as Popover from '@radix-ui/react-popover';
import React, { useEffect, useRef, useState } from 'react';
import { useDebounceValue } from 'usehooks-ts';

import { useGetAddressSuggestions } from '../../../../../hooks/api/addresses';
import css from './AddressCombobox.module.css';
import { IconSpinner } from 'components';
import { Address } from 'hooks/api/addresses/useGetAddressSuggestions';

interface AddressComboboxProps {
  searchData?: string[];
  value: string;
  onAddressClick: (address: Address) => void;
  disabled?: boolean;
  onChange: (value: string) => void;
  country?: string;
}

const AddressCombobox: React.FC<AddressComboboxProps> = ({
  disabled,
  onAddressClick,
  value = '',
  onChange,
  searchData,
  country = '',
}) => {
  const [containerId, setContainerId] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const searchDataString = searchData?.join(', ') || '';
  const searchText = useDebounceValue(`${searchDataString}, ${value}`, 500);
  const { data, isLoading, isError } = useGetAddressSuggestions({
    text: searchText[0],
    containerId,
    country,
  });
  const addresses = data?.Items || [];
  const noResults = addresses.length === 0 && value.length > 0;

  useEffect(() => {
    const activeButton = popoverRef.current?.querySelector(
      `[data-active="true"]`
    ) as HTMLButtonElement;
    activeButton?.scrollIntoView({ block: 'nearest' });
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(0);
  }, [data]);

  const handleItemClick = (item: Address) => {
    if (item.Type === 'Container') {
      setContainerId(item.Id);
      setActiveIndex(0);
      // Add a space so that we can delete the container id with backspace
      onChange(value + ' ');
    } else if (item.Type === 'Address') {
      onAddressClick(item);
      setIsPopoverOpen(false); // Close the popover when an address is selected
    }
  };

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setActiveIndex(prevIndex => (prevIndex + 1) % addresses.length);
    } else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setActiveIndex(prevIndex => (prevIndex - 1 + addresses.length) % addresses.length);
    } else if (e.key === 'Enter') {
      e.preventDefault();
      e.stopPropagation();
      if (addresses.length > 0) {
        handleItemClick(addresses[activeIndex]);
      }
    } else if (e.key === 'Backspace') {
      setContainerId('');
    } else if (e.key === 'Escape') {
      setIsPopoverOpen(false); // Close the popover when the escape key is pressed
      e.preventDefault();
    }
  };

  return (
    <div className={css.combobox}>
      <Popover.Root open={isPopoverOpen && value.length > 0 && !disabled}>
        <Popover.Anchor asChild>
          <input
            type="text"
            value={value}
            ref={inputRef}
            placeholder="Search for an address"
            onChange={e => {
              onChange(e.target.value);
              setIsPopoverOpen(true); // Open the popover when the input value changes
            }}
            onKeyDown={handleInputKeyDown}
            onBlur={e => {
              if (
                e.relatedTarget &&
                (e.relatedTarget as HTMLElement).getAttribute('data-popover-button')
              ) {
                return;
              }

              if (popoverRef.current?.contains(e.relatedTarget as Node)) {
                return;
              }

              setIsPopoverOpen(false);
            }}
            disabled={disabled}
          />
        </Popover.Anchor>
        <Popover.Portal>
          <Popover.Content
            ref={popoverRef}
            onOpenAutoFocus={e => e.preventDefault()}
            className={css.popoverContent}
            avoidCollisions={false}
            collisionPadding={32}
            sideOffset={8}
            style={{ width: inputRef.current?.offsetWidth }}
          >
            <div className={css.popoverContentWidthWrapper}>
              {isLoading && (
                <div className={css.contentLoading}>
                  <IconSpinner />
                </div>
              )}
              {isError && (
                <div className={css.contentError}>
                  <div>Couldn't get addresses</div>
                </div>
              )}
              {!isLoading && noResults && !isError && (
                <div>No matching addresses found. Please try a different search.</div>
              )}
              {!isLoading &&
                addresses.map((address, index) => (
                  <button
                    key={address.Id}
                    data-active={index === activeIndex}
                    data-popover-button
                    data-is-container={address.Type === 'Container'}
                    onClick={() => handleItemClick(address)}
                    type="button"
                  >
                    {address.Text}
                    <span>, {address.Description}</span>
                  </button>
                ))}
            </div>
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>
    </div>
  );
};

export default AddressCombobox;
