import { useQuery } from '@tanstack/react-query';

import { LOQATE_API_KEY } from 'config/env';

export interface AddressData {
  Items: Address[];
}

export interface Address {
  Id: string;
  Type: 'Address' | 'Container';
  Text: string;
  Highlight: string;
  Description: string;
}

export const useGetAddressSuggestions = ({ text, containerId, country }) => {
  return useQuery<AddressData>({
    queryKey: ['addressSearchResults', text, containerId],
    queryFn: async () => {
      const response = await fetch(
        'https://api.addressy.com/Capture/Interactive/Find/v1.1/json3.ws?' +
          new URLSearchParams({
            Key: LOQATE_API_KEY,
            Text: text,
            Container: containerId,
            Countries: country,
          })
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      return response.json();
    },
  });
};
