import { useMutation } from '@tanstack/react-query';

import { useSdk } from 'util/sdkContext';

export const useResetPassword = () => {
  const sdk = useSdk();

  return useMutation({
    mutationFn: async email => {
      return sdk.passwordReset.request({ email });
    },
    meta: {
      name: 'resetPassword',
    },
  });
};
