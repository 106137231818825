/**
 * This component will show the booking info and calculated total price.
 * I.e. dates and other details related to payment decision in receipt format.
 */
import classNames from 'classnames';
import React from 'react';

import { formatMoney } from '../../util/currency';
import { useIntl } from '../../util/reactIntl';
import LineItemTotalPrice from './LineItemTotalPrice';
import css from './OrderBreakdown.module.css';

const lineItemLabels = new Map([
  ['line-item/item', 'Subtotal'],
  ['line-item/buyer-protection-fee', 'Buyer protection fee'],
  ['line-item/nold-credit', 'Nold credit'],
  ['line-item/shipping-fee', 'Shipping'],
  ['line-item/authentication-fee', 'Authentication fee'],
]);

type OrderBreakdownProps = {
  rootClassName?: string;
  className?: string;
  lineItems: any[];
  total: any;
};

const OrderBreakdown: React.FC<OrderBreakdownProps> = props => {
  const { rootClassName, className, lineItems, total } = props;
  const intl = useIntl();

  const itemsToDisplay = lineItems
    .map(item => ({
      ...item,
      label: lineItemLabels.get(item.code) || lineItemToDiscountCodeLabel(item.code),
    }))
    .filter(item => item.label);

  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
      {itemsToDisplay.map(item => (
        <div key={item.code} style={{ display: 'flex', gap: 8, justifyContent: 'space-between' }}>
          <div>
            {item.label}
            {item?.voucher ? ` - ${item.voucher}` : null}
          </div>
          <div>{formatMoney(intl, item.unitPrice)}</div>
        </div>
      ))}

      <LineItemTotalPrice intl={intl} totalPrice={total} />
    </div>
  );
};

function lineItemToDiscountCodeLabel(code: string | undefined): string | undefined {
  return code?.startsWith('line-item/discount-code-')
    ? `Discount code (${code.replace('line-item/discount-code-', '')})`
    : undefined;
}

export default OrderBreakdown;
