import * as Label from '@radix-ui/react-label';
import React from 'react';

type TextInputProps = {
  label?: string;
  className?: string;
  value?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
} & (
  | ({ textarea: true } & React.TextareaHTMLAttributes<HTMLTextAreaElement>)
  | ({ textarea?: false } & React.InputHTMLAttributes<HTMLInputElement>)
);

export const TextInput: React.FC<TextInputProps> = props => {
  const { label, className, value, onChange, textarea = false, ...rest } = props;

  const componentProps = {
    className,
    value,
    onChange,
    // TODO: Fix types
    ...(rest as any),
  };

  return (
    <Label.Root>
      {label}
      {textarea ? <textarea {...componentProps} /> : <input {...componentProps} />}
    </Label.Root>
  );
};

const FieldTextInput = () => {
  return <TextInput />;
};

export default FieldTextInput;
