import { useMutation } from '@tanstack/react-query';

import { useSdk } from 'util/sdkContext';

export const useSendVerificationEmail = () => {
  const sdk = useSdk();

  return useMutation({
    mutationFn: () => {
      return sdk.currentUser.sendVerificationEmail();
    },
    meta: {
      name: 'sendVerificationEmail',
    },
  });
};
