import { useMutation } from '@tanstack/react-query';

import { post } from 'util/httpsClient';

export interface SubscribeToNewsletterParams {
  email: string;
  firstName?: string;
  lastName?: string;
}

export const useSubscribeToNewsletter = () => {
  return useMutation({
    mutationFn: ({ email, firstName, lastName }: SubscribeToNewsletterParams) => {
      return post({ path: '/subscribe', body: { email, firstName, lastName } });
    },
    meta: { name: 'subscribeToNewsletter' },
  });
};
