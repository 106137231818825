import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';

import { IconPlus } from '../../../assets/icons';
import {
  AspectRatioWrapper,
  IconSpinner,
  NamedLink,
  PrimaryButton,
  ReviewModal,
} from '../../../components';
import { useCurrentUser } from '../../../hooks/selectors/useCurrentUser';
import { PayoutModal } from '../PayoutModal/PayoutModal';
import { useGetPayoutTransactions } from '../WalletPage.hooks';
import { RetryModal, SuccessModal, VerificationModal } from './StripeVerificationModal';
import css from './Wallet.module.css';
import StarRating from 'containers/ProfilePage/StarRating/StarRating';
import { SettingsPageTab } from 'containers/SettingsPage/SettingsPage';
import { useGetStripeAccount } from 'hooks/api/users/useGetStripeAccount';
import { addSymbolToFormattedPrice } from 'util/currency';

export const ProfileWallet: React.FC<{
  className?: string;
}> = ({ className }) => {
  const { toClaim, earned, transactionsWithPayouts, isLoading } = useGetPayoutTransactions();
  // TODO: We're relying on the fact that each user has their currency 'geo-locked'. Re-implement this once we
  // introduce multiple currencies per user
  const currency =
    !isLoading && transactionsWithPayouts.length > 0
      ? transactionsWithPayouts[0].payout.currency
      : 'GBP';
  return (
    <div className={classNames(css.mobileWallets, className)}>
      <div className={css.header}>
        <div className={css.earned}>
          <FormattedCurrency value={toClaim} currency={currency} />
          <span className={css.earned__text}>To claim</span>
        </div>
        <div className={css.earned}>
          <FormattedCurrency value={earned} currency={currency} />
          <span className={css.earned__text}>Earned in sales</span>
        </div>
      </div>
      {transactionsWithPayouts?.length > 0 && (
        <ul className={css.root}>
          {transactionsWithPayouts.map(r => {
            return (
              <li key={r.id.uuid} className={css.walletItem}>
                <Sale transaction={r} />
              </li>
            );
          })}
        </ul>
      )}
      {isLoading && (
        <div style={{ padding: 'var(--n-size-4)', display: 'grid', placeContent: 'center' }}>
          <IconSpinner />
        </div>
      )}
      {Boolean(!transactionsWithPayouts?.length) && (
        <div className={css.emptyStateWithButton}>
          <span>Hey! We can’t wait for you to start selling, earning and making a difference!</span>
          <NamedLink name="ResellLandingPage">
            <PrimaryButton>
              <IconPlus />
              Add a listing
            </PrimaryButton>
          </NamedLink>
        </div>
      )}
    </div>
  );
};

export const FormattedCurrency = ({ value, currency }) => {
  const beforeDecimal = Math.floor(value);
  const afterDecimal = (value - beforeDecimal).toFixed(2).slice(2);
  // TODO: redesign when we introduce currency conversion
  const symbol = currency === 'EUR' ? '€' : '£';

  return (
    <span>
      {symbol}
      {beforeDecimal}
      <sub style={{ bottom: 'none' }}>.{afterDecimal}</sub>
    </span>
  );
};

const Sale = ({ transaction }) => {
  const data = {
    id: transaction.id,
    title: transaction.listing.attributes.title,
    brandName: transaction.listing.attributes.publicData?.brandName,
    brandId: transaction.listing.attributes.publicData?.brandId,
    image: transaction.listing?.images?.[0]?.attributes?.variants?.['square-small'].url,
    price: transaction.payout.baseAmount,
    status: transaction.attributes.lastTransition.transition,
    payoutClaimed: Boolean(transaction.payout.claimType),
    bankAccount: transaction.payout?.bankAccount || '',
    rating: transaction.attributes.protectedData?.providerReview?.rating,
  };

  const intl = useIntl();

  const [verificationModalOpen, setVerificationModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [retryModalOpen, setRetryModalOpen] = useState(false);

  const {
    data: stripeAccount,
    isLoading: isStripeAccountLoading,
    refetch: refetchVerificationLink,
  } = useGetStripeAccount();
  const [reviewModal, setReviewModal] = useState({ open: false, initialRating: 0 });
  const [payoutModalOpen, setPayoutModalOpen] = useState(false);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const verificationStatus = queryParams.get('verification_status');

    if (verificationStatus === 'success') {
      setSuccessModalOpen(true);
      queryParams.delete('verification_status');
      window.history.replaceState(null, '', `${window.location.pathname}`);
    } else if (verificationStatus === 'retry') {
      refetchVerificationLink();
      setRetryModalOpen(true);
      queryParams.delete('verification_status');
      window.history.replaceState(null, '', `${window.location.pathname}`);
    }
  }, [refetchVerificationLink]);

  const handleClaimPayoutClicked = () => {
    const stripeSetupComplete = Boolean(
      currentUser?.attributes?.profile?.privateData?.stripeSetupComplete
    );
    if (!stripeSetupComplete) {
      toast.error(
        <div>
          Please{' '}
          <NamedLink
            name="SettingsPage"
            params={{ tab: SettingsPageTab.BankAccount }}
            style={{
              textDecoration: 'underline',
            }}
          >
            set up your bank account
          </NamedLink>{' '}
          before claiming your payout.
        </div>
      );
    } else if (stripeAccount && stripeAccount.verificationLink && !isStripeAccountLoading) {
      setVerificationModalOpen(true);
    } else if (!isStripeAccountLoading) {
      setPayoutModalOpen(true);
    }
  };

  const aspectWidth = 1;
  const aspectHeight = 1;

  const payout = addSymbolToFormattedPrice(
    intl,
    transaction.payout.claimType === 1 // TODO: Use enum
      ? transaction.payout.cashAmount
      : transaction.payout.creditAmount,
    transaction.attributes.payoutTotal.currency
  );

  const price = addSymbolToFormattedPrice(
    intl,
    data.price,
    transaction.attributes.payoutTotal.currency
  );

  return (
    <>
      <VerificationModal
        isOpen={verificationModalOpen}
        onClose={() => setVerificationModalOpen(false)}
        verificationLink={stripeAccount?.verificationLink || ''}
      />
      <SuccessModal isOpen={successModalOpen} onClose={() => setSuccessModalOpen(false)} />
      <RetryModal
        isOpen={retryModalOpen}
        onClose={() => setRetryModalOpen(false)}
        verificationLink={stripeAccount?.verificationLink || ''}
      />

      <PayoutModal
        payoutModalOpen={payoutModalOpen}
        setPayoutModalOpen={setPayoutModalOpen}
        transaction={transaction}
      />
      <div className={css.sectionWrapper}>
        <NamedLink name="ChatPage" to={{ search: `?chatId=${transaction.id.uuid}&filter=sales` }}>
          <AspectRatioWrapper
            width={aspectWidth}
            height={aspectHeight}
            className={css.imageContainer}
          >
            <img className={css.imageWrapper} src={data.image} alt="product" />
          </AspectRatioWrapper>
        </NamedLink>
        <NamedLink name="ChatPage" to={{ search: `?chatId=${transaction.id.uuid}&filter=sales` }}>
          <div className={css.priceInfo}>
            {data.brandName && (
              <NamedLink name="SearchPage" to={{ search: `?brandIds=${data.brandId}` }}>
                <span className={css.brandName}>{data.brandName}</span>
              </NamedLink>
            )}
            <span className={css.title}>{data.title}</span>
            <span className={css.resalePrice}>
              <span className={css.price}>{price}</span>
              <FormattedMessage id="ProfilePage.wallet.resalePriceLabel" />
            </span>
          </div>
        </NamedLink>
        <div className={css.statusWrapper}>
          <span className={css.status}>
            {data.payoutClaimed ? (
              payout
            ) : (
              <FormattedMessage id="ProfilePage.wallet.successfulSale" />
            )}
          </span>
          <span className={css.statusDescription}>
            {data.payoutClaimed ? (
              transaction.payout.claimType === 1 ? ( // TODO: Use enum
                <FormattedMessage
                  id="ProfilePage.wallet.claimedInCash"
                  values={{
                    accountEndsIn: data.bankAccount,
                  }}
                />
              ) : (
                <FormattedMessage id="ProfilePage.wallet.claimedInCredit" />
              )
            ) : (
              <FormattedMessage id="ProfilePage.wallet.readyToBeClaimed" />
            )}
          </span>
        </div>
        <div className={css.orderInfo}>
          {!data.payoutClaimed && (
            <PrimaryButton onClick={handleClaimPayoutClicked}>
              <FormattedMessage id="ProfilePage.wallet.claimPayout" />
            </PrimaryButton>
          )}
          <span className={css.reviewBuyer}>
            {!data.rating && <FormattedMessage id="ProfilePage.wallet.reviewBuyer" />}
            <span>
              <StarRating
                rating={data.rating || 0}
                onClick={
                  data.rating
                    ? undefined
                    : i => setReviewModal(v => ({ ...v, open: true, initialRating: i }))
                }
              />
              <ReviewModal
                open={reviewModal.open}
                initialRating={reviewModal.initialRating}
                transactionId={transaction.id.uuid}
                onClose={() => setReviewModal(v => ({ ...v, open: false }))}
              />
            </span>
          </span>
          {data.payoutClaimed && transaction.payout.lastModified && (
            <span className={css.date}>
              {formatDateToDDMMYYYY(new Date(transaction.payout.lastModified))}
            </span>
          )}
        </div>
      </div>
    </>
  );
};

export const formatDateToDDMMYYYY = date => {
  if (!date) {
    return '';
  }

  if (typeof date === 'string') {
    date = new Date(date);
  }

  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = date.getFullYear();

  return `${day}.${month}.${year}`;
};
