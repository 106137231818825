import { useIntl } from 'react-intl';

import {
  LayoutSingleColumn,
  MobileBottomNavigation,
  NamedLink,
  Page,
  PrimaryButton,
} from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from 'containers/FooterContainer/FooterContainer';

const ThankYouPage = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'ThankYouPage.title', defaultMessage: 'Thank You' });

  return (
    <Page title={title}>
      <LayoutSingleColumn
        topbar={<TopbarContainer currentPage="ThankYouPage" />}
        footer={<MobileBottomNavigation />}
      >
        <main className="flex flex-col items-center justify-center flex-grow text-center px-6 py-10 sm:py-16">
          <h1 className="font-syne text-3 sm:text-4 font-bold uppercase mb-6">
            {intl.formatMessage({
              id: 'ThankYouPage.heading',
              defaultMessage: 'Thank You for Your Order!',
            })}
          </h1>
          <p className="font-montserrat text-1 sm:text-1 mb-8 max-w-2xl">
            {intl.formatMessage({
              id: 'ThankYouPage.message',
              defaultMessage: 'We appreciate your business and hope you enjoy your purchase.',
            })}
          </p>
          <NamedLink name="SearchPage">
            <PrimaryButton>continue shopping</PrimaryButton>
          </NamedLink>
        </main>
        <FooterComponent />
      </LayoutSingleColumn>
    </Page>
  );
};

export default ThankYouPage;
