import { put } from 'util/httpsClient';

export const QUERY_KEY = 'userAddresses';

export const updateAddress = address => {
  const { id, shippingId, userId, isDeleted, createdOn, lastModified, ...rest } = address;
  address.validate = false;

  return put({ path: `/addresses/${id}`, body: rest });
};
