import { useInfiniteQuery } from '@tanstack/react-query';

import { GetListingsRequest } from 'containers/SearchPage/SearchPage.hooks';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { get } from 'util/httpsClient';
import { buildQueryString } from 'util/httpUtils';

export const useGetFavouriteListings = (params: GetListingsRequest) => {
  const { userGeolocation } = useGeolocation();
  const { currentUser } = useCurrentUser();

  return useInfiniteQuery({
    enabled: Boolean(currentUser.id?.uuid),
    queryKey: ['favoriteListings', { ...params, userGeolocation }],
    queryFn: async ({ pageParam = 1 }) => {
      const queryString = buildQueryString({
        ...params,
        country: userGeolocation,
        page: pageParam,
      });

      const path = `/listings/favourites${queryString ? `?${queryString}` : ''}` as const;

      const response = await get({
        path,
      });

      return {
        listings: response.listings,
        currentPage: response.meta.page,
        totalPages: response.meta.totalPages,
      };
    },
    getNextPageParam: lastPage => {
      if (lastPage.currentPage < lastPage.totalPages) {
        return lastPage.currentPage + 1;
      }
      return undefined;
    },
    meta: {
      errorMessage: 'Failed to fetch favourite listings',
    },
  });
};
