import { ExpressCheckoutAddress } from '@stripe/stripe-js';

import { useCreateAddress, useGetAddresses } from 'hooks/api/addresses';

export const useSaveAddressIfNonExistent = () => {
  const { data: addressesQueryData, refetch } = useGetAddresses();
  const { mutateAsync: createAddress } = useCreateAddress();

  return async (
    addressData: { name: string; phone: string; address: ExpressCheckoutAddress } | undefined
  ) => {
    if (!addressData) {
      return;
    }

    let addresses = addressesQueryData;
    if (!addresses) {
      addresses = await refetch();
    }

    const existingAddress = addresses?.find(
      addr =>
        addr.street1 === addressData.address.line1 &&
        addr.street2 === addressData.address.line2 &&
        addr.city === addressData.address.city &&
        addr.country === addressData.address.country &&
        addr.zip === addressData.address.postal_code
    );

    if (existingAddress) {
      return existingAddress;
    }

    try {
      const newAddress = await createAddress({
        fullName: addressData.name,
        country: addressData.address.country,
        phoneNumber: addressData.phone,
        zip: addressData.address.postal_code,
        street1: addressData.address.line1,
        street2: addressData.address.line2 || '',
        city: addressData.address.city,
        validate: false,
      });

      return newAddress;
    } catch (error) {
      throw error;
    }
  };
};
