import React, { useEffect, useMemo } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { useDebounceValue } from 'usehooks-ts';

import { useGetShippingRates } from '../CheckoutPage.hooks';
import { AddressFormFields } from 'containers/SettingsPage/ProfileAddressesForm/components/AddressForm/AddressForm';
import { useGeolocation } from 'hooks/useGeolocation';

type P = {
  showAddressesFrom: 'GB' | 'EU' | 'all';
  billing?: boolean;
  isLoadingCurrentUser: boolean;
  setIsRatesLoading: (isRatesLoading: boolean) => void;
  setRates: (rates: any) => void;
};

export const CheckoutAddressFieldsGuest: React.FC<P> = React.memo(
  ({ showAddressesFrom, billing, setRates, setIsRatesLoading, isLoadingCurrentUser }) => {
    const formState = useFormState();
    const params = useParams<{ id: string }>();
    const listingId = params.id;
    const { userGeolocation } = useGeolocation();

    const fieldPrefix = billing ? 'billing' : '';
    const fieldName = (name: string) =>
      fieldPrefix ? `${fieldPrefix}${name.charAt(0).toUpperCase()}${name.slice(1)}` : name;

    const requiredFields = ['fullName', 'country', 'city', 'zip', 'street1', 'phoneNumber'].map(
      field => fieldName(field)
    );

    const isAllFieldsFilled = requiredFields.every(field => formState.values[field]);

    const isAllFieldsValid = requiredFields.every(field => !formState.errors?.[field]);

    const canFetchRates = isAllFieldsFilled && isAllFieldsValid && !billing;

    const getRateParams = useMemo(() => {
      if (canFetchRates) {
        return {
          listingId,
          userGeolocation,
          address: {
            country: formState.values.country,
            city: formState.values.city,
            zip: formState.values.zip,
            street1: formState.values.street1,
            fullName: formState.values.fullName,
            phoneNumber: formState.values.phoneNumber,
          },
        };
      } else {
        return null;
      }
    }, [canFetchRates, formState.values, listingId, userGeolocation]);

    const [debouncedGetRateParams] = useDebounceValue(getRateParams, 1000);

    const {
      data: shippingRatesData,
      isSuccess,
      isLoading,
    } = useGetShippingRates(
      debouncedGetRateParams || {
        listingId: '',
        userGeolocation: '',
        address: { country: '', city: '', zip: '', street1: '', fullName: '', phoneNumber: '' },
      },
      {
        enabled: Boolean(debouncedGetRateParams && !billing),
      }
    );

    useEffect(() => {
      setIsRatesLoading(isLoading && canFetchRates);
      if (isSuccess && shippingRatesData) {
        setRates(shippingRatesData);
      }
    }, [isSuccess, setRates, shippingRatesData, setIsRatesLoading, isLoading, canFetchRates]);

    if (isLoadingCurrentUser) {
      return <div>Loading user data...</div>;
    }

    return (
      <>
        <Field name={fieldPrefix}>{props => <input hidden {...props.input} />}</Field>
        <div style={{ display: 'grid', gap: 24 }}>
          <AddressFormFields
            isGuest={true}
            showAddressesFrom={showAddressesFrom}
            fieldPrefix={billing ? 'billing' : ''}
          />
        </div>
      </>
    );
  }
);
