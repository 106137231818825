import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import React, { ChangeEventHandler, FormEventHandler } from 'react';
import { useHistory } from 'react-router-dom';

import { IconClose, IconSearch, LogoNold } from '../../assets/icons';
import noImagePlaceholder from '../../assets/no-image-placeholder.png';
import { Button, LimitedAccessBanner, NamedLink } from '../../components';
import appSettings from '../../config/settings';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { useGetUserSocials, useSearchUsers, useToggleFollowUser } from '../../hooks/api/users';
import { useIntl } from '../../util/reactIntl';
import { pathByRouteName } from '../../util/routes';
import { DOBModal } from './DOBModal';
import css from './Topbar.module.css';
import TopbarDesktop from './TopbarDesktop/TopbarDesktop';
import TopbarMobileMenu from './TopbarMobileMenu/TopbarMobileMenu';
import { useLogout } from 'hooks/api/auth';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useMediaQueries } from 'hooks/useMediaQueries';
import LearnMorePopup from './LearnMorePopup';

type TopbarComponentProps = {
  className?: string;
  rootClassName?: string;
  desktopClassName?: string;
  mobileRootClassName?: string;
  mobileClassName?: string;
  isAuthenticated: boolean;
  authScopes?: any[];
  currentUser?: any;
  currentPage?: string;
  currentSearchParams?: any;
  onSearchChange?: (text: string) => void;
};

const Topbar: React.FC<TopbarComponentProps> = props => {
  const {
    className,
    rootClassName,
    desktopClassName,
    mobileRootClassName,
    mobileClassName,
    isAuthenticated,
    authScopes = [],
    currentUser,
    currentPage,
    onSearchChange,
  } = props;

  const history = useHistory();
  const config = useConfiguration();
  const intl = useIntl();
  const isDesktop = useMediaQueries({ viewport: 'medium' });
  const routeConfiguration = useRouteConfiguration();
  const [searchPopoverOpen, setSearchPopoverOpen] = React.useState(false);

  const { mutateAsync: logout } = useLogout();
  const handleLogout = () => {
    logout().then(() => {
      const path = pathByRouteName('LandingPage', routeConfiguration);

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (appSettings.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
        window.location = path;
      }

      // TODO: Clear data from store + react query + localStorage
      console.log('logged out');
    });
  };

  const handleSubmitSearch: FormEventHandler<HTMLFormElement> = e => {
    e.preventDefault();

    if (search.startsWith('@')) {
      return;
    }

    if (onSearchChange) {
      setSearchPopoverOpen(false);
    } else {
      const search = new FormData(e.target as HTMLFormElement).get('search') as string;
      const searchPagePath = pathByRouteName('SearchPage', routeConfiguration);
      history.push(`${searchPagePath}?text=${search}`);
    }
  };

  const [search, setSearch] = React.useState('');
  const isUserSearch = search.startsWith('@');
  const handleSearchChange: ChangeEventHandler<HTMLInputElement> = e => {
    const value = e.target.value;

    if (!value.startsWith('@')) {
      onSearchChange?.(value);
    }

    setSearch(value);
  };

  const classes = classNames(rootClassName || css.root, className);
  const topbarRef = React.useRef<HTMLDivElement>(null);

  return (
    <Popover.Root
      open={searchPopoverOpen}
      onOpenChange={open => {
        setSearchPopoverOpen(open);
        if (!open) setSearch('');
      }}
    >
      <DOBModal />
      <LearnMorePopup />
      <div className={classes} ref={topbarRef}>
        {!isDesktop && (
          <div className={css.promotionBanner}>
            <span>USE "SAMPLES25" FOR 25% OFF ALL SAMPLES</span>
          </div>
        )}
        {isDesktop && (
          <div className={css.promotionBanner}>
            <span>USE "SAMPLES25" FOR 25% OFF ALL SAMPLES</span>
          </div>
        )}
        <Popover.Anchor />
        <LimitedAccessBanner
          isAuthenticated={isAuthenticated}
          authScopes={authScopes}
          currentUser={currentUser}
          onLogout={handleLogout}
          currentPage={currentPage}
        />
        <div className={classNames(mobileRootClassName || css.mobileContainer, mobileClassName)}>
          <TopbarMobileMenu isAuthenticated={isAuthenticated} onLogout={handleLogout} />
          <NamedLink name="LandingPage">
            <LogoNold
              width="auto"
              height={16}
              title={intl.formatMessage({ id: 'Topbar.logoIcon' })}
            />
          </NamedLink>
          <Popover.Trigger asChild>
            <Button
              rootClassName={css.searchMenu}
              title={intl.formatMessage({ id: 'Topbar.searchIcon' })}
            >
              <IconSearch />
            </Button>
          </Popover.Trigger>
        </div>
        <div className={css.desktop}>
          <TopbarDesktop
            className={desktopClassName}
            currentUser={currentUser}
            currentPage={currentPage}
            intl={intl}
            isAuthenticated={isAuthenticated}
            onLogout={handleLogout}
            appConfig={config}
          />
        </div>

        <Popover.Content asChild>
          <div
            className={css.searchPopover}
            style={
              {
                '--topbarHeight': `${topbarRef.current?.offsetHeight}px`,
              } as React.CSSProperties
            }
          >
            <form onSubmit={handleSubmitSearch}>
              <IconSearch width={24} height={24} />
              <input
                name="search"
                onChange={handleSearchChange}
                placeholder='Use "@" to search users'
              />
              <Popover.Close>
                <IconClose />
              </Popover.Close>
            </form>
            {isUserSearch && (
              <UserSearch search={search} onClose={() => setSearchPopoverOpen(false)} />
            )}
            {isUserSearch && (
              <div
                className={css.overlay}
                onClick={() => {
                  setSearch('');
                  setSearchPopoverOpen(false);
                }}
              />
            )}
          </div>
        </Popover.Content>
      </div>
    </Popover.Root>
  );
};

const UserSearch = ({ search = '', onClose }) => {
  const { currentUser } = useCurrentUser();
  const handle = search.trim().replace('@', '');
  const { data: userResults } = useSearchUsers({ handle: handle.length < 3 ? '' : handle });
  const { data: userSocials } = useGetUserSocials();
  const { mutate: toggleFollowUser, isLoading } = useToggleFollowUser();

  return (
    <div className={css.userResults}>
      {userResults?.map(user => (
        <NamedLink
          name="ProfilePage"
          params={{ id: user.id }}
          key={user.id}
          className={css.userResult}
          onClick={onClose}
        >
          <picture>
            <source srcSet={user.imageUrl} />
            <img src={noImagePlaceholder} alt={user.handle} />
          </picture>
          <div className={css.userResult__info}>
            <div>@{user.handle}</div>
            <div>{user.fullName}</div>
          </div>

          {user.id !== currentUser?.id?.uuid && (
            <Button
              disabled={isLoading}
              onClick={e => {
                e.preventDefault();
                toggleFollowUser(user.id);
              }}
            >
              {userSocials?.followingIds?.includes(user.id) ? 'Unfollow' : 'Follow'}
            </Button>
          )}
        </NamedLink>
      ))}
      {handle.length < 3 && <div>Type {3 - handle.length} more characters</div>}
      {userResults?.length === 0 && <div>No results</div>}
    </div>
  );
};

export default Topbar;
