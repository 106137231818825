/**
 * This component wraps React-Router's Redirect by providing name-based routing.
 * (Helps to narrow down the scope of possible format changes to routes.)
 */
import React from 'react';
import { Redirect } from 'react-router-dom';

import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { pathByRouteName } from '../../util/routes';
import { RouteName } from 'routing/routeConfiguration';

type P = {
  name: RouteName;
  search?: string;
  state?: any;
  push?: boolean;
  params?: any;
};

const NamedRedirect: React.FC<P> = props => {
  const routeConfiguration = useRouteConfiguration();
  const { name, search = '', state = {}, params = {}, push = false } = props;
  const pathname = pathByRouteName(name, routeConfiguration, params);
  return <Redirect to={{ pathname, search, state }} push={push} />;
};

export default NamedRedirect;
