import { useQuery } from '@tanstack/react-query';

import { get } from 'util/httpsClient';

export const useSearchUsers = (params: { handle?: string }) => {
  params.handle = params.handle?.replace('@', '');

  return useQuery({
    queryKey: ['userSearch', params],
    queryFn: async () => {
      if (!params.handle) {
        return [];
      }

      return get({ path: `/users?${new URLSearchParams(params).toString()}` });
    },
  });
};
