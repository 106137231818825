import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import css from './Tabs.module.css';
import { useMediaQueries } from 'hooks/useMediaQueries';

// TODO: Rename to Stepper
const Tabs = props => {
  const { children, className, rootClassName } = props;
  const classes = classNames(rootClassName, css.root, className);

  const tabNavTabs = React.Children.map(children, child => {
    const { tabId, tabLabel, tabLinkProps } = child.props;

    // Child components need to have TabNav props included
    if (!tabId || !tabLabel || !tabLinkProps) {
      throw new Error(
        `Tabs component: a child component is missing required props.
        tabId: (${tabId})
        tabLabel: (${tabLabel})
        tabLinkProps: (${tabLinkProps})`
      );
    }

    return {
      id: tabId,
      text: child.props.tabLabel,
      linkProps: child.props.tabLinkProps,
      disabled: child.props.disabled,
      selected: child.props.selected,
    };
  });

  const childArray = React.Children.toArray(children);
  // @ts-expect-error TS(2339) FIXME: Property 'props' does not exist on type 'ReactChil... Remove this comment to see the full error message
  const selectedTabPanel = childArray.find(c => c.props.selected);

  // One of the children needs to be selected
  if (!selectedTabPanel) {
    throw new Error(`Tabs component: one Child should have 'selected' prop.`);
  }

  const tabsContainerRef = useRef(null);

  const scrollToActiveTab = () => {
    if (tabsContainerRef.current && selectedTabPanel) {
      const container = tabsContainerRef.current;
      // @ts-expect-error TS(2339) FIXME: Property 'querySelector' does not exist on type 'n... Remove this comment to see the full error message
      const activeTab = container.querySelector(`.${css.active}`);
      if (activeTab) {
        // Calculate the scroll position to center the active tab
        // @ts-expect-error TS(2339) FIXME: Property 'clientWidth' does not exist on type 'nev... Remove this comment to see the full error message
        const containerWidth = container.clientWidth;
        const activeTabWidth = activeTab.clientWidth;
        const activeTabLeft = activeTab.offsetLeft;
        const scrollPosition = activeTabLeft + activeTabWidth / 2 - containerWidth / 2;

        // Smoothly scroll to the calculated position
        // @ts-expect-error TS(2339) FIXME: Property 'scrollTo' does not exist on type 'never'... Remove this comment to see the full error message
        container.scrollTo({
          left: scrollPosition,
        });
      }
    }
  };

  useEffect(() => {
    scrollToActiveTab();
  }, [selectedTabPanel]);

  const isLastSelected = tabNavTabs[tabNavTabs.length - 1].selected;
  const isDesktop = useMediaQueries({ viewport: 'small' });
  const currentTab = tabNavTabs.find(x => x.selected);

  return (
    <div className={classes}>
      {!isDesktop && !isLastSelected && (
        <div className={css.stepperMobileTitle}>{currentTab.text}</div>
      )}
      {!isLastSelected && (
        <div className={css.stepperItems} ref={tabsContainerRef}>
          {/* We do this to hide the preview and summary step */}
          {tabNavTabs.slice(0, -1).map((tabData, index) => {
            // Check if we are on the next step, to mark latest as completed.
            const isStepCompleted =
              !tabData.selected && tabNavTabs.indexOf(tabNavTabs.find(x => x.selected)) > index;

            return (
              <div
                key={tabData.id}
                className={classNames(css.stepperItem, {
                  [css.active]: tabData.selected,
                  [css.completed]: isStepCompleted,
                })}
              >
                <div className={css.stepCounter}>{index + 1}</div>
                {isDesktop && <div className={css.stepName}>{tabData.text}</div>}
              </div>
            );
          })}
        </div>
      )}
      {selectedTabPanel}
    </div>
  );
};

const { node, string } = PropTypes;

Tabs.defaultProps = {
  className: null,
  rootClassName: null,
  navRootClassName: null,
  tabRootClassName: null,
};

Tabs.propTypes = {
  children: node.isRequired,
  className: string,
  rootClassName: string,
  navRootClassName: string,
  tabRootClassName: string,
};

export default Tabs;
