import { useIntl } from 'react-intl';
import { types } from 'sharetribe-flex-sdk';

import { CheckoutCheckbox } from '../CheckoutCheckbox/CheckoutCheckbox';
import css from './CheckoutCheckboxInputs.module.css';
import { useGetUserNoldCredit } from 'hooks/api/users/useGetUserNoldCredit';
import { useGeolocation } from 'hooks/useGeolocation';
import { isEUCountry } from 'translations/countryCodes';
import { formatMoney } from 'util/currency';

const { Money } = types;

export const CheckboxInputs = ({ orderData, setOrderData }) => {
  const intl = useIntl();
  const { userGeolocation } = useGeolocation();
  const { data: userNoldCredit, isLoading: isNoldCreditLoading } = useGetUserNoldCredit();

  const noldCreditAmount = userNoldCredit?.amount || 0;
  const noldCreditCurrency = isEUCountry(userGeolocation) ? 'EUR' : 'GBP';
  const storeCredit = {
    amount: noldCreditAmount,
    currency: noldCreditCurrency,
  };
  const storeCreditMoney = storeCredit && new Money(storeCredit.amount, storeCredit.currency);
  const formattedStoreCredit = storeCreditMoney && formatMoney(intl, storeCreditMoney);

  return (
    <div className={css.checkboxInputsContainer}>
      <div className={css.checkboxInputsContainer__row}>
        <CheckoutCheckbox
          label="Use my NOLD credit"
          checked={orderData.useNoldCredit}
          onCheckedChange={() => {
            setOrderData(v => ({
              ...v,
              useNoldCredit: !v.useNoldCredit,
            }));
          }}
        />
        <div>{isNoldCreditLoading ? '...' : `${formattedStoreCredit} available`}</div>
      </div>
      {/* <div className={css.checkboxInputsContainer__row}>
        <CheckoutCheckbox
          label="Authentication"
          checked={orderData.authenticate}
          onCheckedChange={() => {
            setOrderData(v => ({
              ...v,
              authenticate: !v.authenticate,
            }));
          }}
        />
        <div>£15</div>
      </div> */}
    </div>
  );
};
