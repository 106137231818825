import { useMutation } from '@tanstack/react-query';

import { EventData } from 'util/events';
import { post } from 'util/httpsClient';

export const useSendEvent = () => {
  return useMutation({
    mutationFn: (data: EventData) => {
      return post({
        path: `/events`,
        body: {
          data,
        },
      });
    },
    meta: { name: 'sendEvent' },
  });
};
