import React from 'react';
import { toast } from 'react-hot-toast';

import css from './ToastContent.module.css';
import { IconClose } from 'assets/icons';

type ToastContentProps = {
  messages: string[];
  toastId: React.ReactText;
};

const ToastContent: React.FC<ToastContentProps> = ({ messages, toastId }) => {
  return (
    <div className={css.toastContent}>
      <div className={css.toastBody}>
        {messages.map((msg, index) => (
          <p key={index} className={css.toastMessage}>
            {msg}
          </p>
        ))}
        <button onClick={() => toast.dismiss(toastId.toString())} className={css.toastCloseButton}>
          <IconClose />
        </button>
      </div>
    </div>
  );
};

export default ToastContent;
