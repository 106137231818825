import classNames from 'classnames';

import { useProfileTabs } from '../ProfilePage.hooks';
import css from './ProfileTabNav.module.css';

// TODO: Make accessible and SEO friendly

const ProfileTabNav = () => {
  const { profileTabs } = useProfileTabs();

  return (
    <div className={classNames(css.root)}>
      <div className={css.tabsContainer}>
        {profileTabs.map(tab => {
          return (
            <div
              className={css.tab}
              key={tab.value}
              onClick={tab.onClick}
              data-active={tab.selected}
            >
              {tab.text}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProfileTabNav;
