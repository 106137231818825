import { useCallback, useMemo } from 'react';
import { toast } from 'react-hot-toast';
import { useHistory, useLocation } from 'react-router-dom';
import { z } from 'zod';

import { parse, stringify } from 'util/urlHelpers';

export function parseSearchQueryParams<T extends {}>(
  search: string,
  defaultParams: T,
  zodSchema: z.ZodType<T>
) {
  const urlQueryParams = parse(search);

  const params = { ...defaultParams, ...urlQueryParams };

  if (zodSchema) {
    const res = zodSchema.safeParse(params);

    if (!res.success) {
      // TODO: Log error with sentry
      console.error(res.error);
      toast.error('Invalid search params');
      return params;
    }

    return res.data;
  }

  return params;
}

export function useQueryParams<T extends {}>(defaultParams: T, zodSchema?: z.ZodType<T>) {
  const history = useHistory();
  const location = useLocation();

  const paramsWithDefaultValues = useMemo(() => {
    const urlQueryParams = parse(location.search);

    const params = { ...defaultParams, ...urlQueryParams };
    if (zodSchema) {
      const res = zodSchema.safeParse(params);

      if (!res.success) {
        // TODO: Log error with sentry
        console.error(res.error);
        toast.error('Invalid search params');
        return params;
      }

      return res.data;
    }

    return params;
  }, [defaultParams, location.search, zodSchema]);

  const generateNewParams = useCallback(
    (params: Partial<T>, clearOther = false) => {
      const newQueryParams = { ...paramsWithDefaultValues, ...params };
      return stringify(clearOther ? params : newQueryParams);
    },
    [paramsWithDefaultValues]
  );

  const setQueryParams = useCallback(
    (params: Partial<T>, options: { clearOther?: boolean } = {}) => {
      const newSearch = generateNewParams(params, options.clearOther);

      if (newSearch !== location.search) {
        history.push({ search: newSearch });
      }
    },
    [generateNewParams, history, location.search]
  );

  return [paramsWithDefaultValues, setQueryParams, generateNewParams] as const;
}
