import React from 'react';

import { Heading, InlineTextButton, LayoutSingleColumn } from '../../components';
import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { FormattedMessage } from '../../util/reactIntl';
import { pathByRouteName } from '../../util/routes';
import css from './LoadableComponentErrorBoundary.module.css';
import { LogoNold } from 'assets/icons';

export const LoadableComponentErrorBoundaryPage = () => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const landingPagePath = pathByRouteName('LandingPage', routeConfiguration);
  const handleOnClick = () => {
    if (typeof window !== 'undefined') {
      // @ts-expect-error TS(2322) FIXME: Type 'string' is not assignable to type '(string |... Remove this comment to see the full error message
      window.location = landingPagePath;
    }
  };

  const landingPageLink = (
    <InlineTextButton onClick={handleOnClick}>
      <FormattedMessage id="LoadableComponentErrorBoundaryPage.landingPageLink" />
    </InlineTextButton>
  );

  return (
    <div>
      <LayoutSingleColumn
        topbar={
          <div className={css.topbar}>
            <InlineTextButton onClick={handleOnClick}>
              <LogoNold className={css.logoMobile} title={config.marketplaceName} />
              <LogoNold className={css.logoDesktop} title={config.marketplaceName} />
            </InlineTextButton>
          </div>
        }
        footer={null}
      >
        <div className={css.root}>
          <div className={css.content}>
            <div className={css.number}>404</div>
            <Heading as="h1" rootClassName={css.heading}>
              <FormattedMessage id="LoadableComponentErrorBoundaryPage.heading" />
            </Heading>
            <p className={css.description}>
              <FormattedMessage
                id="LoadableComponentErrorBoundaryPage.description"
                values={{ link: landingPageLink }}
              />
            </p>
          </div>
        </div>
      </LayoutSingleColumn>
    </div>
  );
};
