import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import css from './ListingManagementButtons.module.css';
import { Button, Modal, NamedLink, PrimaryButton } from 'components';
import { useHandleListingAction } from 'hooks/api/listings/useHandleListingAction';
import { createListingSlug } from 'util/createListingSlug';
import { LISTING_PAGE_PARAM_TYPE_EDIT } from 'util/urlHelpers';

interface ListingManagementButtonsProps {
  isActive: boolean;
  isDraft: boolean;
  isClosed: boolean;
  isManuallySold: boolean;
  listing: any;
}

export const ListingManagementButtons: React.FC<ListingManagementButtonsProps> = ({
  isActive,
  isDraft,
  listing,
  isClosed,
  isManuallySold,
}) => {
  const intl = useIntl();

  const listingId = listing.id.uuid;

  const {
    handleCloseListing,
    handleDeleteListing,
    handleMarkAsSold,
    handleActivateListing,
    isCloseLoading,
    isDeleteLoading,
    isMarkAsSoldLoading,
    isActivateLoading,
    isRestockLoading,
    isPendingTransactionsModalOpen,
    setIsPendingTransactionsModalOpen,
  } = useHandleListingAction();

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const isActivateInProgress = isManuallySold ? isRestockLoading : isActivateLoading;

  if (!isActive && !isClosed && !isDraft && !isManuallySold) {
    return null;
  }

  return (
    <div className={css.buttonWrapper} onClick={e => e.stopPropagation()}>
      {isActive && (
        <PrimaryButton
          className={css.buttonItem}
          inProgress={isCloseLoading}
          disabled={isCloseLoading}
          onClick={async e => {
            await handleCloseListing(listingId);
          }}
        >
          Close
        </PrimaryButton>
      )}

      {(isClosed || isManuallySold) && (
        <PrimaryButton
          className={css.buttonItem}
          inProgress={isActivateInProgress}
          disabled={isActivateInProgress}
          onClick={async e => {
            await handleActivateListing(listingId, isManuallySold);
          }}
        >
          Activate
        </PrimaryButton>
      )}

      <button
        className={css.buttonItem}
        onClick={e => {
          setIsDeleteModalOpen(true);
        }}
      >
        Delete
      </button>

      <Modal
        open={isDeleteModalOpen}
        onOpenChange={setIsDeleteModalOpen}
        title={intl.formatMessage({ id: 'ListingManagementButtons.deleteListing' })}
      >
        <p>{intl.formatMessage({ id: 'ListingManagementButtons.confirmDelete' })}</p>
        <div className={css.modalButtons}>
          <PrimaryButton
            className={css.modalButton}
            onClick={async e => {
              await handleDeleteListing(listingId).then(() => setIsDeleteModalOpen(false));
            }}
            disabled={isDeleteLoading}
            inProgress={isDeleteLoading}
          >
            {intl.formatMessage({ id: 'ListingManagementButtons.delete' })}
          </PrimaryButton>
          <Button className={css.modalButton} onClick={() => setIsDeleteModalOpen(false)}>
            Cancel
          </Button>
        </div>
      </Modal>

      <Modal
        open={isPendingTransactionsModalOpen}
        onOpenChange={setIsPendingTransactionsModalOpen}
        title={intl.formatMessage({ id: 'ListingManagementButtons.pendingTransactionsModal' })}
        showCloseButton={false}
      >
        <p>{intl.formatMessage({ id: 'ListingManagementButtons.pendingTransactions' })}</p>
        <div className={css.pendingModalButtons}>
          <PrimaryButton
            className={css.modalButton}
            onClick={() => setIsPendingTransactionsModalOpen(false)}
          >
            OK
          </PrimaryButton>
        </div>
      </Modal>

      {(isActive || isClosed) && (
        <PrimaryButton
          className={css.buttonItem}
          inProgress={isMarkAsSoldLoading}
          disabled={isMarkAsSoldLoading}
          onClick={async e => {
            await handleMarkAsSold(listingId);
          }}
        >
          Sold
        </PrimaryButton>
      )}

      <button className={css.buttonItem}>
        <NamedLink
          name="EditListingPage"
          params={{
            id: listingId,
            slug: createListingSlug(listing),
            tab: 'photos',
            type: LISTING_PAGE_PARAM_TYPE_EDIT,
          }}
        >
          Edit
        </NamedLink>
      </button>
    </div>
  );
};
