import { DiscountCodeState } from '../CheckoutPage';
import css from './CheckoutDiscountCodeInput.module.css';
import { PrimaryButton } from 'components';
import { TextInput } from 'components/FieldTextInputV2/FieldTextInput';

type P = {
  onApply: (code: string) => void;
  onRemove: (code: string) => void;
  discountCodeState: DiscountCodeState;
  setDiscountCodeState: React.Dispatch<React.SetStateAction<DiscountCodeState>>;
};

export const DiscountCodeInput: React.FC<P> = ({
  onApply,
  onRemove,
  discountCodeState,
  setDiscountCodeState,
}) => {
  const { input, isApplied, isError } = discountCodeState;

  const handleApply = async e => {
    e.preventDefault();

    if (input) {
      await onApply(input);
    }
  };

  const handleClear = () => {
    onRemove(input);
  };

  const disableTextField = isApplied && !isError;

  return (
    <form className={css.discountCodeInput} onSubmit={handleApply}>
      <TextInput
        placeholder="Discount code"
        value={input}
        onChange={e => setDiscountCodeState({ ...discountCodeState, input: e.target.value })}
        disabled={disableTextField}
      />
      {disableTextField ? (
        <PrimaryButton onClick={handleClear} type="button">
          Clear
        </PrimaryButton>
      ) : (
        <PrimaryButton type="submit">Apply</PrimaryButton>
      )}
    </form>
  );
};
