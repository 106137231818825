import { ProductDefinitionOption } from './productTypeDefinitions';
import { toKebapCase } from './toKebapCase';

export const intWomenClothingSizeOptions: Array<ProductDefinitionOption> = [
  'XXS',
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    category === 'clothing' && department === 'women' && sizeStandard === 'int',
}));

export const euWomenClothingSizeOptions: Array<ProductDefinitionOption> = [
  '34',
  '36',
  '38',
  '40',
  '42',
  '44',
  '46',
  '48',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    category === 'clothing' && department === 'women' && sizeStandard === 'eu',
}));

export const ukWomenClothingSizeOptions: Array<ProductDefinitionOption> = [
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    category === 'clothing' && department === 'women' && sizeStandard === 'uk',
}));

export const usWomenClothingSizeOptions: Array<ProductDefinitionOption> = [
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    category === 'clothing' && department === 'women' && sizeStandard === 'us',
}));

export const itWomenClothingSizeOptions: Array<ProductDefinitionOption> = [
  '38',
  '40',
  '42',
  '44',
  '46',
  '48',
  '50',
  '52',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    category === 'clothing' && department === 'women' && sizeStandard === 'it',
}));

export const euWomenShoeSizeOptions: Array<ProductDefinitionOption> = [
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    category === 'shoes' && department === 'women' && sizeStandard === 'eu',
}));

export const ukWomenShoeSizeOptions: Array<ProductDefinitionOption> = [
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    category === 'shoes' && department === 'women' && sizeStandard === 'uk',
}));

export const usWomenShoeSizeOptions: Array<ProductDefinitionOption> = [
  '5',
  '6',
  '6.5',
  '7.5',
  '8',
  '9',
  '10',
  '11',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    category === 'shoes' && department === 'women' && sizeStandard === 'us',
}));

export const itWomenShoeSizeOptions: Array<ProductDefinitionOption> = [
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    category === 'shoes' && department === 'women' && sizeStandard === 'it',
}));

export const womenBeltSizeOptions: Array<ProductDefinitionOption> = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'One size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, productType }) => productType === 'belts' && department === 'women',
}));

export const womenGloveSizeOptions: Array<ProductDefinitionOption> = [
  'XS',
  'S',
  'M',
  'L',
  'One Size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, productType }) =>
    productType === 'gloves' && department === 'women',
}));

export const womenHatSizeOptions: Array<ProductDefinitionOption> = ['S', 'M', 'L', 'One size'].map(
  size => ({
    value: toKebapCase(size),
    label: size,
    showPredicate: ({ department, productType }) =>
      productType === 'hats' && department === 'women',
  })
);

export const womenRingSizeOptions: Array<ProductDefinitionOption> = [
  'XS',
  'S',
  'M',
  'L',
  'One Size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, productType }) => productType === 'rings' && department === 'women',
}));
