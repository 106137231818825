import classNames from 'classnames';
import { func, node, string } from 'prop-types';
import React, { Component } from 'react';

import css from './OutsideClickHandler.module.css';

export default class OutsideClickHandler extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false);
  }

  handleClick(event) {
    // @ts-expect-error TS(2339) FIXME: Property 'node' does not exist on type 'OutsideCli... Remove this comment to see the full error message
    if (!this.node.contains(event.target)) {
      // @ts-expect-error TS(2339) FIXME: Property 'onOutsideClick' does not exist on type '... Remove this comment to see the full error message
      this.props.onOutsideClick();
    }
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'rootClassName' does not exist on type 'R... Remove this comment to see the full error message
    const { rootClassName, className, children } = this.props;
    const classes = classNames(rootClassName || css.root, className);

    return (
      // @ts-expect-error TS(2339) FIXME: Property 'node' does not exist on type 'OutsideCli... Remove this comment to see the full error message
      <div className={classes} ref={node => (this.node = node)}>
        {children}
      </div>
    );
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
OutsideClickHandler.defaultProps = {
  rootClassName: null,
  className: null,
};

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
OutsideClickHandler.propTypes = {
  rootClassName: string,
  className: string,
  onOutsideClick: func.isRequired,
  children: node.isRequired,
};
