import { useMutation } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';

import { Modal } from '../../components';
import { DateOfBirthForm } from 'components/OrderPanel/DateOfBirthForm';
import { fetchCurrentUser } from 'ducks/user.duck';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { post } from 'util/httpsClient';
import * as log from 'util/log';
import { isGuestUser } from 'util/user';

export const DOBModal = () => {
  const { currentUser } = useCurrentUser();
  const { userGeolocation } = useGeolocation();
  const isLoadingUser = !currentUser?.id;
  const hasStripeAccount = currentUser?.attributes?.profile?.privateData?.stripeId;
  const dateOfBirth = currentUser?.attributes.profile.privateData?.dateOfBirth;
  const isGuest = isGuestUser(currentUser);

  const dispatch = useDispatch();
  const { mutateAsync: createStripeAccount } = useMutation({
    mutationFn: async () => {
      return post({ path: '/users/stripe', body: { userGeolocation } });
    },
    onSettled: () => {
      dispatch(fetchCurrentUser());
    },
  });

  const handleDobModalSubmit = async () => {
    try {
      if (!hasStripeAccount) {
        await createStripeAccount();
      }
    } catch (error) {
      toast.error('Failed to set up your stripe account. Please contact our support team.');
      log.error(error, (error as any).code, undefined);
    }
  };

  return (
    <Modal
      open={!isLoadingUser && !dateOfBirth && !isGuest}
      title="Please enter your date of birth"
      showCloseButton={false}
    >
      In order to be able to process payments we need to know your date of birth.
      <br />
      <br />
      <DateOfBirthForm onDateOfBirthUpdated={() => handleDobModalSubmit()} />
    </Modal>
  );
};
