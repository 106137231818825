import classNames from 'classnames';
import React from 'react';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';

import css from './ProfileHeader.module.css';
import { IconLink, IconShare } from 'assets/icons';
import { Avatar } from 'components';
import { useMediaQueries } from 'hooks/useMediaQueries';

const ProfileHeader: React.FC<{
  ownProfile: boolean;
  profileUser: any;
  className?: string;
}> = ({ ownProfile, profileUser, className }) => {
  const {
    bio,
    displayName,
    publicData: { handle = '' } = {},
    metadata: { salesCount = 0, averageRating = null, trustedSeller = false } = {},
  } = profileUser?.attributes?.profile || {};

  const isDesktop = useMediaQueries({ viewport: 'medium' });

  return (
    <div className={classNames(css.avatarLayout, className)}>
      {isDesktop && <Avatar className={css.avatar} user={profileUser} />}
      <div className={css.profileHeading}>
        <div className={css.mainInfo}>
          {!isDesktop && <Avatar className={css.avatar} user={profileUser} />}
          <div className={css.infoWrapper}>
            <h1 className={css.name}>{displayName}</h1>
            <ShareButton />
            <CopyLinkButton />
          </div>
          {handle && <h2 className={css.handle}>{handle}</h2>}
          <div className={css.profileRatingWrapper}>
            {trustedSeller && 'Trusted Seller | '}
            {salesCount ? `${salesCount} Sales` : 'No sales yet'} |{' '}
            {averageRating ? `${averageRating} Rating` : 'No ratings yet'}
          </div>
        </div>
        <div className={css.secondaryInfo}>{bio && <div className={css.bio}>{bio}</div>}</div>
      </div>
    </div>
  );
};

const ShareButton = () => {
  const inlt = useIntl();
  const handleShare = () => {
    if (typeof window === 'undefined') return;
    navigator
      ?.share({
        title: inlt.formatMessage({ id: 'ProfilePage.shareProfileTitle' }),
        text: inlt.formatMessage({ id: 'ProfilePage.shareProfileText' }),
        url: window.location.href,
      })
      .then(() => toast(inlt.formatMessage({ id: 'ProfilePage.shareProfileSuccess' })))
      .catch(error => {
        console.log('Sharing failed', error);
      });
  };

  if (typeof window !== 'undefined' && !navigator.share) {
    return null;
  }

  return (
    <button
      className={css.shareButton}
      onClick={handleShare}
      aria-label={inlt.formatMessage({ id: 'ProfilePage.shareProfileLabel' })}
    >
      <IconShare />
    </button>
  );
};

const CopyLinkButton = () => {
  const handleCopy = () => {
    if (typeof window === 'undefined') return;

    navigator.clipboard.writeText(window.location.href);
    toast('Link copied to clipboard');
  };

  return (
    <button className={css.shareButton} onClick={handleCopy} aria-label="Copy link to profile">
      <IconLink id="ProfilePage.copyLink" />
    </button>
  );
};

export default ProfileHeader;
