import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import { z } from 'zod';

import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useQueryParams } from 'hooks/useQueryParams';
import { APIError, get, post, put } from 'util/httpsClient';

export const useGetEditablePages = () => {
  return useQuery({
    queryKey: ['editablePages'],
    queryFn: () => get({ path: '/editable-pages' }),
    meta: {
      errorMessage: 'Failed to fetch editable pages',
    },
  });
};

export const useGetEditablePage = (pageId: string, version?: string | number) => {
  return useQuery({
    queryKey: getEditablePageKey(pageId, version),
    queryFn: getEditablePageQueryFn(pageId, version),
    meta: {
      errorMessage: 'Failed to fetch editable page',
    },
    retry: (failureCount, error) => {
      if (error instanceof APIError && error?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    enabled: !!pageId,
  });
};

export const getEditablePageKey = (pageId: string, version?: string | number) => {
  return ['editablePage', pageId, version] as const;
};

export const getEditablePageQueryFn = (pageId: string, version?: string | number) => {
  const searchParams = version ? `?version=${version}` : '';

  return () => get({ path: `/editable-pages/${pageId}${searchParams}` });
};

export const useGetEditablePageVersions = (pageId: string) => {
  return useQuery({
    queryKey: ['editablePageVersions', pageId],
    queryFn: () => get({ path: `/editable-pages/${pageId}/versions` }),
    meta: {
      errorMessage: 'Failed to fetch editable page versions',
    },
    retry: (failureCount, error) => {
      if (error instanceof APIError && error?.status === 404) {
        return false;
      }
      return failureCount < 3;
    },
    enabled: !!pageId,
  });
};

export const useUpdateEditablePages = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newConfig: { id: string; name: string; content: string }) => {
      return put({ path: `/editable-pages/${newConfig.id}`, body: newConfig });
    },
    meta: {
      name: 'updateEditablePages',
    },
    onSuccess: () => {
      return toast.promise(
        new Promise<void>(resolve =>
          setTimeout(() => {
            queryClient.removeQueries({ queryKey: ['editablePages'] });
            resolve();
          }, 3000)
        ),
        {
          success: 'Updated page configuration',
          loading: 'Updating page configuration',
          error: 'Failed to update page configuration',
        }
      );
    },
    onError: () => {
      toast.error('Failed to update page configuration');
    },
  });
};
export const useIsNoldUser = () => {
  const { currentUser } = useCurrentUser();
  const email = currentUser?.attributes?.email;
  const isNoldEmail = email?.endsWith('@thenold.com') || email?.endsWith('@nold.app');

  return isNoldEmail;
};

export const useCreateEditablePage = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (newPage: { path: string; name: string }) => {
      return post({ path: `/editable-pages`, body: newPage });
    },
    meta: {
      name: 'createEditablePage',
    },
    onSuccess: () => {
      return toast.promise(
        new Promise<void>(resolve =>
          setTimeout(() => {
            queryClient.invalidateQueries({ queryKey: ['editablePages'] });
            resolve();
          }, 3000)
        ),
        {
          success: 'Created new editable page',
          loading: 'Creating new editable page',
          error: 'Failed to create new editable page',
        }
      );
    },
    onError: () => {
      toast.error('Failed to create new editable page');
    },
  });
};

export const useEditablePagesQueryParams = () => {
  return useQueryParams(
    {
      edit: false,
      version: undefined,
    },
    z.object({
      edit: z.coerce.boolean(),
      version: z.number().optional(),
    })
  );
};
