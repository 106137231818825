import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

const MetaTagGenerator: React.FC<{ metaConfig: any }> = params => {
  const location = useLocation();
  const currentPath = location.pathname;

  let seoTitle = 'Sell or buy pre-loved clothes | THE NOLD';
  let seoMetaDescription = 'The largest online community for pre-loved clothing enthusiasts.';

  let seoCollectionText = '';
  let seoBody = '';

  if (params.metaConfig && Array.isArray(params.metaConfig)) {
    const SEOMetadata = params.metaConfig.find(meta => meta.url === currentPath);
    if (SEOMetadata) {
      seoTitle = SEOMetadata.seoTitleText;
      seoCollectionText = SEOMetadata.seoCollectionText;
      seoBody = SEOMetadata.seoBodyText;
      seoMetaDescription = SEOMetadata.seoMetaDescription;
    }
  }

  return (
    <Helmet>
      <title>{seoTitle}</title>
      <meta name="og:title" content={seoTitle} />
      <meta name="twitter:title" content={seoTitle} />
      <meta name="description" content={seoMetaDescription} />
      <meta name="og:description" content={seoMetaDescription} />
      <meta name="twitter:description" content={seoMetaDescription} />
    </Helmet>
  );
};

export default MetaTagGenerator;
