import { KIDS_DEPARTMENTS, ProductDefinitionOption } from './productTypeDefinitions';
import { toKebapCase } from './toKebapCase';

export const kidsClothingSizeOptions: Array<ProductDefinitionOption> = [
  '0-1M',
  '1-3M',
  '3-6M',
  '6-9M',
  '9-12M',
  '12-18M',
  '18-24M',
  '2-3Y',
  '3-4Y',
  '4-5Y',
  '6Y',
  '7Y',
  '8Y',
  '9Y',
  '10Y',
  '11-12Y',
  '13-14Y',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category }) =>
    KIDS_DEPARTMENTS.includes(department) && category === 'clothing',
}));

export const kidsShoesSizeOptions: Array<ProductDefinitionOption> = [
  '18',
  '19',
  '20',
  '21',
  '22',
  '23',
  '24',
  '25',
  '26',
  '27',
  '28',
  '29',
  '30',
  '31',
  '32',
  '33',
  '34',
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category }) =>
    KIDS_DEPARTMENTS.includes(department) && category === 'shoes',
}));

export const kidsBeltSizeOptions: Array<ProductDefinitionOption> = [
  '12-18M',
  '18-24M',
  '2-3Y',
  '3-4Y',
  '4-5Y',
  '6Y',
  '7Y',
  '8Y',
  '9Y',
  '10Y',
  '11-12Y',
  '13-14Y',
  'One Size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, productType }) =>
    KIDS_DEPARTMENTS.includes(department) && productType === 'belts',
}));

export const kidsGlovesSizeOptions: Array<ProductDefinitionOption> = [
  '0-1M',
  '1-3M',
  '3-6M',
  '6-9M',
  '9-12M',
  '12-18M',
  '18-24M',
  '2-3Y',
  '3-4Y',
  '4-5Y',
  '6Y',
  '7Y',
  '8Y',
  '9Y',
  '10Y',
  '11-12Y',
  '13-14Y',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, productType }) =>
    KIDS_DEPARTMENTS.includes(department) && productType === 'gloves',
}));

export const kidsHatSizeOptions: Array<ProductDefinitionOption> = [
  '0-1M',
  '1-3M',
  '3-6M',
  '6-9M',
  '9-12M',
  '12-18M',
  '18-24M',
  '2-3Y',
  '3-4Y',
  '4-5Y',
  '6Y',
  '7Y',
  '8Y',
  '9Y',
  '10Y',
  '11-12Y',
  '13-14Y',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, productType }) =>
    KIDS_DEPARTMENTS.includes(department) && productType === 'hats',
}));
