import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';

import { LOQATE_API_KEY } from 'config/env';

export const useGetAddressSuggestionDetails = () => {
  const queryClient = useQueryClient();

  return useCallback(
    async (id: string) =>
      queryClient.fetchQuery({
        queryKey: ['addressDetails', id],
        queryFn: async () => {
          const response = await fetch(
            'https://api.addressy.com/Capture/Interactive/Retrieve/v1/json3.ws?' +
              new URLSearchParams({
                Key: LOQATE_API_KEY,
                Id: id,
              })
          );

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          return data as any;
        },
        cacheTime: 1000 * 60 * 60 * 24,
        staleTime: 1000 * 60 * 60 * 24, // 1 hour
      }),
    [queryClient]
  );
};
