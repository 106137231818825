import { useMutation } from '@tanstack/react-query';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';

import { authInfo } from '../../../ducks/auth.duck';
import { fetchCurrentUser } from '../../../ducks/user.duck';
import { del, get, post } from '../../../util/httpsClient';
import * as log from '../../../util/log';
import { useSubscribeToNewsletter } from 'containers/PageBuilder/SectionBuilder/SectionFooter/SectionFooter.hooks';
import { useLogout } from 'hooks/api/auth';
import { useGeolocation } from 'hooks/useGeolocation';
import { HandleExistsError } from 'util/customError';
import { formatDate } from 'util/dates';
import { useSdk } from 'util/sdkContext';

export const useSignup = () => {
  const sdk = useSdk();
  const dispatch = useDispatch();
  const { mutateAsync: logout } = useLogout();
  const { mutateAsync: subscribeToNewsletter } = useSubscribeToNewsletter();
  const { userGeolocation } = useGeolocation();

  return useMutation({
    mutationFn: async (params: any) => {
      const {
        email,
        password,
        firstName,
        lastName,
        handle,
        dateOfBirth,
        marketingConsent,
        ...rest
      } = params;
      const modifiedHandle = handle?.replace('@', '');

      const createUserParams = {
        email,
        password,
        firstName,
        lastName,
        publicData: { handle: modifiedHandle },
        privateData: { dateOfBirth: formatDate(dateOfBirth) },
        ...(isEmpty(rest) ? {} : { protectedData: { ...rest } }),
      };

      try {
        const existsRes = await get({
          path: `/users/exists?handle=${encodeURIComponent(modifiedHandle)}`,
        });
        if (existsRes.userExists) {
          throw new HandleExistsError('User with this username already exists');
        }

        await sdk.currentUser.create(createUserParams);
        await sdk.login({ username: email, password }).then(response => {
          if (response.status === 200) {
            const tokenData = {
              privateData: {
                accessToken: response?.data?.access_token,
                refreshToken: response?.data?.refresh_token,
              },
            };
            sdk.currentUser.updateProfile(tokenData);
          }
        });
      } catch (error) {
        log.error(error, 'create-user-failed', { createUserParams });
        throw error;
      }

      try {
        const urlParams = new URLSearchParams(window.location.search);
        const referralCode = urlParams.get('referralCode');
        await post({
          path: '/users',
          body: { firstName, lastName, handle: modifiedHandle, referralCode, userGeolocation },
        });
      } catch (error: any) {
        log.error(error, 'signup-failed', { email, firstName, lastName });
        await del({ path: '/users', body: { password } });
        await logout();
        throw error;
      }

      if (marketingConsent) {
        await subscribeToNewsletter({ email, firstName, lastName }).catch(() => {});
      }
    },
    onSuccess: async () => {
      await dispatch(authInfo());
      await dispatch(fetchCurrentUser());
    },
    meta: { name: 'signup' },
  });
};
