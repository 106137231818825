import { useState } from 'react';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';

import {
  useActivateListing,
  useCloseListing,
  useDeleteListing,
  useMarkListingAsSold,
  useRestockListing,
} from './useManageListings';

export const useHandleListingAction = () => {
  const intl = useIntl();

  const [isPendingTransactionsModalOpen, setIsPendingTransactionsModalOpen] = useState(false);

  const handleAction = async (
    action: () => Promise<void>,
    successMessage: string,
    errorMessage: string
  ) => {
    try {
      await action();
      toast.success(successMessage);
    } catch (error: any) {
      if (error.data?.errors?.[0]?.message === 'PENDING_TRANSACTIONS') {
        setIsPendingTransactionsModalOpen(true);
        return;
      }
      const errorMessageToast = error.data?.errors?.[0]?.displayMessage || errorMessage;
      toast.error(errorMessageToast);
    }
  };

  const { mutateAsync: closeListing, isLoading: isCloseLoading } = useCloseListing();
  const { mutateAsync: deleteListing, isLoading: isDeleteLoading } = useDeleteListing();
  const { mutateAsync: markAsSold, isLoading: isMarkAsSoldLoading } = useMarkListingAsSold();
  const { mutateAsync: activateListing, isLoading: isActivateLoading } = useActivateListing();
  const { mutateAsync: restockListing, isLoading: isRestockLoading } = useRestockListing();

  const handleCloseListing = async (listingId: string) => {
    await handleAction(
      async () => closeListing(listingId),
      intl.formatMessage({ id: 'ListingManagementButtons.successClose' }),
      intl.formatMessage({ id: 'ListingManagementButtons.errorClose' })
    );
  };

  const handleDeleteListing = async (listingId: string) => {
    await handleAction(
      async () => deleteListing(listingId),
      intl.formatMessage({ id: 'ListingManagementButtons.successDelete' }),
      intl.formatMessage({ id: 'ListingManagementButtons.errorDelete' })
    );
  };

  const handleMarkAsSold = async (listingId: string) => {
    await handleAction(
      async () => markAsSold(listingId),
      intl.formatMessage({ id: 'ListingManagementButtons.successMarkAsSold' }),
      intl.formatMessage({ id: 'ListingManagementButtons.errorMarkAsSold' })
    );
  };

  const handleActivateListing = async (listingId: string, isManuallySold: boolean) => {
    await handleAction(
      async () => (isManuallySold ? restockListing(listingId) : activateListing(listingId)),
      intl.formatMessage({ id: 'ListingManagementButtons.successActivate' }),
      intl.formatMessage({ id: 'ListingManagementButtons.errorActivate' })
    );
  };

  return {
    handleCloseListing,
    handleDeleteListing,
    handleMarkAsSold,
    handleActivateListing,
    isCloseLoading,
    isDeleteLoading,
    isMarkAsSoldLoading,
    isActivateLoading,
    isRestockLoading,
    isPendingTransactionsModalOpen,
    setIsPendingTransactionsModalOpen,
  };
};
