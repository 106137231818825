import { useIntl } from 'react-intl';

import { formatMoney } from '../../util/currency';
import { ensureUser } from '../../util/data';
import AspectRatioWrapper from '../AspectRatioWrapper/AspectRatioWrapper';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
import css from './OrderSummary.module.css';

const { types } = require('sharetribe-flex-sdk');
const { Money } = types;

const OrderSummary = props => {
  const { currentListing } = props;
  const intl = useIntl();

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const { aspectWidth = 3, aspectHeight = 4, variantPrefix = 'scaled-medium' } = {};
  const variants = firstImage
    ? Object.keys(firstImage?.attributes?.variants).filter(k => k.startsWith(variantPrefix))
    : [];

  const listingTitle = currentListing.attributes.title;
  const currentAuthor = ensureUser(currentListing.author);

  const priceFormatted = formatMoney(intl, currentListing.attributes.price);

  const priceWhenBought = currentListing.attributes.publicData.priceWhenBought;
  const priceWhenBoughtFormatted = priceWhenBought
    ? formatMoney(intl, new Money(priceWhenBought.amount, priceWhenBought.currency))
    : null;

  const soldBy = intl.formatMessage(
    { id: 'OrderSummary.soldBy' },
    { value: currentAuthor.attributes.profile.displayName }
  );

  return (
    <div className={css.detailsContainerDesktop}>
      <AspectRatioWrapper
        width={aspectWidth}
        height={aspectHeight}
        className={css.detailsAspectWrapper}
      >
        <ResponsiveImage
          rootClassName={css.rootForImage}
          alt={listingTitle}
          image={firstImage}
          variants={variants}
        />
      </AspectRatioWrapper>

      <div className={css.listingContainer}>
        <p className={css.listingTitle}>{listingTitle}</p>

        <div style={{ display: 'inline-flex', gap: 8 }}>
          <p className={css.listingPrice}>{priceFormatted}</p>
          <p className={css.listingPriceWhenBought}>{priceWhenBoughtFormatted}</p>
        </div>

        <p className={css.listingSoldBy}>{soldBy}</p>
      </div>
    </div>
  );
};

export default OrderSummary;
