import { Modal, NamedLink, PrimaryButton } from '../../components'; // Added NamedLink import

const GuestEmailExistsModal = ({ isOpen, setIsOpen }) => {
  return (
    <Modal open={isOpen} title="Email Already Exists" showCloseButton onOpenChange={setIsOpen}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <p>A registered user with this email already exists. Please </p>
        <PrimaryButton>
          <NamedLink name="LoginPage">Log in</NamedLink>
        </PrimaryButton>
        <p> or </p>
        <p>choose a different email.</p>
      </div>
    </Modal>
  );
};

export default GuestEmailExistsModal;
