const slice = value => {
  if (!value) {
    return '';
  }
  const numbers = parse(value);
  const parts = [numbers.substring(0, 2), numbers.substring(2, 4), numbers.substring(4, 8)].filter(
    part => part !== ''
  );

  return parts.join('/');
};

export const format = value => {
  if (!value) {
    return '';
  }
  return slice(value);
};

export const parse = value => (value ? value.replace(/\//g, '').replace(/\D/g, '') : '');
