import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

import css from './ReviewModal.module.css';
import { Button, Modal, PrimaryButton } from 'components';
import { TextInput } from 'components/FieldTextInputV2/FieldTextInput';
import StarRating from 'containers/ProfilePage/StarRating/StarRating';
import { useSubmitReview } from 'hooks/api/reviews';

type ReviewModalProps = {
  open: boolean;
  onClose: () => void;
  initialRating: number;
  transactionId: string;
};

const ReviewModal: React.FC<ReviewModalProps> = ({
  open,
  onClose,
  initialRating,
  transactionId,
}) => {
  const [rating, setRating] = useState(initialRating);
  const [text, setText] = useState('');
  useEffect(() => {
    setRating(initialRating);
  }, [initialRating]);

  const { mutateAsync: submitReview, isLoading } = useSubmitReview();

  return (
    <Modal title="Review" open={open} onOpenChange={onClose} contentWrapperClassName={css.content}>
      <StarRating rating={rating} onClick={setRating} />
      <TextInput
        label="Write a review"
        value={text}
        onChange={e => setText(e.target.value)}
        textarea
      />
      <div className={css.buttonsContainer}>
        <Button onClick={onClose}>Cancel</Button>
        <PrimaryButton
          inProgress={isLoading}
          onClick={async () => {
            await submitReview({
              transactionId,
              reviewContent: text,
              reviewRating: rating,
            });
            toast.success('Review submitted');
            onClose();
          }}
        >
          Submit
        </PrimaryButton>
      </div>
    </Modal>
  );
};

export default ReviewModal;
