import { createContext, useContext } from 'react';

const sdkContext = createContext<any>(null);

export const SdkProvider = sdkContext.Provider;

export const useSdk = () => {
  const sdk = useContext(sdkContext);
  if (!sdk) {
    throw new Error('useSdk must be used within a SdkProvider.');
  }
  return sdk;
};
