import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { fetchCurrentUser } from '../../../ducks/user.duck';
import { useSdk } from '../../../util/sdkContext';
import { handleQueryError } from 'app';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { HandleExistsError } from 'util/customError';
import { get, put } from 'util/httpsClient';

export const useUpdateCurrentUser = () => {
  const sdk = useSdk();
  const dispatch = useDispatch();
  const { currentUser } = useCurrentUser();

  return useMutation({
    mutationFn: async (data: any) => {
      let imageUrl;
      if (data.profileImageUrl) {
        imageUrl = data.profileImageUrl;
        delete data.profileImageUrl;
      }
      const queryParams = {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      };

      if (data.publicData?.handle) {
        const existsQueryParams = new URLSearchParams({
          handle: data.publicData?.handle,
          userId: currentUser.id.uuid,
        }).toString();
        const existsPath = `/users/exists?${existsQueryParams}` as const;

        await get({ path: existsPath }).then(res => {
          if (res.userExists) {
            throw new HandleExistsError('User with this username already exists');
          }
          if (data.publicData?.handle) {
            data.publicData.handle = data.publicData.handle.replace('@', '');
          }
        });
      }

      const res = await sdk.currentUser.updateProfile(data, queryParams);
      await put({
        path: '/users',
        body: {
          ...(data.firstName && { firstName: data.firstName }),
          ...(data.lastName && { lastName: data.lastName }),
          ...(data.publicData?.handle && { handle: data.publicData.handle }),
          ...(imageUrl && { imageUrl }),
        },
      });

      return res;
    },
    onSuccess: async () => {
      await dispatch(fetchCurrentUser());
    },
    onError: (error: any, _, context) => {
      handleQueryError(
        error.message || 'Failed to update profile. Please check your input and try again'
      );
    },
    meta: {
      name: 'updateCurrentUser',
    },
  });
};
