import * as log from '../util/log';
import appSettings from 'config/settings';
import { sharetribeSdkCookieStore } from 'index';
// When using SSR, fetch is not defined
let fetch: typeof window.fetch;
if (typeof window !== 'undefined') {
  fetch = window.fetch;
} else {
  fetch = require('node-fetch');
}

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || '';

type PathString = `/${string}`;

type ClientOptions = {
  body?: any;
  headers?: Record<string, string>;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH';
};

async function client(endpoint: PathString, { body, ...customConfig }: ClientOptions = {}) {
  const { accessToken, refreshToken } = getSharetribeTokens();
  const isFormData = body instanceof FormData;
  let ga4ClientId = null;
  if (typeof window !== 'undefined') {
    ga4ClientId = window?.dataLayer?.find(item => item.event === 'clientIdEvent')?.client_id;
  }

  const headers = {
    ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
    ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
    ...(refreshToken && { refresh_token: refreshToken }),
    ...{ 'x-ga4-client-id': ga4ClientId },
    ...customConfig.headers,
  };

  const config: RequestInit = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers,
  };

  if (body) {
    config.body = isFormData ? body : JSON.stringify(body);
  }

  let response = await fetch(`${API_BASE_URL}${endpoint}`, config);

  if (response.status === 401 || response.status === 403) {
    if (refreshToken) {
      const tokenData = await refreshSharetribeToken(refreshToken);

      config.headers!['Authorization'] = `Bearer ${tokenData?.access_token}`;
      config.headers!['refresh_token'] = tokenData?.refresh_token;

      // Retry the request once
      response = await fetch(`${API_BASE_URL}${endpoint}`, config);
    } else {
      log.error(response, 'unauthorized-api-request', config);
      throw new APIError('Unauthorized', { status: response.status });
    }
  }

  if (response.ok) {
    const contentType = response.headers.get('Content-Type');
    if (contentType && contentType.includes('application/json')) {
      return await response.json();
    } else {
      return await response.text();
    }
  } else {
    await processErrorResponse(response);
  }
}

type PathOption = {
  path: PathString;
};

export const post = ({ path, body, ...customConfig }: ClientOptions & PathOption) => {
  return client(path, { method: 'POST', body, ...customConfig });
};

export const put = ({ path, body, ...customConfig }: ClientOptions & PathOption) => {
  return client(path, { method: 'PUT', body, ...customConfig });
};

export const del = ({ path, ...customConfig }: ClientOptions & PathOption) => {
  return client(path, { method: 'DELETE', ...customConfig });
};

export const get = ({ path, ...customConfig }: ClientOptions & PathOption) => {
  return client(path, { method: 'GET', ...customConfig });
};

function getSharetribeTokens() {
  const token = sharetribeSdkCookieStore?.getToken();

  const accessToken = token?.access_token;
  const refreshToken = token?.refresh_token;

  return { accessToken, refreshToken };
}

async function refreshSharetribeToken(refreshToken: string): Promise<any> {
  const response = await fetch('https://flex-api.sharetribe.com/v1/auth/token', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
      Accept: 'application/json',
    },
    body: new URLSearchParams({
      client_id: appSettings.sdk.clientId,
      grant_type: 'refresh_token',
      refresh_token: refreshToken,
      scope: 'user',
    }),
  });

  if (!response.ok) {
    throw new Error(`Failed to refresh token: ${response.statusText}`);
  }

  const data = await response.json();
  sharetribeSdkCookieStore?.setToken(data);

  return data;
}

async function processErrorResponse(response: Response) {
  const data = await response.json();
  if (data.errors && data.errors.length > 0) {
    const errorMessage = data.errors[0].displayMessage || 'An unexpected error occurred';
    log.error(data.errors[0], 'unexpected-api-request', response);
    throw new APIError(errorMessage, data, response.status);
  } else {
    log.error({}, 'unexpected-api-request', response);
    throw new APIError('An unexpected error occurred', null);
  }
}

export class APIError extends Error {
  data: any;
  status?: number;

  constructor(message: string, data: any, status?: number) {
    super(message);
    this.name = 'APIError';
    this.data = data;
    this.status = status;
  }
}
