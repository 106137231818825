import classNames from 'classnames';
import React from 'react';

import { InlineTextButton, NamedLink } from '../../components';
import css from './TabNavHorizontal.module.css';

type TabProps = {
  className?: string;
  text: React.ReactNode;
  disabled?: boolean;
  selected?: boolean;
  onClick?: () => void;
  linkProps?: any;
  href?: string;
};

const Tab: React.FC<TabProps> = props => {
  const { className, disabled, text, selected, onClick, linkProps, href } = props;

  const linkClasses = classNames(css.tabContent, {
    [css.selectedTabContent]: selected,
    [css.disabled]: disabled,
  });

  const buttonClasses = classNames(css.tabContent, css.button, {
    [css.selectedTabContent]: selected,
    [css.disabled]: disabled,
  });

  const isButton = !!onClick;

  return (
    <div className={className}>
      {isButton ? (
        <InlineTextButton rootClassName={buttonClasses} onClick={onClick}>
          {text}
        </InlineTextButton>
      ) : href ? (
        <a href={href} className={linkClasses}>
          {text}
        </a>
      ) : (
        <NamedLink className={linkClasses} {...linkProps}>
          {text}
        </NamedLink>
      )}
    </div>
  );
};

type TabNavHorizontalProps = {
  className?: string | null;
  rootClassName?: string | null;
  tabRootClassName?: string | null;
  tabClassName?: string | null;
  tabs: Array<
    {
      text: React.ReactNode;
      disabled?: boolean;
      selected?: boolean;
    } & (
      | {
          onClick?: (e?: any) => void;
        }
      | {
          linkProps?: any;
        }
    )
  >;
};

export const TabNavHorizontal: React.FC<TabNavHorizontalProps> = props => {
  const { className, rootClassName, tabRootClassName, tabs } = props;
  const classes = classNames(rootClassName || css.root, className);
  const tabClasses = tabRootClassName || css.tab;
  return (
    <nav className={classes}>
      {tabs.map((tab, index) => {
        const key = typeof tab.text === 'string' ? tab.text : index;
        return <Tab key={key} className={tabClasses} {...tab} />;
      })}
    </nav>
  );
};
