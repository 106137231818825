import { KIDS_DEPARTMENTS, ProductDefinitionOption } from './productTypeDefinitions';
import { toKebapCase } from './toKebapCase';

export const clothingSizeStandardOptions: Array<ProductDefinitionOption> = [
  'INT',
  'EU',
  'UK',
  'US',
  'IT',
  'One Size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category }) =>
    !KIDS_DEPARTMENTS.includes(department) && category === 'clothing',
}));

export const shoesSizeStandardOptions: Array<ProductDefinitionOption> = [
  'EU',
  'UK',
  'US',
  'IT',
  'One Size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category }) =>
    !KIDS_DEPARTMENTS.includes(department) && category === 'shoes',
}));

export const sizeStandardToSchemaStandard = (sizeStandard: string) => {
  const sizeStandardMap = {
    eu: 'https://schema.org/WearableSizeSystemEurope',
    uk: 'https://schema.org/WearableSizeSystemUK',
    us: 'https://schema.org/WearableSizeSystemUS',
    it: 'https://schema.org/WearableSizeSystemIT',
  };

  return sizeStandardMap[sizeStandard.toLowerCase()] || 'https://schema.org/WearableSizeSystemUS';
};
