import { useMutation, useQueryClient } from '@tanstack/react-query';

import { QUERY_KEY } from './utils';
import { handleQueryError } from 'app';
import { del } from 'util/httpsClient';

export const useDeleteAddress = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (address: any) => del({ path: `/addresses/${address?.id}` }),
    onMutate: async address => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries([QUERY_KEY]);

      // Snapshot the previous value
      const previousAddresses = queryClient.getQueryData([QUERY_KEY]);

      // Optimistically update to the new value
      queryClient.setQueryData(
        [QUERY_KEY],
        // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
        previousAddresses.filter(a => a.id !== address.id)
      );

      // Return a context object with the snapshotted value
      return { previousAddresses };
    },
    onError: (error: any, __, context) => {
      // Roll back to the previous value
      if (context?.previousAddresses) {
        queryClient.setQueryData([QUERY_KEY], context.previousAddresses);
      }
      if (error.data?.errors[0]?.type === 'AssociatedListingsAddressError') {
        return error;
      } else {
        handleQueryError(
          error.data?.errors[0]?.displayMessage || 'Failed to delete address. Please try again'
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
    },
    meta: { name: 'deleteAddress' },
  });
};
