import React, { useRef } from 'react';

import { CarouselNavigationButtons } from '..';
import css from './ListingsCarousel.module.css';

const ListingsCarousel: React.FC = ({ children }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  return (
    <div className={css.root}>
      <div className={css.listings} ref={carouselRef}>
        {children}
      </div>
      <CarouselNavigationButtons carouselRef={carouselRef} />
    </div>
  );
};

export default ListingsCarousel;
