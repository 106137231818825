import { PropsOf } from '@emotion/react';
import * as Dialog from '@radix-ui/react-dialog';
import * as Portal from '@radix-ui/react-portal';
import classNames from 'classnames';
import {
  createContext,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import css from './Modal.module.css';
import { IconClose } from 'assets/icons';

const FooterContext = createContext<{
  hasFooter: boolean;
  setHasFooter: Dispatch<SetStateAction<boolean>>;
  footerRef: MutableRefObject<HTMLDivElement | null>;
} | null>(null);

type ModalV2Props = {
  title: string;
  contentClassName?: string;
  contentWrapperClassName?: string;
  closeOnOutsideClick?: boolean;
  // TODO: Use dialog overlay and fix scroll issues in addresses
  useDivAsOverlay?: boolean;
  showCloseButton?: boolean;
} & Pick<PropsOf<typeof Dialog.Root>, 'open' | 'onOpenChange'>;

const Modal: React.FC<ModalV2Props> & {
  Footer: React.FC<{ children: React.ReactNode }>;
} = ({
  children,
  title,
  contentClassName,
  contentWrapperClassName,
  closeOnOutsideClick = true,
  useDivAsOverlay = false,
  showCloseButton = true,
  ...rest
}) => {
  const OverlayComponent = useDivAsOverlay ? 'div' : Dialog.Overlay;
  const [hasFooter, setHasFooter] = useState(false);
  const footerRef = useRef<HTMLDivElement | null>(null);
  const contextValue = useMemo(() => {
    return {
      hasFooter,
      setHasFooter,
      footerRef,
    };
  }, [hasFooter, setHasFooter]);

  return (
    <FooterContext.Provider value={contextValue}>
      <Dialog.Root {...rest}>
        <Dialog.Portal>
          <OverlayComponent className={css.overlay} />
          <Dialog.Content
            className={classNames(css.card, contentClassName)}
            onInteractOutside={e => {
              if (!closeOnOutsideClick) {
                e.preventDefault();
              }
            }}
          >
            <Dialog.Title className={css.title}>{title}</Dialog.Title>
            {showCloseButton && (
              <Dialog.Close className={css.closeButton}>
                <IconClose />
              </Dialog.Close>
            )}
            <div
              className={classNames(css.content, contentWrapperClassName)}
              data-has-footer={hasFooter}
            >
              {children}
            </div>
            {hasFooter && <div className={css.footer} ref={footerRef} />}
          </Dialog.Content>
        </Dialog.Portal>
      </Dialog.Root>
    </FooterContext.Provider>
  );
};

const ModalFooter: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const context = useContext(FooterContext);

  useEffect(() => {
    if (context == null) {
      throw new Error('Modal.Footer should be used inside Modal');
    }

    context?.setHasFooter(true);

    return () => {
      context?.setHasFooter(false);
    };
  }, [context]);

  return <Portal.Root container={context?.footerRef.current}>{children}</Portal.Root>;
};

Modal.Footer = ModalFooter;

export default Modal;
