import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import {
  FieldTextInput,
  LayoutSingleColumn,
  MobileBottomNavigation,
  NamedLink,
  Page,
  PrimaryButton,
} from '../../components';
import { NamedRedirect, TabsLayout } from '../../components';
import * as validators from '../../util/validators';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import aboutNoldSrc from './about-nold.png';
import css from './InfoPage.module.css';
import FooterComponent from 'containers/FooterContainer/FooterContainer';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';

export const InfoPageTab = {
  About: 'about',
  HowItWorks: 'how-it-works',
  Nolder: 'nolder',
  Contact: 'contact',
} as const;
// TODO: Zod validate
type InfoPageTab = (typeof InfoPageTab)[keyof typeof InfoPageTab];

export const ProfileSettingsPage = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'ProfileSettingsPage.title' });
  const scrollingDisabled = useIsScrollingDisabled();
  const { tab } = useParams<{ tab: InfoPageTab }>();

  if (!tab) {
    return <NamedRedirect name="InfoPage" params={{ tab: InfoPageTab.About }} />;
  }

  return (
    <Page title={title} scrollingDisabled={scrollingDisabled}>
      <LayoutSingleColumn
        topbar={<TopbarContainer currentPage="ProfileSettingsPage" />}
        footer={<MobileBottomNavigation />}
      >
        <TabsLayout<InfoPageTab>
          title="NOLD"
          tab={tab}
          defaultTab={InfoPageTab.About}
          tabs={[
            {
              title: 'About us',
              value: InfoPageTab.About,
              linkProps: { name: 'InfoPage', params: { tab: InfoPageTab.About } },
              content: (
                <div className={classNames(css.tabContent, css.genericContent)}>
                  <img src={aboutNoldSrc} alt="about nold" />
                  <p>
                    Our name, a fusion of "new" and "old," encapsulates our mission: to bridge the
                    gap between fashion's past and present, and to forge a sustainable future for
                    our planet. The NOLD is a revolutionary marketplace that transcends mere
                    commerce and instead, serves as a catalyst for change in an industry known for
                    its environmental impact.
                  </p>
                  <p>
                    In a world where Earth remains the sole haven for life, the fashion sector has
                    emerged as one of its greatest polluters. We, as the architects of innovation,
                    are obligated to transcend the cycle of overproduction and excess waste. The
                    fashion industry stands at a crossroads. With the power of technology and the
                    will to make a difference, we can strive for a reality where growth is not
                    synonymous with waste.
                  </p>
                  <p>
                    The NOLD is the embodiment of this vision. Our pioneering platform fosters brand
                    partnerships with fashion companies that share our commitment to mitigating the
                    industry's ecological footprint. By promoting a circular economy, we embrace the
                    philosophy that reuse is the most potent weapon against excess production and
                    waste.
                  </p>
                  <p>
                    Welcome to the NOLD, your destination for sustainable fashion resale. We're here
                    to make selling and buying fashion items not only effortless but also
                    environmentally responsible. At the NOLD, we believe in the power of the
                    circular economy to reduce the fashion industry's impact on the planet, one
                    stylish item at a time.
                  </p>
                </div>
              ),
            },
            {
              title: 'How it works',
              value: InfoPageTab.HowItWorks,
              linkProps: { name: 'InfoPage', params: { tab: InfoPageTab.HowItWorks } },
              content: (
                <div className={classNames(css.tabContent, css.genericContent)}>
                  <h1>SELL</h1>

                  <h2>1. LIST YOUR ITEM ENJOYING THE FASTEST, AI-POWERED LISTING</h2>

                  <p>
                    Simply search for your product, and all details will be pre-filled. If the
                    product is not yet part of our database, AI will automatically pre-fill all the
                    details based on your uploaded photos.
                  </p>

                  <h2>2. CONFIRM A SALE AND SHIP SEAMLESSLY</h2>

                  <p>
                    When you confirm an order, a pre-paid label will be generated automatically for
                    you to ship your item.
                  </p>

                  <h2>3. CLAIM YOUR PAYOUT</h2>

                  <p>
                    After successfully selling your item, you can choose to receive 90% of the value
                    in cash or opt for +100% in store credit for the brand partner, or as a NOLD
                    balance.
                  </p>

                  <NamedLink name="ResellLandingPage">
                    <PrimaryButton>Create a listing</PrimaryButton>
                  </NamedLink>

                  <h1>BUY </h1>

                  <h2>1. EXPLORE NOLD’S CURATED SELECTION</h2>

                  <p>
                    Explore a curated selection of pre-loved and sample fashion pieces, updated
                    regularly from top-notch brands and favourite style icons, and discover items,
                    managed and authenticated by NOLD.
                  </p>

                  <h2>2. REQUEST TO BUY AN ITEM</h2>

                  <p>
                    Found the perfect fashion gem? Request to buy it and await confirmation from the
                    seller. If you require additional details or photos to help you make the final
                    decision, utilise the chat feature to communicate freely with the seller.
                  </p>

                  <h2>3. RECEIVE, REVIEW, SHOP AGAIN</h2>

                  <p>
                    Providing feedback is a vital part of building a strong and trustworthy NOLD
                    community! So, after successfully receiving the item, don’t forget to leave a
                    fair review of your shopping experience.{' '}
                  </p>

                  <NamedLink name="SearchPage">
                    <PrimaryButton>Explore</PrimaryButton>
                  </NamedLink>
                </div>
              ),
            },
            {
              title: 'Nolder',
              value: InfoPageTab.Nolder,
              linkProps: { name: 'InfoPage', params: { tab: InfoPageTab.Nolder } },
              content: (
                <div className={classNames(css.tabContent, css.genericContent)}>
                  <h1>BECOME A NOLDer</h1>

                  <h2>BRAND PARTNERSHIPS</h2>

                  <p>
                    Our strategy is all about fostering exciting brand partnerships. This means you
                    get to list and sell your favourite products without worrying about commission
                    fees, all while enjoying the unique aesthetic experience of each brand.
                  </p>

                  <h2>AUTHENTICITY</h2>

                  <p>
                    The NOLD partners with a third-party provider which can verify the authenticity
                    of the items. Buyers can request verification of products they purchase for an
                    additional fee. This process involves submitting online images to NOLD,
                    following which experts provide certification confirming the product's
                    authenticity.
                  </p>

                  <h2>ICONIC WARDROBES</h2>

                  <p>
                    We believe that teaming up with the most influential style icons could be a
                    transformative statement in the fashion world, enabling users to gain access to
                    exclusive insights, trends, and curated collections that redefine their fashion
                    experience.
                  </p>
                </div>
              ),
            },
            {
              title: 'Contact us',
              value: InfoPageTab.Contact,
              linkProps: { name: 'InfoPage', params: { tab: InfoPageTab.Contact } },
              content: (
                <div className={classNames(css.tabContent, css.genericContent)}>
                  <ContactPage />
                </div>
              ),
            },
          ]}
        />
        <FooterComponent />
      </LayoutSingleColumn>
    </Page>
  );
};

const ContactPage = () => {
  return (
    <FinalForm
      onSubmit={values => {}}
      render={({ values, valid, form }) => {
        const subject = encodeURIComponent(`${values.subject} - ${values.name}`);
        const message = encodeURIComponent(values.message);
        const email = 'hello@thenold.com';
        const mailto = `mailto: ${email}?subject=${subject}&body=${message}`;

        return (
          <>
            <FieldTextInput
              id="name"
              name="name"
              autoComplete="name"
              label="Full name"
              type="text"
              validate={validators.required('Name is required')}
            />
            <FieldTextInput
              id="subject"
              name="subject"
              autoComplete="subject"
              label="Subject"
              type="text"
              validate={validators.required('Subject is required')}
            />
            <FieldTextInput
              id="message"
              name="message"
              autoComplete="message"
              label="Message"
              type="textarea"
              validate={validators.required('Message is required')}
            />
            <a
              href={valid ? mailto : ''}
              onClick={
                valid
                  ? undefined
                  : e => {
                      e.preventDefault();
                      form.submit();
                    }
              }
            >
              <PrimaryButton type="button">Send Mail</PrimaryButton>
            </a>
            <div>
              Clicking the button will compose an email to{' '}
              <a style={{ textDecoration: 'underline' }} href={`mailto:${email}`}>
                {email}
              </a>
            </div>
          </>
        );
      }}
    ></FinalForm>
  );
};

export default ProfileSettingsPage;
