/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// NOTE: if you want to change the structure of the data,
// you should also check src/util/configHelpers.js
// some validation is added there.

// TODO: UPDATE THIS!

export const ConditionType = {
  NeverWornWithTag: 'never-worn-with-tag',
  NeverWornWithoutTag: 'never-worn-without-tag',
  Excellent: 'excellent',
  GoodWithNegligibleDefects: 'good-with-negligible-defects',
  GoodWithDefects: 'good-with-defects',
  SampleSale: 'sample-sale',
} as const;

export const conditionTypes = Object.values(ConditionType);

export const ConditionTypeLabels = {
  [ConditionType.NeverWornWithTag]: 'Never worn, with a tag',
  [ConditionType.NeverWornWithoutTag]: 'Never worn, without a tag',
  [ConditionType.Excellent]: 'Excellent',
  [ConditionType.GoodWithNegligibleDefects]: 'Good with negligible defects',
  [ConditionType.GoodWithDefects]: 'Good with defects',
  [ConditionType.SampleSale]: 'Sample sale',
} as const;

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

export type ListingType = {
  // Unique string. This will be saved to listing's public data on EditListingWizard.
  listingType: string;
  // Label for the listing type. Used as microcopy for options to select listing type in EditListingWizard.
  label: string;
  // Set of configurations how this listing type will behave when transaction is created.
  transactionType: {
    // Transaction process.
    // The process must match one of the processes that this client app can handle
    // (check src/util/transaction.js) and the process must also exists in correct marketplace environment.
    process: string;
    // Valid alias for the aforementioned process. This will be saved to listing's public data as transctionProcessAlias and transaction is initiated with this.
    alias: string;
    // Unit type is mainly used as pricing unit. This will be saved to transaction's protected data.
    // Recommendation: don't use same unit types in completely different processes ('item' sold should not be priced the same as 'item' booked).
    unitType: string;
  };
  // This is relevant only to listings with product-selling listing type.
  // If set to 'oneItem', stock management is not showed and the listing is considered unique (stock = 1).
  // Possible values: 'oneItem' and 'multipleItems'.
  // Default: 'multipleItems'.
  stockType: 'oneItem' | 'multipleItems';
};
export const listingTypes: ListingType[] = [
  {
    listingType: 'product-selling',
    label: 'Product selling',
    transactionType: {
      process: 'default-purchase',
      alias: 'default-purchase/release-1',
      unitType: 'item',
    },
    stockType: 'multipleItems',
  },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;

const configListing = {
  enforceValidListingType,
  listingTypes,
};

export default configListing;
