import { useQuery } from '@tanstack/react-query';

import { get } from '../../../util/httpsClient';
import { useCurrentUser } from '../../selectors/useCurrentUser';

export function useGetStripeAccount() {
  const { currentUser } = useCurrentUser();
  const hasStripeAccount = currentUser?.attributes?.profile?.privateData?.stripeId;

  return useQuery({
    queryKey: ['stripeAccount', hasStripeAccount],
    queryFn: async () => {
      try {
        if (!hasStripeAccount) {
          return null;
        }
        return await get({ path: '/users/stripe' });
      } catch (error: any) {
        const noStripeAccount = error.data.errors?.find(e => e.type === 'NotFoundError');
        if (noStripeAccount) {
          return null;
        }
        throw error;
      }
    },
    meta: {
      errorMessage: 'Failed to fetch stripe account',
    },
  });
}
