import { createContext, useContext } from 'react';

import { RouteConfiguration } from 'routing/routeConfiguration';

const RouteConfigurationContext = createContext<RouteConfiguration | null>(null);

export const RouteConfigurationProvider = RouteConfigurationContext.Provider;

export const useRouteConfiguration = () => {
  const value = useContext(RouteConfigurationContext);

  if (!value) {
    throw new Error('useRouteConfiguration must be used within a RouteConfigurationProvider');
  }

  return value;
};
