import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import * as Popover from '@radix-ui/react-popover';
import classNames from 'classnames';
import { ChevronDown } from 'lucide-react';
import { useEffect, useState } from 'react';
import { FlagIcon } from 'react-flag-kit';

import { IconHeart, IconInbox, IconSearch, IconWallet, LogoNold } from '../../../assets/icons';
import { Avatar, NamedLink } from '../../../components';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './TopbarDesktop.module.css';
import { SettingsPageTab } from 'containers/SettingsPage/SettingsPage';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useGeolocation } from 'hooks/useGeolocation';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { getCountryCodes } from 'translations/countryCodes';

const emptyUUID = '00000000-0000-0000-0000-000000000000';

const TopbarDesktop = props => {
  const { className, appConfig, currentUser, rootClassName, intl, isAuthenticated, onLogout } =
    props;
  const [mounted, setMounted] = useState(false);
  const { userGeolocation, updateGeolocation } = useGeolocation();

  useEffect(() => {
    setMounted(true);
  }, [intl.locale]);

  const marketplaceName = appConfig.marketplaceName;
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;

  const classes = classNames(rootClassName || css.root, className);

  const profileMenu =
    authenticatedOnClientSide && currentUser?.id?.uuid ? <ProfileMenu onLogout={onLogout} /> : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.basicLink} style={{ marginRight: 20 }}>
      <FormattedMessage id="TopbarDesktop.signup" />
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" className={css.basicLink} style={{ marginRight: 20 }}>
      <FormattedMessage id="TopbarDesktop.login" />
    </NamedLink>
  );

  return (
    <nav className={classes}>
      <div className={css.maxWidthWrapper}>
        <div className={css.sectionLeft}>
          <NamedLink name="SearchPage" className={css.basicLink}>
            SHOP
          </NamedLink>
          <NamedLink name="ResellLandingPage" className={css.basicLink}>
            SELL
          </NamedLink>
        </div>
        <div className={css.sectionCenter}>
          <NamedLink name="LandingPage">
            <LogoNold
              height={20}
              width="auto"
              title={intl.formatMessage({ id: 'TopbarDesktop.logo' }, { marketplaceName })}
            />
          </NamedLink>
        </div>
        <div className={css.sectionRight}>
          {signupLink}
          {loginLink}{' '}
          <NamedLink
            className={css.linkIconWrapper}
            name="SearchPage"
            tabIndex={-1}
            onClick={event => {
              // Middle click (e.g. wheel click) on link should open the link in new tab.
              if (event.button !== 1) {
                event.preventDefault();
              }
            }}
          >
            <Popover.Trigger
              style={{ padding: 0, border: 0, borderRadius: '50%', outlineOffset: 8 }}
            >
              <IconSearch title="search" />
            </Popover.Trigger>
          </NamedLink>
          {authenticatedOnClientSide ? (
            <>
              <NamedLink
                className={css.linkIconWrapper}
                params={{ id: currentUser?.id?.uuid || emptyUUID }}
                to={{ search: '?profileTab=favourites' }}
                name="ProfilePage"
              >
                <IconHeart />
              </NamedLink>
              <NamedLink className={css.linkIconWrapper} name="WalletPage">
                <IconWallet />
              </NamedLink>
              <NamedLink className={css.linkIconWrapper} name="ChatPage">
                <IconInbox />
              </NamedLink>
            </>
          ) : null}
          {profileMenu}
          <CountrySelector
            userGeolocation={userGeolocation}
            updateGeolocation={updateGeolocation}
            intl={intl}
          />
        </div>
      </div>
    </nav>
  );
};

const CountrySelector = ({ userGeolocation, updateGeolocation, intl }) => {
  const [open, setOpen] = useState(false);
  const countryCodes = getCountryCodes(intl.locale, 'all', null);

  const renderFlag = countryCode => {
    return <FlagIcon code={countryCode} size={22} />;
  };

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger
        className={`${css.linkIconWrapper} focus:outline-none focus:bg-gray-100 w-7 h-7 flex items-center justify-center rounded-full hover:bg-gray-100 transition-colors duration-200`}
      >
        <span className="text-[22px] leading-none"></span>
        {renderFlag(userGeolocation)}
        <ChevronDown className="ml-0.5 w-3 h-3" />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          className={`${css.profileMenu__content} w-64 max-h-[300px] overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100`}
          sideOffset={5}
        >
          {countryCodes.map(country => (
            <DropdownMenu.Item
              key={country.code}
              className="flex items-center cursor-pointer px-4 py-2 hover:bg-gray-100 transition-colors duration-200"
              onSelect={() => {
                updateGeolocation(country.code);
                setOpen(false);
              }}
            >
              {renderFlag(country.code)}
              <span className="ml-2">{country.name}</span>
            </DropdownMenu.Item>
          ))}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export const ProfileMenu = ({ onLogout }) => {
  const { currentUser } = useCurrentUser();
  const [open, setOpen] = useState(false);
  const isDesktop = useMediaQueries({ viewport: 'medium' });

  useEffect(() => {
    if (!isDesktop) {
      setOpen(false);
    }
  }, [isDesktop]);

  return (
    <DropdownMenu.Root open={open} onOpenChange={setOpen}>
      <DropdownMenu.Trigger style={{ border: 'none' }}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content
          collisionPadding={{
            right: 16,
            bottom: 16,
          }}
          className={css.profileMenu__content}
        >
          <DropdownMenu.Item asChild>
            <NamedLink params={{ id: currentUser.id?.uuid || emptyUUID }} name="ProfilePage">
              <FormattedMessage id="TopbarDesktop.yourListingsLink" />
            </NamedLink>
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <NamedLink name="WalletPage">
              <FormattedMessage id="TopbarDesktop.walletLink" />
            </NamedLink>
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <NamedLink name="SettingsPage" params={{ tab: SettingsPageTab.EditProfile }}>
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </DropdownMenu.Item>
          <DropdownMenu.Item asChild>
            <button className={css.logout} onClick={() => onLogout?.()}>
              <FormattedMessage id="TopbarDesktop.logout" />
            </button>
          </DropdownMenu.Item>
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default TopbarDesktop;
