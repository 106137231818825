import { useQueryParams } from '../../hooks/useQueryParams';
import { FormattedMessage } from '../../util/reactIntl';
import { ProfileTab } from './ProfilePage';

export const useProfileTabs = () => {
  const [{ profileTab }, setQueryParams] = useQueryParams({
    profileTab: ProfileTab.LISTINGS,
  });

  const profileTabs = [
    {
      text: <FormattedMessage id="ProfilePage.listings" />,
      value: ProfileTab.LISTINGS,
    },
    {
      text: <FormattedMessage id="ProfilePage.favourites" />,
      value: ProfileTab.FAVOURITES,
    },
    {
      text: <FormattedMessage id="ProfilePage.myOrders" />,
      value: ProfileTab.ORDERS,
    },
    {
      text: <FormattedMessage id="ProfilePage.reviews" />,
      value: ProfileTab.REVIEWS,
    },
  ].map(tab => ({
    ...tab,
    selected: profileTab === tab.value,
    onClick: e => {
      e.preventDefault();
      setQueryParams(
        {
          profileTab: tab.value,
        },
        { clearOther: true }
      );
    },
  }));

  return {
    profileTabs,
    currentProfileTab: profileTab,
  };
};
