import * as Dialog from '@radix-ui/react-dialog';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

import { IconClose } from 'assets/icons';
import { cn } from 'util/cn';

interface DrawerProps {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  children: ReactNode;
  title?: string;
  contentClassName?: string;
}

const Drawer = ({ isOpen, onOpenChange, contentClassName, children, title }: DrawerProps) => {
  return (
    <Dialog.Root open={isOpen} onOpenChange={onOpenChange}>
      <Dialog.Portal forceMount>
        <AnimatePresence>
          {isOpen && (
            <>
              <Dialog.Overlay asChild>
                <motion.div
                  className="fixed inset-0 bg-black/50 backdrop-blur-sm"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                />
              </Dialog.Overlay>
              <Dialog.Content asChild>
                <motion.div
                  className="fixed inset-y-0 left-0 w-[calc(100%-var(--n-size-7))] flex flex-col bg-white overflow-y-auto"
                  initial={{ x: '-100%' }}
                  animate={{ x: '0' }}
                  exit={{ x: '-100%' }}
                  transition={{ duration: 0.2 }}
                >
                  <div className="flex items-center justify-between">
                    {title && <h2 className="text-lg font-bold">{title}</h2>}
                    <Dialog.Close className="p-2">
                      <IconClose title="close" />
                    </Dialog.Close>
                  </div>
                  <div className={cn('flex-1 overflow-y-auto p-2', contentClassName)}>
                    {children}
                  </div>
                </motion.div>
              </Dialog.Content>
            </>
          )}
        </AnimatePresence>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export default Drawer;
