import classNames from 'classnames';
import React from 'react';
import { Field } from 'react-final-form';

import { ValidationError } from '../../components';
import css from './FieldSelect.module.css';

type SelectProps = {
  rootClassName?: string;
  className?: string;
  selectClassName?: string;

  // Label is optional, but if it is given, an id is also required so
  // the label can reference the input in the `for` attribute
  id?: string;
  label?: string;

  // Generated by final-form's Field component
  input: any;
  meta: any;

  onChange?: (value: string) => void;
  children?: React.ReactNode;
};

const FieldSelectComponent: React.FC<SelectProps> = props => {
  const {
    rootClassName,
    className,
    selectClassName,
    id,
    label,
    input,
    meta,
    children,
    onChange,
    ...rest
  } = props;

  if (label && !id) {
    throw new Error('id required when a label is given');
  }

  const { invalid, touched, error } = meta;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = touched && invalid && error;

  const selectClasses = classNames({
    [css.selectError]: hasError,
  });
  const handleChange = e => {
    input.onChange(e);
    if (onChange) {
      onChange(e.currentTarget.value);
    }
  };

  const selectProps = { className: selectClasses, id, ...input, onChange: handleChange, ...rest };

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <select {...selectProps}>{children}</select>
      <ValidationError fieldMeta={meta} />
    </div>
  );
};

const FieldSelect = props => {
  return (
    <Field
      name={props.name}
      validate={props.validate}
      render={({ input, meta }) => <FieldSelectComponent {...props} input={input} meta={meta} />}
    />
  );
};

export default FieldSelect;
