import { useQuery } from '@tanstack/react-query';

import { denormalisedResponseEntities } from '../../../util/data';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';
import { useSdk } from 'util/sdkContext';

export function useGetUserReviews({ type, page }: { type: string; page: string }) {
  const { currentUser } = useCurrentUser();
  const sdk = useSdk();

  return useQuery({
    enabled: Boolean(currentUser.id?.uuid),
    queryKey: ['reviews', type, page],
    queryFn: () =>
      sdk.reviews
        .query({
          subjectId: currentUser.id.uuid,
          type,
          include: ['author', 'author.profileImage', 'subject'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
          perPage: 10,
          page: Number(page),
        })
        .then(res => ({
          totalPages: res?.data?.meta?.totalPages || 0,
          totalItems: res?.data?.meta?.totalItems || 0,
          reviews: denormalisedResponseEntities(res),
        })),
    meta: {
      errorMessage: 'Failed to fetch reviews',
    },
  });
}
