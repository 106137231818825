import { useQuery } from '@tanstack/react-query';

import { useCurrentUser } from '../../selectors/useCurrentUser';
import { QUERY_KEY } from './utils';
import { get } from 'util/httpsClient';

export const useGetAddresses = () => {
  const { currentUser } = useCurrentUser();

  return useQuery({
    enabled: Boolean(currentUser.id),
    queryKey: [QUERY_KEY],
    queryFn: getAddressesQueryFn(),
    meta: {
      errorMessage: 'Failed to fetch addresses',
    },
  });
};

export const getAddressesQueryKey = () => [QUERY_KEY] as const;

export const getAddressesQueryFn = () => () =>
  get({ path: `/users/addresses` }).then(
    res => res?.sort((a, b) => (a.createdOn > b.createdOn ? 1 : -1))
  );
