import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';

import { useCurrentUser } from '../../selectors/useCurrentUser';
import { QUERY_KEY } from './utils';
import { handleQueryError } from 'app';
import ToastContent from 'components/ToasterContent/ToastContent';
import { AddressFormData } from 'containers/SettingsPage/ProfileAddressesForm/components/AddressForm/AddressForm';
import { post } from 'util/httpsClient';

export const useCreateAddress = () => {
  const { currentUser } = useCurrentUser();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (address: AddressFormData) =>
      post({
        path: `/addresses`,
        body: {
          ...address,
          email: address.email || currentUser?.attributes?.email,
          isDefault: undefined,
          validate: false,
        },
      }),
    onError: (error: any) => {
      if (error.data?.errors[0]?.type === 'ShippoError') {
        toast.error(
          t => <ToastContent messages={error.data.errors[0].validationMessages} toastId={t.id} />,
          {
            duration: 10000,
          }
        );
      } else {
        handleQueryError('Failed to create address. Please check your input and try again');
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries([QUERY_KEY]);
    },
    meta: { name: 'createAddress' },
  });
};
