import { useQuery } from '@tanstack/react-query';

import { get } from 'util/httpsClient';

interface ConfigurationResponse {
  enums: {
    departments: any[];
    categories: any[];
    productTypes: any[];
    productSubtypes: any[];
    colors: any[];
    compositions: any[];
    styleTags: any[];
    sizes: any[];
  };
  brands: {
    id: number;
    name: string;
    partnerId: number | undefined;
  }[];
  defaultPartnerId: number;
  partners: any[];
  sizeMappings: any[];
  SEOMetadata: Array<{
    id: number;
    seoTitleText: string;
    seoCollectionText: string;
    seoBodyText: string;
    seoMetaDescription: string | null;
    customData: any;
    urlPathname: string;
  }>;
  collections: {
    id: number;
    name: string;
    filterLink: string;
  }[];
  awsAppConfig: {
    shopDrawer: {
      collections: number[];
      icons: Array<{
        name: string;
        id: string;
      }>;
    };
  };
}

export const useGetConfiguration = () => {
  return useQuery({
    queryKey: ['configuration'],
    queryFn: async () =>
      get({
        path: '/configuration',
      }) as Promise<ConfigurationResponse>,
  });
};

export const getConfigurationQueryKey = () => ['configuration'];

export const getConfigurationQueryFn = () => async () =>
  get({ path: '/configuration' }) as Promise<ConfigurationResponse>;
