import * as Tabs from '@radix-ui/react-tabs';
import React, { useEffect } from 'react';

import { NamedLink } from '..';
import css from './TabsLayout.module.css';
import { useMediaQueries } from 'hooks/useMediaQueries';
import { RouteName } from 'routing/routeConfiguration';

type TabsLayoutProps<T extends string> = {
  title: string;
  tabs: {
    title: string;
    content: React.ReactNode;
    value: T;
    linkProps?: { name: RouteName; params: { tab: T } };
    href?: string;
  }[];
  defaultTab?: T;
  tab: T;
};

const TabsLayout = <T extends string>({ title, tab, tabs, defaultTab }: TabsLayoutProps<T>) => {
  const isDesktop = useMediaQueries({ viewport: 'large' });
  const isMobile = !isDesktop;

  if (tabs.some(({ href, linkProps }) => !href && !linkProps)) {
    throw new Error('Tabs must have either href or linkProps');
  }

  const navRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (isMobile && navRef.current) {
      const activeTab = navRef.current.querySelector("[data-active='true']");
      if (activeTab) {
        activeTab.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }, [isMobile]);

  return (
    <Tabs.Root defaultValue={defaultTab} className={css.tabs} value={tab}>
      {isMobile && <h1 className={css.pageTitle}>{title}</h1>}
      {isDesktop && (
        <div className={css.topBar}>
          <div className={css.topBar__content}>
            <h1>{title}</h1>
            <h2>
              {tabs.map(({ title, value }) => (
                <Tabs.Content key={value} value={value}>
                  {title}
                </Tabs.Content>
              ))}
            </h2>
          </div>
        </div>
      )}
      <div className={css.tabs__contentWrapper}>
        <Tabs.List className={css.tabs__list} ref={navRef}>
          {tabs.map(({ title, value, linkProps, href }) => (
            <Tabs.Trigger
              key={value}
              value={value}
              data-active={tab === value}
              className={css.tabs__trigger}
              asChild
            >
              {linkProps ? (
                <NamedLink {...linkProps}>{title}</NamedLink>
              ) : (
                <a href={href} target="_blank" rel="noreferrer">
                  {title}
                </a>
              )}
            </Tabs.Trigger>
          ))}
        </Tabs.List>
        {tabs.map(({ value, content }) => (
          <Tabs.Content key={value} value={value}>
            {content}
          </Tabs.Content>
        ))}
      </div>
    </Tabs.Root>
  );
};

export default TabsLayout;
