import * as Portal from '@radix-ui/react-portal';
import { useEffect, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import css from './IntercomChatLauncher.module.css';
import { IconIntercomChat } from 'assets/icons';

const hasIntercomCookie = () => {
  if (typeof window !== 'undefined' && document) {
    return document?.cookie?.includes('intercom-id');
  }

  return false;
};

export const useHasIntercomCookie = () => {
  const [hasCookie, setHasCookie] = useState(hasIntercomCookie());
  useEffect(() => {
    const interval = setInterval(() => {
      const newValue = hasIntercomCookie();
      if (hasCookie !== newValue) {
        setHasCookie(newValue);
      }
    }, 1000);
    return () => clearInterval(interval);
  });

  return hasCookie;
};

const IntercomChatLauncher = () => {
  const { show } = useIntercom();

  const hasCookie = useHasIntercomCookie();

  return (
    <Portal.Root asChild>
      {hasCookie ? (
        <button className={css.root} aria-label="Open Intercom Chat" onClick={show}>
          <IconIntercomChat />
        </button>
      ) : (
        <a href="mailto:hello@thenold.com" className={css.root} aria-label="Contact support">
          <IconIntercomChat />
        </a>
      )}
    </Portal.Root>
  );
};

export default IntercomChatLauncher;
