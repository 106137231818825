import React, { useEffect } from 'react';
import { Field, useForm, useFormState } from 'react-final-form';
import { useIntl } from 'react-intl';
import { types } from 'sharetribe-flex-sdk';

import * as validators from '../../../util/validators';
import { priceData } from '../../ListingPage/ListingPage.shared';
import { CardButton } from '../../SettingsPage/ProfileAddressesForm/components/AddressItem';
import { OrderData } from '../CheckoutPage';
import css from './CheckoutShippingMethodFields.module.css';
import { IconSpinner } from 'components';
import { useConfiguration } from 'context/configurationContext';
import { useGeolocation } from 'hooks/useGeolocation';

const { Money } = types;

export const CheckoutShippingMethodFields: React.FC<{
  rates: any;
  isRatesLoading: boolean;
}> = ({ rates, isRatesLoading }) => {
  const config = useConfiguration();
  const intl = useIntl();
  const { userGeolocation } = useGeolocation();
  const form = useForm();
  const { values } = useFormState();

  if (rates) {
    rates = rates.map(rate => ({
      id: rate.id,
      name: rate.name,
      description: <img style={{ height: 24 }} src={rate.providerImage} alt="shipping provider" />,
      price: { amount: Number(rate.amount) * 100, currency: rate.currency },
    }));
  }

  useEffect(() => {
    if (rates?.length && !values.shippingRateId) {
      form.change('shippingRateId', rates[0].id);
    }
  }, [form, rates, values.shippingRateId]);

  // Hardcoding a mock Evri rate for guest users in GB when no rates are available
  if (rates?.length === 0 && userGeolocation === 'GB') {
    rates = [
      {
        id: 'evri_shipping',
        name: 'ParcelShop Drop-Off ',
        price: { amount: 248, currency: 'GBP' },
        description: (
          <img
            style={{ height: 24 }}
            src="https://shippo-static.s3.amazonaws.com/providers/200/myHermes.png"
            alt="shipping provider"
          />
        ),
      },
    ];
  }

  return (
    <Field
      name="shippingRateId"
      key={rates?.map(rate => rate.id).join('')}
      validate={validators.required('')}
    >
      {props => (
        <>
          <input hidden {...props.input} />
          {isRatesLoading && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <IconSpinner />
            </div>
          )}
          {rates?.map(shippingRate => (
            <CardButton
              selected={props.input.value === shippingRate.id}
              key={shippingRate.id}
              onClick={() => props.input.onChange(shippingRate.id)}
            >
              <div className={css.shippingMethodInfo}>
                <span>{shippingRate.name}</span>
                <span>
                  {
                    priceData(
                      new Money(shippingRate.price.amount, shippingRate.price.currency),
                      config.currency,
                      intl
                    )?.formattedPrice
                  }
                </span>
                <span>{shippingRate.description}</span>
              </div>
            </CardButton>
          ))}
          {props.meta.touched && props.meta.error && (
            <span style={{ color: 'red' }}>{props.meta.error}</span>
          )}
          {!rates && !isRatesLoading && (
            <div style={{ fontWeight: 600 }}>
              Please select a shipping address to see available shipping methods.
            </div>
          )}
          {rates?.length === 0 && !isRatesLoading && (
            <div style={{ fontWeight: 600, color: 'red' }}>
              There are no shipping methods available for your address.
            </div>
          )}
          <div style={{ color: '#666' }}>
            Taxes and duties may apply based on the origin and destination countries.
          </div>
        </>
      )}
    </Field>
  );
};
