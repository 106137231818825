import classNames from 'classnames';
import React from 'react';
import { FieldMetaState } from 'react-final-form';

import css from './ValidationError.module.css';

interface P {
  rootClassName?: string;
  className?: string;
  fieldMeta: FieldMetaState<any>;
}

/**
 * This component can be used to show validation errors next to form
 * input fields. The component takes the final-form Field component
 * `meta` object as a prop and infers if an error message should be
 * shown.
 */
const ValidationError: React.FC<P> = props => {
  const { rootClassName = '', className = '', fieldMeta } = props;
  const { touched, error } = fieldMeta;
  const classes = classNames(rootClassName || css.root, className);
  return touched && error ? <div className={classes}>{error}</div> : null;
};

export default ValidationError;
