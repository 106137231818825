import { useQueryParams } from '../../hooks/useQueryParams';
import { WalletTab } from './WalletPage';

export const useWalletTabs = () => {
  const [{ walletTab }, setQueryParams] = useQueryParams({
    walletTab: WalletTab.PAYOUTS,
  });

  const walletTabs = [
    {
      text: 'Your Payouts',
      value: WalletTab.PAYOUTS,
    },
    {
      text: 'Store Credits',
      value: WalletTab.STORE_CREDITS,
    },
  ].map(tab => ({
    ...tab,
    selected: walletTab === tab.value,
    onClick: e => {
      e.preventDefault();
      setQueryParams(
        {
          walletTab: tab.value,
        },
        { clearOther: true }
      );
    },
  }));

  return {
    profileTabs: walletTabs,
    currentWalletTab: walletTab,
  };
};
