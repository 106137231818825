import { ProductDefinitionOption } from './productTypeDefinitions';
import { toKebapCase } from './toKebapCase';

export const intMenClothingSizeOptions: Array<ProductDefinitionOption> = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'XXL',
  'XXXL',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    department === 'men' && category === 'clothing' && sizeStandard === 'int',
}));

export const euMenClothingSizeOptions: Array<ProductDefinitionOption> = [
  '44',
  '46',
  '48',
  '50',
  '52',
  '54',
  '56',
  '58',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    department === 'men' && category === 'clothing' && sizeStandard === 'eu',
}));

export const ukMenClothingSizeOptions: Array<ProductDefinitionOption> = [
  '34',
  '36',
  '38',
  '40',
  '42',
  '44',
  '46',
  '48',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    department === 'men' && category === 'clothing' && sizeStandard === 'uk',
}));

export const usMenClothingSizeOptions: Array<ProductDefinitionOption> = [
  '34',
  '36',
  '38',
  '40',
  '42',
  '44',
  '46',
  '48',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    department === 'men' && category === 'clothing' && sizeStandard === 'us',
}));

export const itMenClothingSizeOptions: Array<ProductDefinitionOption> = [
  '44',
  '46',
  '48',
  '50',
  '52',
  '54',
  '56',
  '58',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    department === 'men' && category === 'clothing' && sizeStandard === 'it',
}));

export const euMenShoesSizeOptions: Array<ProductDefinitionOption> = [
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
  '44',
  '45',
  '46',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    department === 'men' && category === 'shoes' && sizeStandard === 'eu',
}));

export const ukMenShoesSizeOptions: Array<ProductDefinitionOption> = [
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    department === 'men' && category === 'shoes' && sizeStandard === 'uk',
}));

export const usMenShoesSizeOptions: Array<ProductDefinitionOption> = [
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
  '13',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    department === 'men' && category === 'shoes' && sizeStandard === 'us',
}));

export const itMenShoesSizeOptions: Array<ProductDefinitionOption> = [
  '35',
  '36',
  '37',
  '38',
  '39',
  '40',
  '41',
  '42',
  '43',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category, sizeStandard }) =>
    department === 'men' && category === 'shoes' && sizeStandard === 'it',
}));

export const menBeltSizeOptions: Array<ProductDefinitionOption> = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'One size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category }) => department === 'men' && category === 'belts',
}));

export const menGlovesSizeOptions: Array<ProductDefinitionOption> = [
  'XS',
  'S',
  'M',
  'L',
  'XL',
  'One size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category }) => department === 'men' && category === 'gloves',
}));

export const menHatSizeOptions: Array<ProductDefinitionOption> = [
  'XS',
  'S',
  'M',
  'L',
  'One Size',
].map(size => ({
  value: toKebapCase(size),
  label: size,
  showPredicate: ({ department, category }) => department === 'men' && category === 'hats',
}));

export const menRingSizeOptions: Array<ProductDefinitionOption> = ['S', 'M', 'L', 'One size'].map(
  size => ({
    value: toKebapCase(size),
    label: size,
    showPredicate: ({ department, category }) => department === 'men' && category === 'rings',
  })
);
