import { Field } from 'react-final-form';

import { ValidationError } from '..';
import { FormattedMessage } from '../../util/reactIntl';
import * as validators from '../../util/validators';
import { Input } from 'components/_chadcn/Input';
import { Label } from 'components/_chadcn/Label';
import { cn } from 'util/cn';

const FieldUsername = ({ formId, initialValue }: { formId?: string; initialValue?: string }) => (
  <Field
    id={formId ? `${formId}.handle` : 'handle'}
    name="handle"
    validate={validators.isUsernameValid}
    initialValue={initialValue}
    render={({ input, meta }) => (
      <div>
        <Label htmlFor="handle">
          <FormattedMessage id="SignupForm.handleLabel" />
        </Label>
        <div className='relative after:text-0-in-px after:content-["@"] after:left-2 after:absolute after:-top-px after:bottom-0 after:grid after:items-center'>
          <Input
            placeholder="username"
            className={cn('pl-[calc(var(--n-size-4)+4px)]', {
              'border-[#ff0000]': meta.touched && meta.error,
            })}
            {...input}
          />
        </div>
        <ValidationError fieldMeta={meta} />
      </div>
    )}
  />
);

export default FieldUsername;
