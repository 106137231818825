import { SVGProps, useEffect, useState } from 'react';

import css from './StarRating.module.css';

type P = {
  rating: number;
  className?: string;
  style?: React.CSSProperties;
};

export const StarRatingWithNumber: React.FC<P> = ({ rating, className, style }) => {
  return (
    <div className={className + ' ' + css.rating} style={style}>
      <StarRating rating={rating} />
      <span className={css.number}>{Math.round(rating * 10) / 10}</span>
    </div>
  );
};

const StarRating: React.FC<{
  rating: number;
  onClick?: (rating: number) => void;
  style?: React.CSSProperties;
}> = ({ rating, onClick, style }) => {
  const stars: JSX.Element[] = [];

  const [displayRating, setDisplayRating] = useState(rating);
  useEffect(() => {
    setDisplayRating(rating);
  }, [rating]);

  const onHover = (i: number) => {
    if (onClick) {
      setDisplayRating(i);
    }
  };
  const onHoverEnd = () => {
    if (onClick) {
      setDisplayRating(rating);
    }
  };

  for (let i = 1; i <= 5; i++) {
    if (i <= displayRating) {
      stars.push(
        <FilledStar
          key={i}
          onMouseEnter={() => onHover(i)}
          onMouseLeave={onHoverEnd}
          onClick={() => onClick?.(i)}
        />
      );
    } else if (0 < i - displayRating && i - displayRating < 1) {
      stars.push(
        <HalfStar
          key={i}
          onMouseEnter={() => onHover(i)}
          onMouseLeave={onHoverEnd}
          onClick={() => onClick?.(i)}
        />
      );
    } else {
      stars.push(
        <EmptyStar
          key={i}
          onMouseEnter={() => onHover(i)}
          onMouseLeave={onHoverEnd}
          onClick={() => onClick?.(i)}
        />
      );
    }
  }
  return (
    <span className={css.stars} style={style}>
      {stars}
    </span>
  );
};

const EmptyStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <mask
      id="mask0_847_17622"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="25"
    >
      <rect x="0.0803223" y="0.712158" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_847_17622)">
      <path
        d="M8.93041 18.5371L12.0804 16.6371L15.2304 18.5621L14.4054 14.9621L17.1804 12.5621L13.5304 12.2371L12.0804 8.8371L10.6304 12.2121L6.98041 12.5371L9.7554 14.9621L8.93041 18.5371ZM7.40541 20.6352L8.64578 15.3217L4.52271 11.7506L9.95348 11.2794L12.0804 6.26978L14.2073 11.2794L19.6381 11.7506L15.515 15.3217L16.7554 20.6352L12.0804 17.814L7.40541 20.6352Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
);

const HalfStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <mask
      id="mask0_846_7926"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="25"
    >
      <rect x="0.0803223" y="0.712158" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_846_7926)">
      <path
        d="M12.0804 8.8371V16.6371L15.2304 18.5621L14.4054 14.9621L17.1804 12.5621L13.5304 12.2371L12.0804 8.8371ZM7.40541 20.6352L8.64578 15.3217L4.52271 11.7506L9.95348 11.2794L12.0804 6.26978L14.2073 11.2794L19.6381 11.7506L15.515 15.3217L16.7554 20.6352L12.0804 17.814L7.40541 20.6352Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
);

const FilledStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    {...props}
  >
    <mask
      id="mask0_847_17634"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="25"
      height="25"
    >
      <rect x="0.0803223" y="0.712158" width="24" height="24" fill="#D9D9D9" />
    </mask>
    <g mask="url(#mask0_847_17634)">
      <path
        d="M7.40541 20.6352L8.64578 15.3217L4.52271 11.7506L9.95348 11.2794L12.0804 6.26978L14.2073 11.2794L19.6381 11.7506L15.515 15.3217L16.7554 20.6352L12.0804 17.814L7.40541 20.6352Z"
        fill="#1C1B1F"
      />
    </g>
  </svg>
);

export default StarRating;
