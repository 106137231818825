import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';

import { PayoutClaimType } from 'containers/WalletPage/StoreCredits/StoreCredits';
import { fetchCurrentUser } from 'ducks/user.duck';
import { put } from 'util/httpsClient';

export function useClaimPayout() {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: ({
      payoutId,
      claimType,
      partnerId,
    }: {
      payoutId: string;
      claimType: PayoutClaimType;
      partnerId?: any;
    }) => {
      return put({
        path: `/payouts/${payoutId}`,
        body: {
          claimType,
          partnerId,
        },
      });
    },
    onSettled: () => {
      // TODO: Extract query keys
      queryClient.invalidateQueries(['payouts']);
      dispatch(fetchCurrentUser());
    },
    meta: { name: 'claimPayout' },
  });
}
