import { useQuery } from '@tanstack/react-query';

import { denormalisedResponseEntities } from '../../../util/data';
import { useSdk } from '../../../util/sdkContext';
import { useCurrentUser } from '../../selectors/useCurrentUser';
import { TransactionUserRole } from './useGetTransactions';

export const useGetTransaction = (transactionId: string) => {
  const sdk = useSdk();
  const { currentUser } = useCurrentUser();

  return useQuery({
    enabled: Boolean(transactionId && currentUser.id?.uuid),
    queryKey: createQueryKey(transactionId),
    queryFn: createQueryFn(sdk, transactionId, currentUser),
    meta: {
      errorMessage: 'Failed to fetch transaction',
    },
  });
};

export const createQueryKey = (transactionId: string) => ['transactions', { transactionId }];

export const createQueryFn = (sdk, transactionId: string, currentUser) => async () =>
  sdk.transactions
    .show({
      id: transactionId,
      include: [
        'listing',
        'provider',
        'provider.profileImage',
        'customer',
        'customer.profileImage',
        'listing.images',
      ],
      'fields.transaction': [
        'processName',
        'lastTransition',
        'lastTransitionedAt',
        'transitions',
        'payinTotal',
        'payoutTotal',
        'lineItems',
        'protectedData',
      ],
      'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(denormalisedResponseEntities)
    .then(res => res?.[0])
    .then(transaction => {
      const userRole =
        transaction.customer.id.uuid === currentUser.id.uuid
          ? TransactionUserRole.CUSTOMER
          : TransactionUserRole.PROVIDER;
      const otherUser =
        transaction?.[
          userRole === TransactionUserRole.CUSTOMER
            ? TransactionUserRole.PROVIDER
            : TransactionUserRole.CUSTOMER
        ];

      return {
        ...transaction,
        userRole,
        otherUser,
        isCurrentUserProvider: userRole === TransactionUserRole.PROVIDER,
      };
    });
