import Drawer from 'components/Drawer/Drawer';
import NamedLink from 'components/NamedLink/NamedLink';
import { ConditionType } from 'config/configListing';
import { useGetConfiguration } from 'hooks/api/useGetConfiguration';
import { cn } from 'util/cn';
import { DEFAULT_SORTING } from 'util/constants';

function ShopDrawer({
  open,
  onOpenChange,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
}) {
  const { data: configuration } = useGetConfiguration();

  const allCollections = configuration?.collections || [];
  const shopDrawerCollectionIds = configuration?.awsAppConfig?.shopDrawer?.collections || [];
  const shopDrawerCollections = shopDrawerCollectionIds
    .map(id => allCollections.find(c => c.id === id))
    .filter(Boolean)
    .map(c => ({
      name: c!.name,
      link: {
        name: 'SearchPage',
        to: { search: c!.filterLink },
      },
    }));
  const shopDrawerIcons = (configuration?.awsAppConfig?.shopDrawer?.icons || []).map(icon => ({
    name: icon.name,
    link: {
      name: 'ProfilePage',
      params: { id: icon.id },
    },
  }));

  return (
    <Drawer isOpen={open} onOpenChange={onOpenChange} contentClassName="grid gap-2 content-start">
      {[
        ...DRAWER_CONTENT,
        ...(shopDrawerIcons.length
          ? [
              {
                categoryName: 'Icons',
                items: shopDrawerIcons,
              },
            ]
          : []),
        ...(shopDrawerCollections.length
          ? [
              {
                categoryName: 'Collections',
                items: shopDrawerCollections,
              },
            ]
          : []),
      ].map((category, i) => {
        return (
          <div
            key={category.categoryName}
            className={cn('grid gap-1 ', {
              'border-t border-gray-200 pt-2': i !== 0,
            })}
          >
            <h2 className="pl-2 uppercase font-syne font-bold">{category.categoryName}</h2>
            <div className="grid gap-1 ml-4">
              {category.items.map(item => {
                if (item.link) {
                  return (
                    <NamedLink
                      name={item.link.name}
                      to={item.link.to}
                      params={item.link.params}
                      key={item.name}
                      onClick={() => onOpenChange(false)}
                    >
                      <span>{item.name}</span>
                    </NamedLink>
                  );
                }

                return (
                  <div key={item.name}>
                    <span>{item.name}</span>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </Drawer>
  );
}

const DRAWER_CONTENT: {
  categoryName: string;
  items: {
    name: string;
    link?: {
      name: string;
      to?: any;
      params?: any;
    };
  }[];
}[] = [
  {
    categoryName: 'Shop',
    items: [
      {
        name: 'All',
        link: {
          name: 'SearchPage',
        },
      },
      {
        name: 'Sample',
        link: {
          name: 'SearchPage',
          to: {
            search: new URLSearchParams({
              condition: ConditionType.SampleSale,
              sortBy: DEFAULT_SORTING,
            }).toString(),
          },
        },
      },
      {
        name: 'Pre-loved',
        link: {
          name: 'SearchPage',
          to: {
            search: new URLSearchParams({
              preloved: String(true),
              sortBy: DEFAULT_SORTING,
            }).toString(),
          },
        },
      },
      {
        name: 'Clothes',
        link: {
          name: 'SearchPage',
          to: {
            search: new URLSearchParams({
              category: 'clothing',
              sortBy: DEFAULT_SORTING,
            }).toString(),
          },
        },
      },
      {
        name: 'Bags',
        link: {
          name: 'SearchPage',
          to: {
            search: new URLSearchParams({
              category: 'bags',
              sortBy: DEFAULT_SORTING,
            }).toString(),
          },
        },
      },
      {
        name: 'Shoes',
        link: {
          name: 'SearchPage',
          to: {
            search: new URLSearchParams({
              category: 'shoes',
              sortBy: DEFAULT_SORTING,
            }).toString(),
          },
        },
      },
    ],
  },
];

export default ShopDrawer;
