export enum AnalyticsEvent {
  // Core events
  UserSignup = 'USER_SIGNUP',
  RequestToBuyItem = 'ITEM_REQUEST_TO_BUY',
  AcceptOrder = 'ORDER_ACCEPT',
  CreateListing = 'LISTING_CREATE',
  AddToWishlist = 'WISHLIST_ADD',
  // Funnel events
  VisitSearchPage = 'SEARCH_PAGE_VISIT',
  VisitProductPage = 'PRODUCT_PAGE_VISIT',
  Search = 'SEARCH',
  Filter = 'FILTER',
  VisitCheckout = 'CHECKOUT_VISIT',
  EnterCardData = 'CARD_DATA_ENTER',
  SubmitCheckout = 'CHECKOUT_SUBMITTED',
}
