import { AnimatePresence, useScroll } from 'framer-motion';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';

import css from './CarouselNavigationButtons.module.css';
import { IconChevronLeft, IconChevronRight } from 'assets/icons';
import { useMediaQueries } from 'hooks/useMediaQueries';

const CarouselNavigationButtons = ({ carouselRef }) => {
  const isDesktop = useMediaQueries({ viewport: 'medium' });
  const carouselWidth = carouselRef.current?.clientWidth || 0;

  function scrollBy(amount: number) {
    if (carouselRef.current) {
      carouselRef.current.scrollBy({
        left: amount,
        behavior: 'smooth',
      });
    }
  }

  function handleScrollLeft() {
    scrollBy(-carouselWidth);
  }
  function handleScrollRight() {
    scrollBy(carouselWidth);
  }

  // Button visibility
  const { scrollX, scrollXProgress } = useScroll({ container: carouselRef, axis: 'x' });
  const [buttonLeft, setButtonLeft] = React.useState(() => scrollX.get() !== 0);
  const [buttonRight, setButtonRight] = React.useState(() => scrollXProgress.get() !== 1);

  useEffect(() => {
    const u1 = scrollXProgress.onChange(v => {
      setButtonRight(v < 1);
    });
    const u2 = scrollX.onChange(v => {
      setButtonLeft(v > 0);
    });

    return () => {
      u1();
      u2();
    };
  }, [scrollX, scrollXProgress]);

  if (!isDesktop) {
    return null;
  }

  return (
    <AnimatePresence>
      {buttonLeft && (
        <motion.div
          key="left"
          className={css.button}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}
          style={{ left: 16 }}
        >
          <IconChevronLeft onClick={handleScrollLeft} />
        </motion.div>
      )}
      {buttonRight && (
        <motion.div
          key="right"
          className={css.button}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4 }}
          style={{ right: 16 }}
        >
          <IconChevronRight onClick={handleScrollRight} />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CarouselNavigationButtons;
