import { useMutation, useQueryClient } from '@tanstack/react-query';

import { transitions } from '../../../transactions/transactionProcessPurchase';
import { useSdk } from '../../../util/sdkContext';
import * as getNextPossibleTransitions from '../transactions/useGetNextPossibleTransitions';
import * as getTransaction from '../transactions/useGetTransaction';
import { useCurrentUser } from 'hooks/selectors/useCurrentUser';

export const useSubmitReview = () => {
  const sdk = useSdk();
  const queryClient = useQueryClient();
  const { currentUser } = useCurrentUser();

  return useMutation({
    mutationFn: async ({
      transactionId,
      reviewRating,
      reviewContent,
    }: {
      transactionId: string;
      reviewRating: number;
      reviewContent: string;
    }) => {
      const transaction = await queryClient.fetchQuery({
        queryKey: getTransaction.createQueryKey(transactionId),
        queryFn: getTransaction.createQueryFn(sdk, transactionId, currentUser),
      });
      const nextPossibleTransitions = await queryClient.fetchQuery({
        queryKey: getNextPossibleTransitions.createQueryKey(transactionId),
        queryFn: getNextPossibleTransitions.createQueryFn(sdk, transactionId),
      });

      const transition = nextPossibleTransitions?.find((t: string) =>
        transaction.isCurrentUserProvider
          ? t === transitions.REVIEW_1_BY_PROVIDER || t === transitions.REVIEW_2_BY_PROVIDER
          : t === transitions.REVIEW_1_BY_CUSTOMER || t === transitions.REVIEW_2_BY_CUSTOMER
      );

      if (!transition) {
        throw new Error('No review transition available');
      }

      const review = {
        rating: reviewRating,
        content: reviewContent,
      };
      return await sdk.transactions.transition({
        id: transactionId,
        transition,
        params: {
          reviewRating,
          reviewContent,
          protectedData: {
            ...(transaction.isCurrentUserProvider
              ? { providerReview: review }
              : { customerReview: review }),
          },
        },
      });
    },
    onSettled: (_, __, { transactionId }) => {
      queryClient.invalidateQueries(['transactions']);
      queryClient.invalidateQueries(getTransaction.createQueryKey(transactionId));
      queryClient.invalidateQueries(['reviews']);
    },
    meta: { name: 'submitReview' },
  });
};
