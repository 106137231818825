import { Modal, PrimaryButton } from 'components';

const SuccessModal = ({ isOpen, onClose }) => (
  <Modal open={isOpen} onOpenChange={onClose} title="Verification Success">
    <div>
      <p>Congratulations! Your account has been successfully verified.</p>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PrimaryButton onClick={onClose}>Close</PrimaryButton>
      </div>
    </div>
  </Modal>
);

const RetryModal = ({ isOpen, onClose, verificationLink }) => (
  <Modal open={isOpen} onOpenChange={onClose} title="Verification Failed">
    <div>
      <p>
        We encountered an issue verifying your account. Please try again to ensure a smooth payout
        process.
      </p>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PrimaryButton onClick={() => window.open(verificationLink)}>Verify Now</PrimaryButton>
      </div>
    </div>
  </Modal>
);

const VerificationModal = ({ isOpen, onClose, verificationLink }) => (
  <Modal open={isOpen} onOpenChange={onClose} title="Complete Your Verification">
    <div style={{ textAlign: 'center' }}>
      <p>To proceed with your payout claim, please verify your account.</p>
      <br />
      <p>
        {' '}
        As part of our security processes with Stripe, you need to enable and verify your identity,
        ensuring a secure transaction. For more details,{' '}
        <u>
          <a href="https://thenold.com/legal/terms-and-conditions">check our T&C here</a>.
        </u>
      </p>
      <br />
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <PrimaryButton onClick={() => window.open(verificationLink)}>Verify Now</PrimaryButton>
      </div>
    </div>
  </Modal>
);

export { RetryModal, SuccessModal, VerificationModal };
