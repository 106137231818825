import { useQuery, useQueryClient } from '@tanstack/react-query';

import { denormalisedResponseEntities, ensureUser } from 'util/data';
import { get } from 'util/httpsClient';
import { useSdk } from 'util/sdkContext';

export const useGetUser = (idOrHandle: string) => {
  const sdk = useSdk();
  const queryClient = useQueryClient();

  return useQuery({
    enabled: Boolean(idOrHandle),
    queryKey: ['user', idOrHandle],
    queryFn: async () => {
      if (isUuidV4(idOrHandle)) {
        return getUserById(sdk, idOrHandle);
      }

      return getUserByHandle(idOrHandle).then(user => getUserById(sdk, user.id));
    },
    onSuccess: res => {
      // Store user in cache by both id and handle
      if (isUuidV4(idOrHandle)) {
        const handle = res.attributes.profile?.publicData?.handle;
        queryClient.setQueryData(['user', handle], res);
      } else {
        const id = res.id?.uuid;
        queryClient.setQueryData(['user', id], res);
      }
    },
  });
};

export const getUserByHandle = async (handle: string) => {
  return get({ path: `/users/handle/${handle}` });
};

export const getUserById = async (sdk: any, id: string) => {
  return sdk.users
    .show({
      id,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    })
    .then(denormalisedResponseEntities)
    .then(response => response?.[0])
    .then(ensureUser);
};

const isUuidV4 = (value: string) => {
  return value.match(
    // Check if id fits uuid v4 format
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-4[0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/
  );
};
