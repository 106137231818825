/**
 * This is the main entrypoint file for the application.
 *
 * When loaded in the client side, the application is rendered in the
 * #root element.
 *
 * When the bundle created from this file is imported in the server
 * side, the exported `renderApp` function can be used for server side
 * rendering.
 *
 * Note that this file is required for the build process.
 */
// React 16 depends on the collection types Map and Set, as well as requestAnimationFrame.
// https://reactjs.org/docs/javascript-environment-requirements.html
import { loadableReady } from '@loadable/component';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import 'core-js/features/map';
import 'core-js/features/set';
import 'raf/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';

// Import default styles before other CSS-related modules are imported
// This ensures that the styles in marketplaceDefaults.css are included
// as first ones in the final build CSS build file.
import './styles/marketplaceDefaults.css';
import './styles/output.css';
import './styles/overrides.css';

import { GoogleAnalyticsHandler, LoggingAnalyticsHandler } from './analytics/handlers';
import { ClientApp, renderApp } from './app';
import defaultConfig from './config/configDefault';
import appSettings from './config/settings';
import { authInfo } from './ducks/auth.duck';
import { fetchAppAssets } from './ducks/hostedAssets.duck';
import { fetchCurrentUser } from './ducks/user.duck';
import routeConfiguration from './routing/routeConfiguration';
import configureStore from './store';
import * as apiUtils from './util/api';
import { mergeConfig } from './util/configHelpers';
import * as log from './util/log';
import { matchPathname } from './util/routes';
import { SdkProvider } from './util/sdkContext';
import { createInstance, tokenStore, types as sdkTypes } from './util/sdkLoader';
import { GOOGLE_ANALYTICS_ID } from 'config/env';

// Extend global window object to contain __PRELOADED_STATE__
declare global {
  interface Window {
    __PRELOADED_STATE__: string;
    __REACT_QUERY_STATE__: string;
    __GROWTHBOOK_PAYLOAD__: string;
    __METADATA_PAYLOAD__: string;
    dataLayer?: any[];
    app: {
      appSettings: typeof appSettings;
      defaultConfig: typeof defaultConfig;
      sdk: ReturnType<typeof createInstance>;
      sdkTypes: typeof sdkTypes;
      store: ReturnType<typeof configureStore>;
    };
    ApplePaySession: any;
  }
}

declare const process: NodeJS.Process;

const render = (store: any, shouldHydrate: any, sdk: any) => {
  // If the server already loaded the auth information, render the app
  // immediately. Otherwise wait for the flag to be loaded and render
  // when auth information is present.
  const state = store.getState();
  const cdnAssetsVersion = state.hostedAssets.version;
  const authInfoLoaded = state.auth.authInfoLoaded;
  const info = authInfoLoaded ? Promise.resolve({}) : store.dispatch(authInfo());
  info
    .then(() => {
      store.dispatch(fetchCurrentUser());
      // Ensure that Loadable Components is ready
      // and fetch hosted assets in parallel before initializing the ClientApp
      return Promise.all([
        loadableReady(),
        store.dispatch(fetchAppAssets(defaultConfig.appCdnAssets, cdnAssetsVersion)),
      ]);
    })
    .then(([_, fetchedAppAssets]: any) => {
      const { translations: translationsRaw, ...rest } = fetchedAppAssets || {};
      // We'll handle translations as a separate data.
      // It's given to React Intl instead of pushing to config Context
      const translations = translationsRaw?.data || {};

      // Rest of the assets are considered as hosted configs
      const configEntries = Object.entries<any>(rest);
      const hostedConfig = configEntries.reduce((collectedData, [name, content]) => {
        return { ...collectedData, [name]: content.data || {} };
      }, {});

      if (shouldHydrate) {
        ReactDOM.hydrate(
          <SdkProvider value={sdk}>
            <ClientApp
              store={store}
              hostedTranslations={translations as any}
              hostedConfig={hostedConfig}
            />
          </SdkProvider>,
          document.getElementById('root')
        );
      } else {
        ReactDOM.render(
          <SdkProvider value={sdk}>
            <ClientApp
              store={store}
              hostedTranslations={translations as any}
              hostedConfig={hostedConfig}
            />
          </SdkProvider>,
          document.getElementById('root')
        );
      }
    })
    .catch((e: any) => {
      // @ts-expect-error TS(2554) FIXME: Expected 3 arguments, but got 2.
      log.error(e, 'browser-side-render-failed');
    });
};

const setupAnalyticsHandlers = (googleAnalyticsId: any) => {
  const handlers: Array<any> = [];

  // Log analytics page views and events in dev mode
  if (appSettings.dev) {
    handlers.push(new LoggingAnalyticsHandler());
  }

  // Add Google Analytics 4 (GA4) handler if tracker ID is found
  if (googleAnalyticsId) {
    if (googleAnalyticsId.indexOf('G-') !== 0) {
      console.warn(
        'Google Analytics 4 (GA4) should have measurement id that starts with "G-" prefix'
      );
    } else {
      handlers.push(new GoogleAnalyticsHandler());
    }
  }

  return handlers;
};

let sharetribeSdkCookieStore:
  | {
      setToken: any;
      getToken: any;
    }
  | undefined; // undefined when not in browser

// If we're in a browser already, render the client application.
if (typeof window !== 'undefined') {
  // set up logger with Sentry DSN client key and environment
  log.setup();

  const baseUrl = appSettings.sdk.baseUrl ? { baseUrl: appSettings.sdk.baseUrl } : {};
  const assetCdnBaseUrl = appSettings.sdk.assetCdnBaseUrl
    ? { assetCdnBaseUrl: appSettings.sdk.assetCdnBaseUrl }
    : {};

  // eslint-disable-next-line no-underscore-dangle
  const preloadedState = window.__PRELOADED_STATE__ || '{}';
  const initialState = JSON.parse(preloadedState, sdkTypes.reviver);

  sharetribeSdkCookieStore = tokenStore.browserCookieStore({
    clientId: appSettings.sdk.clientId,
    secure: appSettings.usingSSL,
  });
  const sdk = createInstance({
    transitVerbose: appSettings.sdk.transitVerbose,
    clientId: appSettings.sdk.clientId,
    secure: appSettings.usingSSL,
    tokenStore: sharetribeSdkCookieStore,
    typeHandlers: apiUtils.typeHandlers,
    ...baseUrl,
    ...assetCdnBaseUrl,
  });

  // Note: on localhost:3000, you need to use environment variable.
  const googleAnalyticsIdFromSSR = initialState?.hostedAssets?.googleAnalyticsId;
  const googleAnalyticsId = googleAnalyticsIdFromSSR || GOOGLE_ANALYTICS_ID;
  const analyticsHandlers = setupAnalyticsHandlers(googleAnalyticsId);
  // @ts-expect-error TS(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
  const store = configureStore(initialState, sdk, analyticsHandlers);

  import('./util/polyfills');
  render(store, !!window.__PRELOADED_STATE__, sdk);

  if (appSettings.dev) {
    // Expose stuff for the browser REPL
    window.app = {
      appSettings,
      defaultConfig,
      sdk,
      sdkTypes,
      store,
    };
  }
}

// Show warning if CSP is not enabled
const CSP = process.env.REACT_APP_CSP;
const cspEnabled = CSP === 'block' || CSP === 'report';

if (CSP === 'report' && process.env.REACT_APP_ENV === 'production') {
  console.warn(
    'Your production environment should use CSP with "block" mode. Read more from: https://www.sharetribe.com/docs/ftw-security/how-to-set-up-csp-for-ftw/'
  );
} else if (!cspEnabled) {
  console.warn(
    "CSP is currently not enabled! You should add an environment variable REACT_APP_CSP with the value 'report' or 'block'. Read more from: https://www.sharetribe.com/docs/ftw-security/how-to-set-up-csp-for-ftw/"
  );
}

// Export the function for server side rendering.
export default renderApp;

// exporting matchPathname and configureStore for server side rendering.
// matchPathname helps to figure out which route is called and if it has preloading needs
// configureStore is used for creating initial store state for Redux after preloading
export {
  configureStore,
  defaultConfig,
  fetchAppAssets,
  matchPathname,
  mergeConfig,
  routeConfiguration,
  sharetribeSdkCookieStore,
};
