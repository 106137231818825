import throttle from 'lodash/throttle';
import React, { Component as ReactComponent } from 'react';

/**
 * A higher order component (HOC) that provides the current viewport
 * dimensions to the wrapped component as a `viewport` prop that has
 * the shape `{ width: 600, height: 400}`.
 */
export const withViewport = Component => {
  // The resize event is flooded when the browser is resized. We'll
  // use a small timeout to throttle changing the viewport since it
  // will trigger rerendering.
  const WAIT_MS = 100;

  class WithViewportComponent extends ReactComponent {
    constructor(props) {
      super(props);
      this.state = { width: 0, height: 0 };
      this.handleWindowResize = this.handleWindowResize.bind(this);
      this.setViewport = throttle(this.setViewport.bind(this), WAIT_MS);
    }
    componentDidMount() {
      this.setViewport();
      window.addEventListener('resize', this.handleWindowResize);
      window.addEventListener('orientationchange', this.handleWindowResize);
    }
    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize);
      window.removeEventListener('orientationchange', this.handleWindowResize);
    }
    handleWindowResize() {
      this.setViewport();
    }
    setViewport() {
      this.setState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    render() {
      const viewport = this.state;
      const props = { ...this.props, viewport };
      return <Component {...props} />;
    }
  }

  // @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type 'typ... Remove this comment to see the full error message
  WithViewportComponent.displayName = `withViewport(${Component.displayName || Component.name})`;

  return WithViewportComponent;
};

/**
 * A higher order component (HOC) that provides dimensions to the wrapped component as a
 * `dimensions` prop that has the shape `{ width: 600, height: 400}`.
 *
 * @param {React.Component} Component to be wrapped by this HOC
 * @param {Object} options pass in options like maxWidth and maxHeight.
 *
 * @return {Object} HOC component which knows its dimensions
 */
export const withDimensions = (Component, options = {}) => {
  // The resize event is flooded when the browser is resized. We'll
  // use a small timeout to throttle changing the viewport since it
  // will trigger rerendering.
  const THROTTLE_WAIT_MS = 200;
  // First render default wait after mounting (small wait for styled paint)
  const RENDER_WAIT_MS = 100;

  class WithDimensionsComponent extends ReactComponent {
    constructor(props) {
      super(props);
      // @ts-expect-error TS(2339) FIXME: Property 'element' does not exist on type 'WithDim... Remove this comment to see the full error message
      this.element = null;
      // @ts-expect-error TS(2339) FIXME: Property 'defaultRenderTimeout' does not exist on ... Remove this comment to see the full error message
      this.defaultRenderTimeout = null;

      this.state = { width: 0, height: 0 };

      this.handleWindowResize = throttle(this.handleWindowResize.bind(this), THROTTLE_WAIT_MS);
      this.setDimensions = this.setDimensions.bind(this);
    }

    componentDidMount() {
      window.addEventListener('resize', this.handleWindowResize);
      window.addEventListener('orientationchange', this.handleWindowResize);

      // @ts-expect-error TS(2339) FIXME: Property 'defaultRenderTimeout' does not exist on ... Remove this comment to see the full error message
      this.defaultRenderTimeout = window.setTimeout(() => {
        this.setDimensions();
      }, RENDER_WAIT_MS);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleWindowResize);
      window.removeEventListener('orientationchange', this.handleWindowResize);
      // @ts-expect-error TS(2339) FIXME: Property 'defaultRenderTimeout' does not exist on ... Remove this comment to see the full error message
      window.clearTimeout(this.defaultRenderTimeout);
    }

    handleWindowResize() {
      window.requestAnimationFrame(() => {
        this.setDimensions();
      });
    }

    setDimensions() {
      this.setState(prevState => {
        // @ts-expect-error TS(2339) FIXME: Property 'element' does not exist on type 'WithDim... Remove this comment to see the full error message
        const { clientWidth, clientHeight } = this.element || { clientWidth: 0, clientHeight: 0 };
        return { width: clientWidth, height: clientHeight };
      });
    }

    render() {
      // Dimensions from state (i.e. dimension after previous resize)
      // These are needed for component rerenders
      // @ts-expect-error TS(2339) FIXME: Property 'width' does not exist on type 'Readonly<... Remove this comment to see the full error message
      const { width, height } = this.state;

      // Current dimensions from element reference
      // @ts-expect-error TS(2339) FIXME: Property 'element' does not exist on type 'WithDim... Remove this comment to see the full error message
      const { clientWidth, clientHeight } = this.element || { clientWidth: 0, clientHeight: 0 };
      const hasDimensions =
        (width !== 0 && height !== 0) || (clientWidth !== 0 && clientHeight !== 0);

      // clientWidth and clientHeight
      const currentDimensions =
        clientWidth !== 0 && clientHeight !== 0
          ? { width: clientWidth, height: clientHeight }
          : width !== 0 && height !== 0
          ? { width, height }
          : {};

      const props = { ...this.props, dimensions: currentDimensions };

      // lazyLoadWithDimensions HOC needs to take all given space
      // unless max dimensions are provided through options.
      // @ts-expect-error TS(2339) FIXME: Property 'maxWidth' does not exist on type '{}'.
      const { maxWidth, maxHeight } = options;
      const maxWidthMaybe = maxWidth ? { maxWidth } : {};
      const maxHeightMaybe = maxHeight ? { maxHeight } : {};
      const style =
        maxWidth || maxHeight
          ? { width: '100%', height: '100%', ...maxWidthMaybe, ...maxHeightMaybe }
          : { position: 'absolute', top: 0, right: 0, bottom: 0, left: 0 };

      return (
        <div
          ref={element => {
            // @ts-expect-error TS(2339) FIXME: Property 'element' does not exist on type 'WithDim... Remove this comment to see the full error message
            this.element = element;
          }}
          // @ts-expect-error TS(2322) FIXME: Type '{ maxHeight: any; maxWidth: any; width: stri... Remove this comment to see the full error message
          style={style}
        >
          {hasDimensions ? <Component {...props} /> : null}
        </div>
      );
    }
  }

  // @ts-expect-error TS(2339) FIXME: Property 'displayName' does not exist on type 'typ... Remove this comment to see the full error message
  WithDimensionsComponent.displayName = `withDimensions(${
    Component.displayName || Component.name
  })`;

  return WithDimensionsComponent;
};
