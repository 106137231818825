import classNames from 'classnames';
import React, { MouseEventHandler } from 'react';

import css from './AspectRatioWrapper.module.css';

type AspectRatioWrapperProps = {
  className?: string;
  rootClassName?: string;
  width: number;
  height: number;
  children?: React.ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

const AspectRatioWrapper = React.forwardRef<HTMLDivElement, AspectRatioWrapperProps>(
  (props, ref) => {
    const { children, className, rootClassName, width, height, onClick, ...rest } = props;
    const classes = classNames(rootClassName || css.root, className);

    const aspectRatio = (height / width) * 100;
    const paddingBottom = `${aspectRatio}%`;

    return (
      <div className={classes} onClick={onClick} ref={ref} {...rest}>
        <div className={css.aspectPadding} style={{ paddingBottom }}>
          <div className={css.aspectBox}>{children}</div>
        </div>
      </div>
    );
  }
);

export default AspectRatioWrapper;
