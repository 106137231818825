import { useMutation } from '@tanstack/react-query';
import { useCallback } from 'react';

import { GUEST_ACCOUNT_PASSWORD } from 'config/env';
import { useSdk } from 'util/sdkContext';

const registerGuestAccount = async (sdk: any, email: string, fullName: string) => {
  try {
    const firstName = fullName.split(' ')[0];
    const lastName = fullName.split(' ')[fullName.split(' ').length - 1] || firstName;

    await sdk.currentUser.create({
      email,
      password: GUEST_ACCOUNT_PASSWORD,
      firstName,
      lastName,
      privateData: { guestAccount: true },
    });
  } catch (error: any) {
    throw error;
  }
};

export const useGuestAccount = () => {
  const sdk = useSdk();

  const loginGuest = useCallback(
    async (opts: { email: string; fullName: string }) => {
      const password = GUEST_ACCOUNT_PASSWORD;
      const { email, fullName } = opts;

      try {
        await sdk.login({ username: email, password });
      } catch (error: any) {
        if (error.status === 401) {
          await registerGuestAccount(sdk, email, fullName);

          return await sdk.login({ username: email, password });
        }

        throw error;
      }
      return { email, password };
    },
    [sdk]
  );

  return useMutation({
    mutationFn: loginGuest,
    meta: { name: 'guestAccount' },
  });
};
