import { useCallback } from 'react';

export const useScrollToFirstError = (options: {
  errorClassName?: string;
  cardElementClassName?: string;
  addressSelectionClassName?: string;
  onCardElementEmpty?: () => void;
}) => {
  const {
    errorClassName = 'input-error',
    cardElementClassName,
    addressSelectionClassName,
    onCardElementEmpty,
  } = options;

  const scrollToFirstError = useCallback(
    (isCardElementFilled: boolean) => {
      const errorSelector = `.${errorClassName}`;
      const cardElementSelector = cardElementClassName ? `.${cardElementClassName}` : null;
      const addressSelectionSelector = addressSelectionClassName
        ? `.${addressSelectionClassName}`
        : null;

      const errorElement = document.querySelector(errorSelector);
      const cardElement =
        !isCardElementFilled && cardElementSelector
          ? document.querySelector(cardElementSelector)
          : null;
      const addressSelectionElement = addressSelectionSelector
        ? document.querySelector(addressSelectionSelector)
        : null;

      const firstErrorElement = errorElement || cardElement || addressSelectionElement;

      if (!isCardElementFilled && onCardElementEmpty) {
        onCardElementEmpty();
      }

      if (firstErrorElement instanceof HTMLElement) {
        console.log('Scrolling to first error', firstErrorElement);
        firstErrorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        firstErrorElement.focus();
      }
    },
    [errorClassName, cardElementClassName, addressSelectionClassName, onCardElementEmpty]
  );

  return scrollToFirstError;
};
