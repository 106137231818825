import _ from 'lodash';
import { Helmet } from 'react-helmet-async';
import Markdown from 'react-markdown';
import { useLocation } from 'react-router-dom';

import {
  ImageLazy,
  LayoutSingleColumn,
  ListingCard,
  ListingsCarousel,
  MobileBottomNavigation,
  NamedLink,
  NamedRedirect,
  Page,
  PrimaryButton,
} from '../../components';
import FooterContainer from '../FooterContainer/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import { FILTERS_DEFAULTS } from 'containers/SearchPage/SearchPage.filters';
import { GetListingsWithFiltersRequest } from 'containers/SearchPage/SearchPage.hooks';
import { useGetListings } from 'hooks/api/listings/useGetListings';
import { useGetConfiguration } from 'hooks/api/useGetConfiguration';
import { useIsScrollingDisabled } from 'hooks/selectors/useIsScrollingDisabled';
import { stringify } from 'util/urlHelpers';

type CustomData = {
  title: string;
  brand: string;
  brandId: string;
  initialType: string;
  productType: string;
  productSubtype: string;
  productCategory: string;
  sectionDescription?: string;
  pageCopy?: string;
  image1Alt: string;
  image2Alt: string;
  image3Alt: string;
  shopButtonTitle: string;
};

const SEOPages = () => {
  const scrollingDisabled = useIsScrollingDisabled();

  const { data: config, isLoading } = useGetConfiguration();
  const seoMetadata = config?.SEOMetadata || [];
  const path = useLocation().pathname;

  const pageSEOMetadata = seoMetadata.find(p => p.urlPathname.toLowerCase() === path.toLowerCase());
  const customData = (pageSEOMetadata?.customData || {}) as CustomData;
  const queryParams = getSEOPagesListingQueryParams(customData) || {};
  const stringifiedQueryParams = stringify({ ...FILTERS_DEFAULTS, ...queryParams });
  const {
    data: listingsData,
    isFetched,
    isLoading: isLoadingListings,
  } = useGetListings(queryParams);
  const allListings = listingsData?.pages?.flatMap(page => page.listings) || [];
  const { data: genericListingsData } = useGetListings(
    {},
    { enabled: isFetched && !allListings.length }
  );

  const latestListings = (() => {
    if (allListings.length >= 6) {
      return allListings.slice(0, 24);
    } else {
      const genericListings = genericListingsData?.pages?.flatMap(page => page.listings) || [];
      const combinedListings = [...allListings, ...genericListings];
      return _.uniqBy(combinedListings, v => v.id.uuid).slice(0, 24);
    }
  })();

  if (isLoadingListings) {
    return null;
  }

  if (!pageSEOMetadata && !isLoading) {
    return <NamedRedirect name="NotFoundPage" />;
  }

  const metaTitle = pageSEOMetadata?.seoTitleText && `${pageSEOMetadata?.seoTitleText} | THE NOLD`;
  const metaDescription =
    pageSEOMetadata?.seoMetaDescription ||
    (pageSEOMetadata?.seoTitleText &&
      `Buy authentic ${pageSEOMetadata?.seoTitleText} at The NOLD. Shop timeless classics and rare finds with worldwide delivery. Elevate your style with unique preloved items.`);

  return (
    <Page title="THE NOLD" scrollingDisabled={scrollingDisabled}>
      <Helmet>
        {metaTitle && (
          <>
            <title>{metaTitle}</title>
            <meta name="og:title" content={metaTitle} />
            <meta name="twitter:title" content={metaTitle} />
          </>
        )}
        {metaDescription && (
          <>
            <meta name="description" content={metaDescription} />
            <meta name="og:description" content={metaDescription} />
            <meta name="twitter:description" content={metaDescription} />
          </>
        )}
      </Helmet>
      <LayoutSingleColumn topbar={<TopbarContainer />} footer={<MobileBottomNavigation />}>
        <div className="flex flex-col gap-10 my-8 items-center justify-start w-full overflow-hidden">
          <div className="flex flex-col gap-5 w-full overflow-hidden">
            <h2 className="text-center uppercase text-3 font-syne font-bold">
              {customData?.title || 'Recently listed'}
            </h2>
            <ListingsCarousel>
              {latestListings.map(l => (
                <ListingCard key={l.id.uuid} showPriceInfo={false} showTitle={false} listing={l} />
              ))}
            </ListingsCarousel>
            <NamedLink
              name="SearchPage"
              to={{ search: stringifiedQueryParams }}
              className="self-center"
            >
              <PrimaryButton>Shop {customData?.shopButtonTitle}</PrimaryButton>
            </NamedLink>
          </div>
          {customData?.pageCopy && (
            <div className="prose prose-headings:font-syne prose-headings:uppercase prose-h1:font-bold max-w-[85ch] px-2">
              <Markdown>{customData?.pageCopy}</Markdown>
            </div>
          )}
          {customData.sectionDescription && (
            <div className="grid gap-5 w-full max-w-content-max-width mx-auto px-3 md:px-10">
              <h2 className="text-center uppercase text-3 font-syne font-bold">
                {customData?.title}
              </h2>
              <div className="grid gap-3">
                {customData?.sectionDescription
                  ?.split('\n')
                  .map(p => p.trim())
                  .filter(Boolean)
                  .map((p, i) => (
                    <p key={i} className="text-1">
                      {p}
                    </p>
                  ))}
              </div>
            </div>
          )}
          <div className="flex flex-col md:flex-row w-full max-w-content-max-width">
            <div className="relative w-full md:w-1/3 aspect-video isolate content-center">
              <ImageLazy
                src="https://cdn.thenold.com/images/seo_img_1_compressed.webp"
                blurSrc="https://cdn.thenold.com/images/seo_img_1_blur.webp"
                className="absolute inset-0 object-center object-cover h-full w-full"
                alt={customData?.image1Alt}
              />
              <h3 className="text-white z-10 relative font-syne uppercase text-5 md:text-3 lg:text-4 font-bold p-5">
                Finest
                <br />
                curation
              </h3>
            </div>
            <div className="relative w-full md:w-1/3 aspect-video isolate content-center">
              <ImageLazy
                src="https://cdn.thenold.com/images/seo_img_2_compressed.webp"
                blurSrc="https://cdn.thenold.com/images/seo_img_2_blur.webp"
                className="absolute inset-0 object-cover object-center h-full w-full"
                alt={customData?.image2Alt}
              />
              <h3 className="text-black z-10 relative font-syne uppercase text-5 md:text-3 lg:text-4 font-bold p-5">
                Fastest
                <br />
                AI Listing
              </h3>
            </div>
            <div className="relative w-full md:w-1/3 aspect-video isolate content-center">
              <ImageLazy
                src="https://cdn.thenold.com/images/seo_img_3_compressed.webp"
                blurSrc="https://cdn.thenold.com/images/seo_img_3_blur.webp"
                className="absolute inset-0 object-cover object-center h-full w-full"
                alt={customData?.image3Alt}
              />
              <h3 className="text-white z-10 relative font-syne uppercase text-5 md:text-3 lg:text-4 font-bold p-5">
                100%
                <br />
                payout
              </h3>
            </div>
          </div>
        </div>
        <FooterContainer />
      </LayoutSingleColumn>
    </Page>
  );
};

export function getSEOPagesListingQueryParams(customData: CustomData) {
  const queryParams: GetListingsWithFiltersRequest = {
    brandIds:
      (customData?.brandId && customData?.brandId?.split(',').map(id => parseInt(id, 10))) ||
      undefined,
    productType: customData?.productType ? [customData?.productType] : undefined,
    productSubtype: customData?.productSubtype ? [customData?.productSubtype] : undefined,
    category: customData?.productCategory || undefined,
  };

  return queryParams;
}

export default SEOPages;
