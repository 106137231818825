import { formatMoney } from '../../util/currency';
import { FormattedMessage } from '../../util/reactIntl';
import css from './OrderBreakdown.module.css';

interface LineItemTotalPriceProps {
  intl: any;
  totalPrice: any;
}

const LineItemTotalPrice: React.FC<LineItemTotalPriceProps> = props => {
  const { intl, totalPrice: propsTotalPrice } = props;

  const totalPrice = propsTotalPrice;

  const formattedTotalPrice = formatMoney(intl, totalPrice);

  return (
    <div className={css.lineItemTotal}>
      <div className={css.totalLabel}>{<FormattedMessage id="OrderBreakdown.total" />}</div>
      <div className={css.totalPrice}>{formattedTotalPrice}</div>
    </div>
  );
};

export default LineItemTotalPrice;
