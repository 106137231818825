import { useQuery } from '@tanstack/react-query';

import { denormalisedResponseEntities } from '../../../util/data';
import { useSdk } from '../../../util/sdkContext';

export const useGetNextPossibleTransitions = transactionId => {
  const sdk = useSdk();

  return useQuery({
    enabled: Boolean(transactionId),
    queryKey: createQueryKey(transactionId),
    queryFn: createQueryFn(sdk, transactionId),
  });
};

export const createQueryKey = transactionId => ['nextPossibleTransitions', transactionId];

export const createQueryFn = (sdk, transactionId: string) => async () => {
  return sdk.processTransitions
    .query({
      transactionId,
    })
    .then(denormalisedResponseEntities)
    .then(res => res?.map(pt => pt?.attributes?.name));
};
