import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import iconAnimatedCo2 from '../icon-animated-co2.webp';
import iconAnimatedH2o from '../icon-animated-h2o.webp';
import iconAnimatedWaste from '../icon-animated-waste.webp';
import css from './Impact.module.css';
import { IconPlus } from 'assets/icons';
import { NamedLink, PrimaryButton } from 'components';
import { useGetUserSocials, useToggleFollowUser } from 'hooks/api/users';
import { useMediaQueries } from 'hooks/useMediaQueries';

type P = {
  profileUser: any;
  className?: string;
  ownProfile: boolean;
};

export const Impact: React.FC<P> = ({ profileUser, className, ownProfile }) => {
  const { data: userSocials } = useGetUserSocials();
  const { mutate: toggleFollowUser } = useToggleFollowUser();
  const userMetadata = profileUser?.attributes.profile.metadata;

  const co2 = userMetadata?.co2 || 0;
  const water = userMetadata?.water || 0;
  const waste = userMetadata?.waste || 0;

  const isDesktop = useMediaQueries({ viewport: 'medium' });

  return (
    <div className={classNames(css.impact, className)}>
      <h3 className={css.impact__title}>
        <FormattedMessage id="ProfilePage.impact.title" />
      </h3>
      <div className={css.impact__stat}>
        <img src={iconAnimatedCo2} alt="co2" />
        <div className={css.impact__statText}>
          <div className={css.impact__statMain}>
            <FormattedNumber value={co2} maximumFractionDigits={2} /> kg
          </div>
          <div className={css.impact__statSecondary}>of CO2 savings</div>
        </div>
      </div>
      <div className={css.impact__stat}>
        <img src={iconAnimatedH2o} alt="h2o" />
        <div className={css.impact__statText}>
          <div className={css.impact__statMain}>
            <FormattedNumber value={water} maximumFractionDigits={2} /> l
          </div>
          <div className={css.impact__statSecondary}>of H2O savings</div>
        </div>
      </div>
      <div className={css.impact__stat}>
        <img src={iconAnimatedWaste} alt="waste" />
        <div>
          <div className={css.impact__statMain}>
            <FormattedNumber value={waste} maximumFractionDigits={2} /> kg
          </div>
          <span className={css.impact__statSecondary}>of waste savings</span>
        </div>
      </div>
      {isDesktop && (
        <div className={css.impact__button}>
          {ownProfile ? (
            <NamedLink name="ResellLandingPage">
              <PrimaryButton>
                <IconPlus />
                Add a listing
              </PrimaryButton>
            </NamedLink>
          ) : (
            <PrimaryButton onClick={() => toggleFollowUser(profileUser.id?.uuid)}>
              {userSocials?.followingIds?.includes(profileUser.id?.uuid) ? 'Unfollow' : 'Follow'}
            </PrimaryButton>
          )}
        </div>
      )}
    </div>
  );
};
