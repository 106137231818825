import { useMediaQuery } from 'usehooks-ts';

// This hook should be synced with the breakpoints in src/styles/customMediaQueries.css

export const Breakpoints = {
  SMALL: 550,
  MEDIUM: 768,
  LARGE: 1024,
};

export const useMediaQueries = ({ viewport }: { viewport: 'small' | 'medium' | 'large' }) => {
  let minWidth = 0;
  switch (viewport) {
    case 'small':
      minWidth = 550;
      break;
    case 'medium':
      minWidth = 768;
      break;
    case 'large':
      minWidth = 1024;
      break;
  }

  return useMediaQuery(`(min-width: ${minWidth}px)`);
};
