import { useQuery } from '@tanstack/react-query';

import { useCurrentUser } from '../../selectors/useCurrentUser';
import { get } from 'util/httpsClient';

export function useGetPayouts() {
  const { currentUser } = useCurrentUser();

  return useQuery({
    enabled: Boolean(currentUser.id?.uuid),
    queryKey: ['payouts'],
    queryFn: () => {
      return get({ path: `/users/payouts` });
    },
    meta: {
      errorMessage: 'Failed to fetch payouts',
    },
  });
}
