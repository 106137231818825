/**
 * Promised component makes it easier to render content that
 * depends on resolution of a Promise.
 *
 * How to use:
 * <Promised promise={givenPromise} renderFulfilled={v => <b>{v}</b>} renderRejected={v => <b>v</b>} />
 */

/* eslint-disable no-underscore-dangle */
import PropTypes from 'prop-types';
import { Component } from 'react';

class Promised extends Component {
  constructor(props) {
    super(props);

    // success value is string to be more useful when rendering texts.
    this.state = {
      value: '',
      error: null,
    };
    // @ts-expect-error TS(2339) FIXME: Property '_isMounted' does not exist on type 'Prom... Remove this comment to see the full error message
    this._isMounted = false;
  }

  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property '_isMounted' does not exist on type 'Prom... Remove this comment to see the full error message
    this._isMounted = true;
    // @ts-expect-error TS(2339) FIXME: Property 'promise' does not exist on type 'Readonl... Remove this comment to see the full error message
    this.props.promise
      .then(value => {
        // @ts-expect-error TS(2339) FIXME: Property '_isMounted' does not exist on type 'Prom... Remove this comment to see the full error message
        if (this._isMounted) {
          this.setState({ value });
        }
      })
      .catch(error => {
        // @ts-expect-error TS(2339) FIXME: Property '_isMounted' does not exist on type 'Prom... Remove this comment to see the full error message
        if (this._isMounted) {
          this.setState({ error });
        }
      });
  }

  componentWillUnmount() {
    // @ts-expect-error TS(2339) FIXME: Property '_isMounted' does not exist on type 'Prom... Remove this comment to see the full error message
    this._isMounted = false;
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'renderFulfilled' does not exist on type ... Remove this comment to see the full error message
    const { renderFulfilled, renderRejected } = this.props;
    // @ts-expect-error TS(2339) FIXME: Property 'error' does not exist on type 'Readonly<... Remove this comment to see the full error message
    return this.state.error ? renderRejected(this.state.error) : renderFulfilled(this.state.value);
  }
}

// @ts-expect-error TS(2339) FIXME: Property 'defaultProps' does not exist on type 'ty... Remove this comment to see the full error message
Promised.defaultProps = { renderRejected: e => e };

const { func, shape } = PropTypes;

// @ts-expect-error TS(2339) FIXME: Property 'propTypes' does not exist on type 'typeo... Remove this comment to see the full error message
Promised.propTypes = {
  promise: shape({
    then: func.isRequired, // usually promises are detected from this single function alone
  }).isRequired,
  renderFulfilled: func.isRequired,
  renderRejected: func.isRequired,
};

export default Promised;
