/**
 * Builds a query string from an object of parameters.
 *
 * @param params - An object containing the query parameters.
 * @returns A string representing the query string (without the leading '?').
 */
export function buildQueryString(params: Record<string, any>): string {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      if (Array.isArray(value)) {
        value.forEach(v => {
          if (v !== undefined && v !== null) {
            queryParams.append(key, v.toString());
          }
        });
      } else if (typeof value === 'object') {
        queryParams.append(key, JSON.stringify(value));
      } else {
        queryParams.append(key, value.toString());
      }
    }
  });

  return queryParams.toString();
}

/**
 * Builds a full URL with query string from a base URL and an object of parameters.
 *
 * @param baseUrl - The base URL to append the query string to.
 * @param params - An object containing the query parameters.
 * @returns A string representing the full URL with the query string.
 */
export function buildUrlWithQueryString(baseUrl: string, params: Record<string, any>): string {
  const queryString = buildQueryString(params);
  return queryString ? `${baseUrl}?${queryString}` : baseUrl;
}
