import { useMutation, useQueryClient } from '@tanstack/react-query';

import { post } from 'util/httpsClient';

type ListingActionType = 'close' | 'open' | 'sold' | 'restock' | 'delete';

const useListingAction = (actionType: ListingActionType) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (listingId: string) =>
      post({ path: `/listings/${listingId}/status`, body: { actionType } }),
    onSuccess: () => {
      queryClient.invalidateQueries(['userListings']);
    },
    meta: { name: `listingAction_${actionType}` },
  });
};

export const useCloseListing = () => useListingAction('close');
export const useActivateListing = () => useListingAction('open');
export const useMarkListingAsSold = () => useListingAction('sold');
export const useRestockListing = () => useListingAction('restock');
export const useDeleteListing = () => useListingAction('delete');
