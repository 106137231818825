import classNames from 'classnames';
import toast from 'react-hot-toast';
import { useIntl } from 'react-intl';

import { getRedemptionTypeString } from '../PayoutModal/PayoutModal';
import { formatDateToDDMMYYYY } from '../Wallet/Wallet';
import { useGetPayoutTransactions } from '../WalletPage.hooks';
import css from './StoreCredits.module.css';
import { IconCopy } from 'assets/icons';
import IconSpinner from 'components/IconSpinner/IconSpinner';
import { useGetConfiguration } from 'hooks/api/useGetConfiguration';
import { useGetUserNoldCredit } from 'hooks/api/users/useGetUserNoldCredit';
import { formatMoney } from 'util/currency';

export enum StoreCreditType {
  Shopify = 1,
  NoldCredit = 2,
  Tillo = 3,
}

export enum PayoutClaimType {
  Cash = 1,
  Credit = 2,
}

const StoreCredits = ({ className }) => {
  const intl = useIntl();

  const { data: userNoldCredit, isLoading: isLoadingUserNoldCredit } = useGetUserNoldCredit();

  let noldCreditFormatted = '';
  if (!isLoadingUserNoldCredit) {
    noldCreditFormatted = formatMoney(intl, userNoldCredit);
  }

  const { data: configuration } = useGetConfiguration();
  const partners = configuration?.partners || [];

  const { transactionsWithPayouts, isLoading } = useGetPayoutTransactions();
  const storeCreditPayouts = transactionsWithPayouts?.filter(
    transaction =>
      transaction.payout?.claimType === PayoutClaimType.Credit &&
      transaction.payout?.partnerId !== configuration?.defaultPartnerId
  );

  return (
    <div className={classNames(css.root, className)}>
      <div className={css.section}>
        <h1 className={css.section__title}>Store Credits</h1>
        <div className={css.noldSectionContent}>
          <div className={css.cardWrapper}>
            <div className={css.card}>
              THE
              <br />
              NOLD
            </div>
            <div>
              {isLoadingUserNoldCredit ? (
                <div
                  style={{
                    display: 'grid',
                    placeContent: 'left',
                    padding: 'var(--n-size-8) 0',
                  }}
                >
                  <IconSpinner />
                </div>
              ) : (
                <div className={css.amount}>{noldCreditFormatted}</div>
              )}
            </div>
          </div>
          <span className={css.availableAtCheckout}>Automatically available at checkout</span>
        </div>
      </div>
      <div className={css.divider} />
      <div className={css.section}>
        <h1 className={css.section__title}>Other Store Credits</h1>
        {storeCreditPayouts?.length === 0 && !isLoading && (
          <div className={css.empty}>
            All store credits that you claim upon a successful sale will appear here.
          </div>
        )}
        {storeCreditPayouts?.map(({ payout, ...transaction }) => {
          const partner = partners.find(p => p.id === payout.partnerId);

          return (
            <div className={css.noldSectionContent}>
              <div className={css.cardWrapper}>
                <div className={css.card} style={{ backgroundImage: `url(${partner?.logoUrl})` }} />
                <div>
                  <div>{payout.partnerName}</div>
                  <div className={css.amount}>£{payout?.creditAmount}</div>
                  {partner?.storeCreditType === StoreCreditType.Tillo ? (
                    <a href={payout.discountCode} style={{ textDecoration: 'underline ' }}>
                      USE GIFTCARD
                    </a>
                  ) : (
                    <div>
                      <IconCopy
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          navigator.clipboard
                            .writeText(payout.discountCode)
                            .then(() => toast('Copied to clipboard'));
                        }}
                      />
                      <span>{payout.discountCode}</span>
                    </div>
                  )}
                </div>
                <div className={css.secondaryInfo}>
                  {payout.validUntil && (
                    <div>Store credit valid until {formatDateToDDMMYYYY(payout.validUntil)}</div>
                  )}
                  {partner?.redemptionType && (
                    <div>Use: {getRedemptionTypeString(partner.redemptionType)}</div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default StoreCredits;
